import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Content, Table } from './styles';
import { GetListaCliente, RejectClient } from '../../../services/client'
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal/Modal';
import { ButtonEdit } from '../../../components/TabelaAprovacaoArtista/styles';

function Approval() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: '',
    description: ''
  });
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    listaClient()
  }, []);

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: '', description: '' });
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  const handleGoToProfileApproval = (customer) => {
    return navigate(
     `/clientes/aprovacao/perfil/`,
      {
        state: {
          profile: customer,
        }
      }
    );
  };

  async function listaClient() {
    setLoad(true)
    const resp = await GetListaCliente()
    if (resp) {
      setData(resp.data)
      setLoad(false)
    } else {
      setLoad(false)
      addToast(ERROR, "Nenhum dado retornado")
    }
  }

  async function rejectModal() {
    if (validForm()) {
      setLoading(true);
      const resp = await RejectClient({ ...payload, client: modal.id });
      setLoading(false);
      if (resp && resp.status === 200) {
        addToast(SUCCESS, "Cliente cancelado com sucesso!");
        handleTriggerModal(false);
        listaClient();
      } else {
        addToast(ERROR, "Não foi possível realizar essa operação");
      }
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, 'Informe o Motivo da rejeição');
      return false;
    }
    return true;
  }

  return (
    
      <><h4>Aprovação de Clientes</h4><div>
      <Table>
        <thead>
          <tr>
            <th data-label="Nome">Nome</th>
            <th data-label="Status">Status</th>
            <th data-label="E-mail">E-mail</th>
            <th data-label="Telefone">Telefone</th>
            <th data-label="Funções">Funções</th>
          </tr>
        </thead>
        <tbody>
          {load === true ?
            <div className="text-center m-5">
              <div className="spinner-border text-info" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div> :
            data.map((customer, index) => (
              <tr key={index}>
                <td data-label="Nome">{customer.name}</td>
                <td data-label="Status">{customer.status}</td>
                <td data-label="E-mail">{customer.email}</td>
                <td data-label="Telefone">{customer.phone}</td>
                <td data-label="Funções">
                  <ButtonEdit azul large font12 onClick={() => handleGoToProfileApproval(customer)}>Visualizar Perfil</ButtonEdit>
                  <ButtonEdit large font12 onClick={() => handleTriggerModal(customer)}>Rejeitar Cadastro</ButtonEdit>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {modal && <Modal
        loading={loading} closeModal={handleTriggerModal}
        rejectModal={rejectModal} handleChangeReject={handleChangeReject} />}</div></>
   
  );
}

export default Approval;