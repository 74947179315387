import axios from 'axios';
import { JOB_APROVE_URL, JOB_SOLICITATION_URL } from './config';

export const getJobs = async (page) => {
  const params = { page };
  try {
    const response = await axios.get(JOB_SOLICITATION_URL, { params });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const deleteJob = async (jobId) => {
  try {
    const response = await axios.delete(`${JOB_SOLICITATION_URL}${jobId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const updateJob = async (payload) => {
  try {
    const response = await axios.patch(`${JOB_SOLICITATION_URL}${payload.id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const approveJob = async (jobId) => {
  try {
    const response = await axios.patch(`${JOB_APROVE_URL}`, {
      job: jobId
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}