import React from 'react'
import { Bar } from 'react-chartjs-2'
import { ChartBar, AreaTotalChart, AreaInformacao } from './styles'
import Images from '../../assets/index'
import Chart from 'chart.js/auto';


const ChartCrescimentoMensalidadeMaior = () => {
  return (
    <>
      <AreaTotalChart>
        <span>Crescimento Mensalidades</span>
        <AreaInformacao>
          <span>156,927</span>
          <p>1.8<img src={Images.icon_arrow_narrow_up.default} alt="arrow"></img> </p>
        </AreaInformacao>
        <ChartBar style={{height: '100%', width: '100%'}}>

          <Bar
            data={{
              labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai','Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
              datasets: [
                {
                  label: '',
                  data: [14, 19, 13, 15, 22, 13, 14, 16, 12, 23, 24, 13,],
                  fill: false,
                  backgroundColor: 'rgb(30, 94, 255, 0.8)',
                  borderColor: 'rgba(30, 94, 255, 1)',
                  borderWidth: 1.5,
                  responsive: true,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
            }}
          />
        </ChartBar>
      </AreaTotalChart>
    </>
  )
}

export default ChartCrescimentoMensalidadeMaior;