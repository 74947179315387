import React, { useState, useEffect } from "react";

import { CreateRecurring, GetRecurring, UpdateRecurring } from "../../services/recurringType";
import {
  Container, Row, Button, Table, DivInput,
  InputOfForm,
  SelectOfForm,
  Span,
  I,
} from "./style";
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from "../../AppContext/Context";
import { addToast, ERROR, SUCCESS } from "../../components/Notifications";

const RecurringPayment = () => {
  const {
    user: { is_type },
  } = useAuth();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [payload, setPayload] = useState({
    name: '',
    quantity: 0,
    type: ''
  });

  useEffect(() => {
    loadRecurringType();
  }, [])

  const loadRecurringType = async () => {
    try {
      setLoading(true);
      const response = await GetRecurring();
      setList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const resetPayload = () => {
    setPayload({
      name: '',
      quantity: 0,
      type: ''
    });
  }

  const handleChange = (field, value) => {
    setPayload({
      ...payload,
      [field]: value
    });
  }

  const handleEdit = (item) => {
    setPayload(item)
  }

  const checkDisableButton = () => {
    const { name, quantity, type } = payload;
    return !name || quantity < 1 || !type;
  }

  const updateList = (newItem, update = false) => {
    if (update) {
      const newList = [...list];
      const indexOf = list.findIndex(item => item.id === newItem.id);
      if (indexOf >= 0) {
        newList[indexOf] = newItem;
        setList(newList);
      }
    } else {
      setList(oldValue => [...oldValue, newItem])
    }
  }

  const handleSubmit = async () => {
    try {
      if (!payload.id) {
        const response = await CreateRecurring(payload);
        resetPayload();
        updateList(response);
        addToast(SUCCESS, 'Criado com Sucesso');
      } else {
        const response = await UpdateRecurring(payload, payload.id);
        resetPayload();
        updateList(response, true);
        addToast(SUCCESS, 'Atualizado com Sucesso');
      }
    } catch (error) {
      console.log(error);
      addToast(ERROR, 'Ocorreu um problema com essa operação');
    }
  }

  return (
    <>
      <h4 id="editContainer">Tipos de Pagamento Recorrente</h4>
      <Container>
        <Row >


          <DivInput>

            <InputOfForm
              type="text"
              
              required
              value={payload.name}
              onChange={({ target }) => handleChange('name', target.value)} />

            <Span>Nome</Span>
            <I></I>
          </DivInput>


          <DivInput>

            <InputOfForm
              type="number"
              required
              value={payload.quantity}
              onChange={({ target }) => handleChange('quantity', Number(target.value))} />

            <Span>Quantidade</Span>
            <I></I>
          </DivInput>







          <DivInput>

            <SelectOfForm
              required
              value={payload.type}
              onChange={({ target }) => handleChange('type', target.value)}>
              <option hidden>Escolha</option>
              <option value="DIAS">Dias</option>
              <option value="MESES">Meses</option>
              <option value="ANOS">Anos</option>
            </SelectOfForm>

            <Span>Quantidade</Span>
            <I></I>
          </DivInput>

          <DivInput>
            
            <Button
              green={!checkDisableButton()}
              disabled={checkDisableButton()}
              onClick={handleSubmit}>
              Confirmar
            </Button>
            
          </DivInput>

        </Row>

        <Table large>
          <thead>
            <th data-label="Nome">Nome</th>
            <th data-label="Quantidade">Quantidade</th>
            <th data-label="Tipo">Tipo</th>
            <th data-label=""></th>
          </thead>
          <tbody>
            {loading === true ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) :
              (list?.map((recurring, index) => (
                <tr key={recurring.id}>
                  <td data-label="Nome">{recurring.name}</td>
                  <td data-label="Quantidade">{recurring.quantity}</td>
                  <td data-label="Tipo">{recurring.type}</td>
                  <td data-label="">
                    {checkPermission(
                      is_type,
                      MODELS.Pagamento,
                      FUNCTIONS.Atualizar
                    ) ? (
                      <a href="#editContainer">
                      <Button
                        green={true}
                        onClick={() => handleEdit(recurring)}>
                        EDITAR
                      </Button>
                      </a>
                    ) : null}
                  </td>
                </tr>
              ))
              )
            }
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default RecurringPayment;
