import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  color: var(--corBranca);
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: var(--corEscura);
  color: var(--corBranca);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  
  text-align: center;

  div {
    margin-bottom: 20px;

  }

  div h2{
    font-size: 1.5em;
  }

  div h4{
    font-size: 1.5em;
    color: var(--corBranca);
  }

  @media(orientation: portrait){
    div h2{
      font-size: 1.1em;
    }
  }
`;

export const TableCustom = styled.table`

  background: var(--corEscura);
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-top: 8px;
  margin: 30px 0 40px 0;
  padding: 0;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);

 
  
 
  margin-top: 2%;
  opacity: 1;
  width: ${props => props.large ? "100%" : "100%"};


  &:nth-child(2){
    background: var(--corEscura);
    
    width:100%;
    
  }  



 

  thead{
    border-radius: 5px;
    margin: 10px 0 10px 0;
    text-align: left;
  }
  
  th{

    letter-spacing: var(--unnamed-character-spacing-0);
    opacity: 1;  
    padding: 16px 18px 12px 12px;
    text-align: center;
  }



  td{
    color: var(--corBranca);
    font-size: 12px;


    
    text-align: center;
    min-height: 35px;
   
  }


  th{
    padding: .625em;
  }

  td{
    padding: .625em 0;
  }

  td:nth-child(2){
    text-align: justify;
  }
 
  th {
   font-size: .85em;
   color: var(--azulEsverdeado);
   letter-spacing: .1em;
   border-bottom: 1px solid var(--azulEsverdeado);
   text-transform: uppercase;
 }


 tbody tr{
  border-bottom: 2px solid var(--unnamed-color-828282);
  
 }


 tbody tr:last-child {
  border-bottom: none;
 }








@media screen and (max-width: 600px) {


  background: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
  border: 0;
 


  &:nth-child(2){
    background: transparent;
    border: 0;
    width: 100%;
    
  } 
    


 caption {
    font-size: 1.3em;
  }
  
 thead {
  
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }






  
    
  
  
 tr {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  display: block;
  justify-content: center;
  margin-bottom: 2em;
  }
  
 td {
    border-bottom: 1px solid var(--azulEsverdeado);
    display: block;
    font-size: .8em;
    min-height: 35px;
    text-align: right;
    margin:
  }
  
 td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    
    */
    content: attr(data-label);
    float: left;
    color: var(--azulEsverdeado);
    font-weight: bold;
    text-transform: uppercase;
  }
  
 td:last-child {
    border-bottom: 0;
  }



  tbody tr{
    border-bottom: 2px solid var(--corPrincipal2);
    
   }
  
  
   tbody tr:last-child {
    border-bottom: 2px solid var(--corPrincipal2);
   }

   td{
    padding: .625em ;
  }

}



`

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`