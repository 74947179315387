import styled from "styled-components";

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
`

// height: 400px; tamanho pre-definido no container
export const Container = styled.div`
  width: 100%;
  background-color: var(--corEscura);
  color: var(--corBranca);
  border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
  margin: 2% 0 50px 0px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`


export const Button = styled.button`
  background-color: var(--corPrincipal2);
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: auto;
  `

export const ButtonVideoOff = styled.button`
  background-color: ${props => props.red ? "#DC1076" : props.is_active ? "#828282" : "var(--corPrincipal2)"};
  font-size: 12px;
  width: 100%;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1% 0% 2% auto;
  color: ${props => props.red ? "var(--corBranca)" : "initial"};
`

export const ButtonsContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 20%;
`

export const VideoArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1% 0 0 0%;
  flex-wrap: wrap;
  width: 98%;

  span{
    margin: 1% 0 1% 0;
    font-weight: bold;
  }


  @media(orientation: portrait){
    
      text-align: center;
      margin: 1% 0 0 0%;
  
    
    
  }
`

export const VideoAreaIndividual = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid var(--unnamed-color-828282);
  width: 100%;

  margin: 0 0 0px 0;
  


  @media(orientation: portrait){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--unnamed-color-828282);

    & div {
      width: 70%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin: 0px 0 20px 0;
    }

    & div:first-child{
      margin: 20px 0 20px 0;
    }




  }
`
export const VideoDescription = styled.div`
  font-size: 0.80em;
  display: block;
  height: 100%;
  width: 35%;
  margin: 0 0% 0 2%;

  h5{
    font-weight: bold;
    cursor: pointer;
    color: var(--corPrincipal2);
    
  } 


  @media(orientation: portrait){
    margin: 0 0% 0 0%;
  }

`

export const DivMain = styled.div`
  position: absolute; 
  z-index: 2;
`

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

`

export const ModalContainer = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: var(--corEscura);
  color: var(--corBranca);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: absolute;
  z-index: 4;
`

export const DivTitle = styled.div`
  display: inline-block;
  letter-spacing: 0px;
  color: var(--corBranca);
  opacity: 1;
  text-align: left;

  h3{
    font-weight: bold;
    text-align: left;
  }
`

export const Body = styled.div`
color: var(--corBranca);
  flex: 50%;
  text-align: left;

  label{
    margin-top: 25px;
    font-size: 18px;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px 0 0% 0;


  @media(orientation: portrait){
    justify-content: center;
    align-items: center;
  }
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0 7px;
`