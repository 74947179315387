import React, { useState, useEffect } from 'react'
import {
  Container, Form, Label,
  InputText, Button, AvaliacaoSpace,
  Function, SelectFunction, InputStar,
  LabelStar, DescricaoArea, UpDivs, InputDescricao, DivInput, Span, I, InputOfForm, TextareaOfForm, SelectOfForm
} from './styles'
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { GetListaArtista } from '../../../services/artist';
import { createArtistMediation, GetProducers } from '../../../services/mediation';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, SUCESS, ERROR, SUCCESS } from '../../../components/Notifications';

const CriarAvaliacao = () => {
  const navigate = useNavigate()

  const [hover, setHover] = useState(null);
  const [artists, setArtists] = useState([]);
  const [producers, setProducers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    grade: 5,
    evaluator: '',
    rated: '',
    description: '',
    tag: []
  })

  useEffect(() => {
    loadArtists();
    loadProducers();
  }, [])

  async function loadArtists() {
    const response = await GetListaArtista();
    if (response) {
      setArtists(response.data);
    }
  }

  async function loadProducers() {
    const response = await GetProducers();
    if (response) {
      setProducers(response.data);
    }
  }

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const response = await createArtistMediation(payload);
    setLoading(false);
    if (response) {
      addToast(SUCCESS, 'Avaliação criada com sucesso');
      navigate("/mediacao/avaliacoes/artista");
    } else {
      addToast(ERROR, 'Não foi possível enviar essa avaliação');
    }
  }

  return (
    <>
      <h4><b>Criar Avaliação Artista</b></h4>
      <Container>
        <Form onSubmit={handleSubmit}>
          <UpDivs>

            <AvaliacaoSpace>


              <DivInput>

                <SelectOfForm
                  required
                  onChange={({ target }) => handleChange('rated', Number(target.value))} >
                  <option hidden defaultChecked>Escolha um Artista</option>
                  {artists.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}



                </SelectOfForm>

                <Span>Artista Avaliado</Span>
                <I></I>
              </DivInput>






              <DivInput>

                <SelectOfForm
                  required
                  onChange={({ target }) => handleChange('evaluator', Number(target.value))} >
                  <option hidden defaultChecked>Escolha um Produtor</option>
                  {producers.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}


                </SelectOfForm>

                <Span>Produtor Avaliador</Span>
                <I></I>
              </DivInput>
            </AvaliacaoSpace>

            <Function id="alvo">
              <Label>
                Avaliação <br />
                {[...Array(5)].map((star, i) => {
                  const ratingValue = i + 1;
                  return (
                    <LabelStar>
                      <InputStar
                        type="radio"
                        name="rating"
                        onClick={() => handleChange('grade', Number(ratingValue))}

                      />
                      <FaStar className="star"
                        color={ratingValue <= (hover || payload.grade) ? "var(--corPrincipal2)" : "#e4e5e9"}
                        size={26}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)} />
                    </LabelStar>
                  )
                })}
              </Label>
            </Function>
            <DescricaoArea>


              <DivInput >

                <TextareaOfForm
                  maxLength={140}
                  required
                  onChange={({ target }) => handleChange('description', target.value)} />

                <Span className='spanTextarea'>Descrição</Span>

              </DivInput>


            </DescricaoArea>

            
            {loading ? <LoadingSpinner /> : <Button type="submit">Criar avaliação</Button>}

          </UpDivs>

        </Form>
      </Container>

    </>
  )
}

export default CriarAvaliacao;