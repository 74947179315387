import styled from 'styled-components'; 


export const TableCustom = styled.table`
  background: var(--corEscura);
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-top: 8px;
  margin: 30px 0 40px 0;
  padding: 0;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
 
  
 
  margin-top: 2%;
  opacity: 1;
  width: ${props => props.large ? "100%" : "auto"};


  &:nth-child(2){
    background: var(--corEscura);
    
    width:100%;
    
  }  


  & select{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: 2px solid var(--corPrincipal2);
    background: transparent;
    color: var(--corBranca);
  }


  & select option{
    color: var(--corEscura);
  }
 

  thead{
    border-radius: 5px;
    margin: 10px 0 10px 0;
    text-align: left;
  }
  
  th{

    letter-spacing: var(--unnamed-character-spacing-0);
    opacity: 1;  
    padding: 16px 18px 12px 12px;
    text-align: center;
  }

  td{
    color: var(--corBranca);
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 4px;
    padding-left: 14px;
    text-align: justify;
    min-height: 35px;
  }

 
 
  th {
   font-size: .85em;
   color: var(--azulEsverdeado);
   letter-spacing: .1em;
   border-bottom: 1px solid var(--azulEsverdeado);
   text-transform: uppercase;
 }


 tbody tr{
  border-bottom: 2px solid var(--unnamed-color-828282);
  
 }


 tbody tr:last-child {
  border-bottom: none;
 }



}



@media screen and (max-width: 600px) {

  height: 100%;
  background: var(--corEscura);
  margin: 30px 0 40px 0;
  padding: 0;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  table-layout: fixed;



&:nth-child(2){
  background: var(--corEscura);
  
  width:100%;
  
}  


caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

 

 th,
 td {
  position: relative;
  padding: .625em;
  width: 100%;
  
  flex-wrap: wrap;
  text-align: center;
}

 th {
  font-size: .85em;
  color: var(--azulEsverdeado);
  letter-spacing: .1em;
  border-bottom: 1px solid var(--azulEsverdeado);
  text-transform: uppercase;
}


td {
  font-size: 100%;
  color: var(--corBranca);
  inline-size: 100%; 
  overflow-wrap: break-word;

  
}

&:last-child {
  

  background: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
  border: 0;


  &:nth-child(2){
    background: transparent;
    border: 0;
    width: 100%;
    
  } 
    


 caption {
    font-size: 1.3em;
  }
  
 thead {
  
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  
    
  
  
 tr {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  display: block;
  justify-content: center;
  margin-bottom: 2em;
  }
  
 td {
    border-bottom: 1px solid var(--azulEsverdeado);
    display: block;
    font-size: .8em;
    min-height: 35px;
    text-align: right;
    
  }
  td:nth-child(2){
    text-align: justify;
  }
  
 td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    
    */
    content: attr(data-label);
    float: left;
    color: var(--azulEsverdeado);
    font-weight: bold;
    text-transform: uppercase;
  }
  
 td:last-child {
    border-bottom: 0;
  }
}


tbody tr{
  border-bottom: 2px solid var(--corPrincipal2);
 }
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 20px;
  padding: 0 7px;
`