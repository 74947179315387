import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Content, Form, FormRow, FormHalf, Header } from "./styles";
import { CreateClient, PatchClient } from "../../../services/client";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { addToast, SUCCESS, ERROR } from "../../../components/Notifications";
import { codigoPaises } from "../../../Helpers/index";

import InputMask from "react-input-mask";
import { createNotification } from "../../../services/notification";
import FilePondUpload from "../../../components/FilePondUpload";
import { DivFotos } from "../../../components/EditPerfil/style";

function PhoneInput(props) {
  return (
    <InputMask
      mask="999999999"
      placeholder="999999999"
      value={props.value}
      onChange={props.onChange}
      style={props.style}
    ></InputMask>
  );
}

function Create() {
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  function generateRandomPassword(length) {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    var password = '';
    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    return password;
  }

  useEffect(() => {
    const url = images[0]
    const parts = url?.split('/');
    const image = '/' + parts?.slice(-2).join('/');

    user.image = image
  }, [images])

  const [user, setUser] = useState({
    id: "",
    name: "",
    phone_prefix: "",
    phone: "",
    taxpayer: "",
    address: "",
    address_number: "",
    address_city: "",
    address_state: "",
    full_address: "",
    site: "",
    image: '',
    trading_name: "",
    password: generateRandomPassword(7)
  });

  /* useEffect(() => {

    const { profile } = location.state;

    setName(profile.name);
    
    setUser({
      id: profile.id,
      name: profile.name,
      email: profile.email,
      phone_prefix: profile.phone_prefix,
      phone: profile.phone,
      taxpayer: profile.taxpayer,
      address_street: profile.address_street,
      address_number: profile.address_number,
      address_city: profile.address_city,
      address_state: profile.address_state,
      full_address: profile.full_address,
      site: profile.site,
      trading_name: profile.trading_name,
    });
  }, [location]); */

  const handleChangeValue = (field, value) =>
    setUser({ ...user, [field]: value });

  const style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #828282",
    borderRadius: "10px",
    padding: "0px 16px 0px 16px",
    height: "40px",
    marginRight: "4px",
  };

  const divColumn = {
    display: "flex",
    flexDirection: "column",
  };

  const divCustom = {
    display: "flex",
  };

  async function handleSave(e) {
    e.preventDefault();

    var checkNumber = /\d+/g;

    if (user.name.match(checkNumber) > 0) {
      addToast(ERROR, "Ops!", "Não é permitido números no campo nome.");
    } else {
      setLoading(true);
      const resp = await CreateClient(user);
      setLoading(false);
      if (resp && resp.status === 200) {
        addToast(SUCCESS, "Dados alterados com sucesso!");
        navigate("/clientes/profiles");
      } else {
        setLoading(false);
        addToast(ERROR, "Ocorreu um erro ao cadastrar cliente");
      }
    }
  }

  return (
    <Container>
      <h4>Cadastro de Cliente</h4>
      <Content>
        <Form onSubmit={handleSave}>
          <Header>
            <p>{name}</p>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Salvar Alterações</Button>
            )}
          </Header>
          <FormRow>
            <FormHalf>
              <Input
                label="Contacto"
                value={user.name}
                onChange={(event) =>
                  handleChangeValue("name", event.target.value)
                }
                required
              />
            </FormHalf>
            <FormHalf>
              <Input
                label="Nome da empresa (Opcional)"
                value={user.trading_name}
                onChange={(event) =>
                  handleChangeValue("trading_name", event.target.value)
                }
              />
            </FormHalf>
          </FormRow>
          <FormRow>
            <FormHalf>
              <div style={divColumn}>
                <label>Telefone</label>
                <div style={divCustom}>
                  <select
                    style={style}
                    value={user.phone_prefix}
                    onChange={(event) =>
                      handleChangeValue("phone_prefix", event.target.value)
                    }
                  >
                    {codigoPaises.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {/* <MaskInput
										mask={'(000) 00000-0000'}
										placeholder="(000) 00000-0000"
										onChange={event => handleChangeValue('phone', event.target.value.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""))}
										style={style}
										value={user.phone}
									/> */}
                  <PhoneInput
                    value={user.phone}
                    onChange={({ target }) =>
                      handleChangeValue(
                        "phone",
                        target.value
                          .replace("(", "")
                          .replace(")", "")
                          .replace(" ", "")
                          .replace("-", "")
                      )
                    }
                    style={style}
                  ></PhoneInput>
                </div>
              </div>
            </FormHalf>
            <FormHalf>
              <Input
                label="NIF/NIPC"
                value={user.taxpayer}
                onChange={(event) =>
                  handleChangeValue("taxpayer", event.target.value)
                }
                required
              />
            </FormHalf>
            <FormHalf>
              <Input
                label="Cidade"
                value={user.address_city}
                onChange={(event) =>
                  handleChangeValue("address_city", event.target.value)
                }
                required
              />
            </FormHalf>
            <FormHalf>
              <Input
                label="Distrito"
                value={user.address_state}
                onChange={(event) =>
                  handleChangeValue("address_state", event.target.value)
                }
                required
              />
            </FormHalf>
          </FormRow>
          <FormRow>
            <FormHalf>
              <Input
                label="Morada"
                value={user.full_address}
                onChange={(event) =>
                  handleChangeValue("full_address", event.target.value)
                }
                required
              />
            </FormHalf>
            <FormHalf>
              <Input
                label="Email"
                value={user.email}
                onChange={(event) =>
                  handleChangeValue("email", event.target.value)
                }
              />
            </FormHalf>
          </FormRow>
          <FormRow></FormRow>
          <FormRow>
            <FormHalf>
              <Input
                label="Site (opcional)"
                value={user.site}
                onChange={(event) =>
                  handleChangeValue("site", event.target.value)
                }
              />
            </FormHalf>
          </FormRow>
          <DivFotos>
            {/* {preview?.map((item, index) => (
            <div key={index}>
              <img src={item} alt="Modelo" />
              <span onClick={() => handleRemoveImage(index)}>Remover</span>
            </div>
          ))} */}

            <FilePondUpload
              label={'Selecione seu documento de identificação'}
              maxFiles={1}
              folder="cliente"
              name={"Base"}
              images={images}
              setImages={(param) => {
                setImages([...images, param]);
              }}
            />

            {/* <input
            ref={inputRef}
            accept="image/png, image/jpeg"
            type="file"
            multiple
            hidden
            onInput={(event) => {
              if (!checkFileSize(event)) {
                handleFileUpload(event);
              } else {
                addToast(ERROR, "Foto ultrapassa limite de 6 MB");
              }
            }}
            onClick={(event) => (event.target.value = null)}
          /> */}
          </DivFotos>
        </Form>
      </Content>
    </Container>
  );
}

export default Create;
