import React, { useState } from 'react';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';
import { CreateType } from '../../../services/permission';
import {
  ModalContainer, ModalBody, ModalButton
} from './styles';

import {
  DivInput, Span, I, InputOfForm
} from '../style';

const TypeModal = ({ toggleModal }) => {

  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({ name: '' })

  async function handleSubmit() {
    if (payload.name) {
      setLoading(true);
      const response = await CreateType(payload);
      setLoading(false);
      if (response) {
        addToast(SUCCESS, 'Tipo de usuário criado com sucesso');
        toggleModal(true);
      } else {
        addToast(ERROR, 'Não foi possível criar um tipo novo');
      }
    }
  }

  return (
    <ModalContainer>

      <ModalBody>
        <div className='text-center'>
          <h4 style={{fontSize: "0.9em"}}>Criar Novo Tipo de Usuário</h4>
        </div>


        <DivInput style={{margin: " 30px 0"}}>

          <InputOfForm
            required
            onChange={({ target }) => setPayload({ ...payload, name: target.value })} />

          <Span>Nome</Span>

          <I></I>

        </DivInput>



        {loading ? <LoadingSpinner noMargin={true} /> : <div className="d-flex justify-content-end">
          <ModalButton font12 blue onClick={handleSubmit}>CRIAR</ModalButton>
          <ModalButton font12 onClick={toggleModal}>CANCELAR</ModalButton>
        </div>}
      </ModalBody>
    </ModalContainer>
  )
}

export default TypeModal;