import { NotificationManager } from "react-notifications";

export const ERROR = 'error';
export const SUCCESS = 'success';

const Notification = (
  title,
  message,
  type = 'info',
  timeout = 4000,
  callback = () => { }
) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message, title, timeout);
      break;
    case 'success':
      NotificationManager.success(message, title, timeout);
      break;
    case 'warning':
      NotificationManager.warning(message, title, timeout);
      break;
    case 'error':
      NotificationManager.error(message, title, timeout, callback);
      break;
    default:
      NotificationManager.info(message, title);
      break
  }
};

export function addToast(type, title, message){
  switch(type){
    case SUCCESS:
      NotificationManager.success(message, title, 3000);
      break;
    case ERROR:
      NotificationManager.error(message, title, 3000);
      break;
    default:
      break;
  }
}


export default Notification;