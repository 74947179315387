import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { useAuth } from "./AppContext/Context";
import Header from "./components/Navbar";
import Login from "./pages/Login";
import CreateGlobalStyle from "./global";
import MenuSidebar from "./components/MenuLateral";
import routes from "./routes";
import "./App.css";

function App() {
  const { user, cores } = useAuth();

  /*
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={props =>
      (
        user ? (
          <Component {...props} />
        ) : (
          <Navigate to={{ pathname: '/', state: { from: props.location } }} />
        )
      )} />)
  }
  */

  /*
  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={props =>
      (
        user ? (
          <Navigate to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      )} />)
  }
  */

  const routeComponents = routes.map((item) => (
    <Route exact path={item.path} element={item.component} key={item.path} />
  ));

  return (
      <div className="App">
        <CreateGlobalStyle cores={cores}></CreateGlobalStyle>
        <BrowserRouter>
          {user ? (
            <>
              <Header />
              <main className="mainContent">
                <MenuSidebar />
                <div className="content">
                  <div className="contentContainer">
                    <Routes>{routeComponents}</Routes>
                  </div>
                </div>
              </main>
            </>
          ) : (
            <>
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>

              <Navigate to="/" />
            </>
          )}
        </BrowserRouter>
      </div>
  );
}

export default App;
