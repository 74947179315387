import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  height: 74vh;
  background-color: #fff;
  margin: 50px 0 50px 0;
`

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const UpDivs = styled.div`
  display: flex;
  justify-content: space-between;
  // height: 44vh;
`

export const Label = styled.label`
  margin-top: ${(props) => props.noMargin ? '0' : '12px'};
`

export const InputTextClass = styled.input`
  width: ${props => props.small ? '80px' : '100%'};
  height: ${props => props.small ? '34px' : '44px'};
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
  border-radius: 8px;
`
export const Button = styled.button`
  background-color: ${(props) => props.loading ? "#707070" : "var(--corPrincipal2)"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  color: var(--corBranca);
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px 34px 0 auto;
`

export const InputsArea = styled.div`
  margin: 0 20px 0 20px;
  width: 450px;
`

export const DescricaoAreaClass = styled.div`
  width: 98%;
  height: 36vh;  
  margin: 0 2% 0 2%;
`

export const InputDescricao = styled.textarea`
  margin-top: 2%;
  width: 96%;
  height: 50%;
  padding: 4px 0 0 6px;
`

export const RowInput = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 12px;
`