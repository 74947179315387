import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  FirstDiv,
  FormCustom,
  ElemtDiv,
  InputSlide,
  DivCentral,
  DivInput,
  InputOfForm,
  SelectOfForm,
  Span,
  I,
  CustomCurrencyInputOfForm,
} from "./style";

import {
  DivCustom,
  ElemtDivCabelo,
  ElemtDivOlhos,
  ButtonCabelo,
  Hr,
  DivFotos,
  ButtonSalvar,
} from "../../components/EditPerfil/style";

import { FormHalf } from "../../pages/Customer/Edit/styles";
import { PostArtista } from "../../services/artist";
import { addToast, ERROR, SUCCESS } from "../../components/Notifications";
import { codigoPaises } from "../../Helpers/index";
import { GetRecurring } from "../../services/recurringType";
import FilePondUpload from "../../components/FilePondUpload.js";

import InputMask from "react-input-mask";

function PhoneInput(props) {
  return (
    <InputMask
      mask={props.mask}
      placeholder={props.mask}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
    ></InputMask>
  );
}

function CadastroArtista() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);

  const [phoneMask, setPhoneMask] = useState("999999999");
  const [recurringTypeList, setRecurringTypeList] = useState([]);
  const [payload, setPayload] = useState({
    name: "",
    nation: "",
    birth_date: "",
    address_city: "",
    email: "",
    phone_prefix: "+1",
    phone: "",
    instagram: "",
    hair: "",
    gender: "",
    district: "",
    eye: "",
    skin: "",
    hability: null,
    description: "",
    stature: 250,
    waist: 200,
    hip: 200,
    feet: 38,
    bust: 200,
    monthly_fee: 0,
    expiration_date: "",
    recurring_payment_type: null,
  });

  useEffect(() => {
    loadRecurringType();
  }, []);

  async function loadRecurringType() {
    try {
      const response = await GetRecurring();
      setRecurringTypeList(response);
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(name, value) {
    if (name === "phone_prefix") {
      setPayload({ ...payload, [name]: value });
      if (value === "+1") {
        setPhoneMask("999999999");
      } else if (value === "+55") {
        setPhoneMask("(99) 99999-9999");
      } else if (value === "+351") {
        setPhoneMask("999999999");
      } else {
        setPhoneMask("");
      }
    } else if (name === "birth_date" || name === "expiration_date") {
      setPayload({
        ...payload,
        [name]: `${value}T00:00:00`,
      });
    } else if (
      name === "stature" ||
      name === "waist" ||
      name === "hip" ||
      name === "bust"
    ) {
      if (value >= 1 && value <= 200) {
        setPayload({ ...payload, [name]: value });
      } else {
      }
    } else if (
      name === "feet"
    ) {
      if (value >= 1 && value <= 50) {
        setPayload({ ...payload, [name]: value });
      }
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }


  async function handleSubmit(event) {
    event.preventDefault();
    if (validForm()) {
      setLoading(true);
      if (images.length > 0) {
        try {
          console.log(images);

          const toSend = {
            ...payload,
            images,
          };

          const resp = await PostArtista(toSend);
          setLoading(false);
          if (resp) {
            addToast(SUCCESS, "Dados salvos com sucesso!");
            navigate("/artista/aprovacao");
          }
        } catch (err) {
          setLoading(false);
          addToast(ERROR, "Ocorreu um erro ao cadastrar artista");
        }
      } else {
        const resp = await PostArtista(payload);
        setLoading(false);
        if (resp) {
          addToast(SUCCESS, "Dados salvos com sucesso!");
          navigate("/artista/aprovacao");
        }
      }
    }
  }

  function validForm() {
    const checkNumber = /\d+/g;
    //const checkString = /^\d+$/;

    if (!payload.hair || !payload.skin || !payload.eye) {
      addToast(ERROR, "Informe todos os dados");
      return false;
    }
    if (!payload.gender) {
      addToast(ERROR, "Informe o gênero");
      return false;
    }
    if (payload.name.match(checkNumber) > 0) {
      addToast(ERROR, "Ops!", "Não é permitido números no campo nome.");
      return false;
    }
    return true;
  }

  // function checkFileSize(event) {
  //   const _imgs = [...event.target.files];
  //   return _imgs.find((file) => file.size >= 6291456);
  // }

  return (
    <>
      <h4>Cadastro Artista</h4>
      <FormCustom onSubmit={handleSubmit}>
        <ElemtDiv>
          <FirstDiv>
            <FormHalf>
              <DivInput>
                <InputOfForm
                  type="text"
                  required="required"
                  onChange={({ target }) => handleChange("name", target.value)}
                />

                <Span>Nome Completo</Span>
                <I></I>
              </DivInput>
            </FormHalf>
            <FormHalf row>
              <div>
                <DivInput>
                  <InputOfForm
                    type="date"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("birth_date", target.value)
                    }
                  />

                  <Span>Data Nascimento</Span>
                  <I></I>
                </DivInput>
              </div>

              <div>
                <DivInput>
                  <InputOfForm
                    type="date"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("expiration_date", target.value)
                    }
                  />

                  <Span>Data Expiração</Span>
                  <I></I>
                </DivInput>
              </div>
              <div style={divColumn}>
                <DivInput>
                  <SelectOfForm
                    required="required"
                    value={payload.phone_prefix}
                    onChange={({ target }) =>
                      handleChange("phone_prefix", target.value)
                    }
                  >
                    {codigoPaises?.map((item) => (
                      <option>{item}</option>
                    ))}
                  </SelectOfForm>

                  <Span>Prefixo</Span>
                  <I></I>
                </DivInput>
                {/* <MaskInput
                    required
                    mask={phoneMask}
                    placeholder={phoneMask}
                    onChange={({ target }) => handleChange('phone', target.value)}
                    style={style} />  */}
              </div>
              <div>
                <DivInput>
                  <PhoneInput
                    type={"number"}
                    value={payload.phone}
                    onChange={({ target }) =>
                      handleChange("phone", target.value)
                    }
                  ></PhoneInput>
                  <Span>Telefone</Span>
                  <I></I>
                </DivInput>
              </div>
              <div style={divColumn}>
                <DivInput>
                  <SelectOfForm
                    required="required"
                    value={payload.phone_prefix}
                    onChange={({ target }) =>
                      handleChange("phone_prefix", target.value)
                    }
                  >
                    {codigoPaises?.map((item) => (
                      <option>{item}</option>
                    ))}
                  </SelectOfForm>

                  <Span>Prefixo</Span>
                  <I></I>
                </DivInput>
                {/* <MaskInput
                    required
                    mask={phoneMask}
                    placeholder={phoneMask}
                    onChange={({ target }) => handleChange('phone', target.value)}
                    style={style} />  */}
              </div>
              <div>
                <DivInput>
                  <PhoneInput
                    type={"number"}
                    value={payload.phone}
                    onChange={({ target }) =>
                      handleChange("phone", target.value)
                    }
                  ></PhoneInput>
                  <Span>Whatsapp</Span>
                  <I></I>
                </DivInput>
              </div>
            </FormHalf>

            <FormHalf row>
              <div>
                <DivInput>
                  <InputOfForm
                    type="text"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("email", target.value)
                    }
                  />

                  <Span>Email</Span>
                  <I></I>
                </DivInput>
              </div>
              <div>
                <DivInput>
                  <InputOfForm
                    type="text"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("instagram", target.value)
                    }
                  />

                  <Span>Instagram</Span>
                  <I></I>
                </DivInput>
              </div>
              <div>
                <DivInput>
                  <InputOfForm
                    type="number"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("followers", target.value)
                    }
                  />

                  <Span>Nº seguidores</Span>
                  <I></I>
                </DivInput>
              </div>
              <div>
                <DivInput>
                  <SelectOfForm
                    required="required"
                    onChange={({ target }) =>
                      handleChange("gender", target.value)
                    }
                  >
                    <option hidden>Escolha um Gênero</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Trans Masculino">Trans Masculino</option>
                    <option value="Trans Feminino">Trans Feminino</option>
                    <option value="Não Binario">Não Binario</option>
                    <option value="Outros">Outros</option>
                    <option value="Todos">Todos</option>
                  </SelectOfForm>

                  <Span>Gênero</Span>
                  <I></I>
                </DivInput>
              </div>
            </FormHalf>
            <FormHalf row>
              <div>
                <DivInput>
                  <InputOfForm
                    type="text"
                    required="required"
                    onChange={({ target }) =>
                      handleChange("nation", target.value)
                    }
                  />

                  <Span>Nacionalidade</Span>
                  <I></I>
                </DivInput>
              </div>
              <div>
                <DivInput>
                  <SelectOfForm
                    required="required"
                    onChange={({ target }) =>
                      handleChange("district", target.value)
                    }
                  >
                    <option hidden>Escolha um Distrito</option>
                    <option value="Porto">Porto</option>
                    <option value="Lisboa">Lisboa</option>
                    <option value="Brasil">Brasil</option>
                    <option value="Outros">Outros</option>
                    <option value="Todos">Todos</option>
                  </SelectOfForm>

                  <Span>Distrito</Span>
                  <I></I>
                </DivInput>
              </div>
              <div>
                <DivInput>
                  <CustomCurrencyInputOfForm
                    required="required"
                    prefix="€ "
                    value={payload.monthly_fee}
                    onChange={(masked, raw) => {
                      handleChange("monthly_fee", raw);
                    }}
                  />

                  <Span>Valor Pagamento</Span>
                  <I></I>
                </DivInput>
              </div>

              <div>
                <DivInput>
                  <SelectOfForm
                    required="required"
                    onChange={({ target }) =>
                      handleChange("recurring_payment_type", target.value)
                    }
                  >
                    <option hidden>Seleciona</option>
                    {recurringTypeList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectOfForm>

                  <Span style={{ fontSize: ".75rem" }}>Tipo Recorrência</Span>
                  <I></I>
                </DivInput>
              </div>
            </FormHalf>
          </FirstDiv>
        </ElemtDiv>

        <DivCustom>
          <ElemtDivCabelo>
            <label>Cabelo</label>
            <Hr></Hr>
            <div>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Castanho Escuro"}
                onClick={() => handleChange("hair", "Castanho Escuro")}
              >
                Castanho Escuro
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Loiro"}
                onClick={() => handleChange("hair", "Loiro")}
              >
                Loiro
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Ruivo"}
                onClick={() => handleChange("hair", "Ruivo")}
              >
                Ruivo
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Grisalho"}
                onClick={() => handleChange("hair", "Grisalho")}
              >
                Grisalho
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Castanho Claro"}
                onClick={() => handleChange("hair", "Castanho Claro")}
              >
                Castanho Claro
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Colorido"}
                onClick={() => handleChange("hair", "Colorido")}
              >
                Colorido
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Preto"}
                onClick={() => handleChange("hair", "Preto")}
              >
                Preto
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.hair === "Careca"}
                onClick={() => handleChange("hair", "Careca")}
              >
                Careca
              </ButtonCabelo>
            </div>
          </ElemtDivCabelo>

          <ElemtDivOlhos>
            <label>Olhos</label>
            <Hr></Hr>
            <div>
              <ButtonCabelo
                type="button"
                selected={payload.eye === "Castanho Escuro"}
                onClick={() => handleChange("eye", "Castanho Escuro")}
              >
                Castanho Escuro
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.eye === "Verde"}
                onClick={() => handleChange("eye", "Verde")}
              >
                Verde
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.eye === "Azul"}
                onClick={() => handleChange("eye", "Azul")}
              >
                Azul
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.eye === "Castanho Claro"}
                onClick={() => handleChange("eye", "Castanho Claro")}
              >
                Castanho Claro
              </ButtonCabelo>
            </div>
          </ElemtDivOlhos>
          <ElemtDivOlhos>
            <label>Pele</label>
            <Hr></Hr>
            <div>
              <ButtonCabelo
                type="button"
                selected={payload.skin === "Branca"}
                onClick={() => handleChange("skin", "Branca")}
              >
                Branca
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.skin === "Negra"}
                onClick={() => handleChange("skin", "Negra")}
              >
                Negra
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.skin === "Morena"}
                onClick={() => handleChange("skin", "Morena")}
              >
                Morena
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.skin === "Indígena"}
                onClick={() => handleChange("skin", "Indígena")}
              >
                Indígena
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={payload.skin === "Asiática"}
                onClick={() => handleChange("skin", "Asiática")}
              >
                Asiática
              </ButtonCabelo>
              <ButtonCabelo
                type="button"
                selected={
                  payload.skin === "Sul Asiática - Índia, Paquistão, etc."
                }
                onClick={() =>
                  handleChange("skin", "Sul Asiática - Índia, Paquistão, etc.")
                }
              >
                Sul Asiática - Índia, Paquistão, etc.
              </ButtonCabelo>
            </div>
          </ElemtDivOlhos>

          <DivCentral>
            <div style={divCustom}>
              <label>
                Altura:{" "}
                <InputSlide
                  value={payload.stature}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("stature", Number(target.value))
                  }
                />{" "}
                cm
              </label>

              <p>0 - 250</p>
            </div>
            {/* <Slider
                domain={[0, 250]}
                values={[payload.stature]}
                selected={height}
                onChangeSelected={(value) => handleChange("stature", value[0])}
              /> */}
            <div style={divCustom}>
              <label>
                Cintura:{" "}
                <InputSlide
                  value={payload.waist}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("waist", Number(target.value))
                  }
                />{" "}
                cm
              </label>

              <p>0 - 200</p>
            </div>
            {/* <Slider
                domain={[0, 400]}
                values={[payload.waist]}
                selected={waist}
                onChangeSelected={(value) => handleChange("waist", value[0])}
              /> */}
            <div style={divCustom}>
              <label>
                Anca:{" "}
                <InputSlide
                  value={payload.hip}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("hip", Number(target.value))
                  }
                />{" "}
                cm
              </label>

              <p>0 - 200</p>
            </div>
            {/* <Slider
                domain={[0, 400]}
                values={[payload.hip]}
                selected={hip}
                onChangeSelected={(value) => handleChange("hip", value[0])}
              /> */}
            <div style={divCustom}>
              <label>
                Peito:{" "}
                <InputSlide
                  value={payload.bust}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("bust", Number(target.value))
                  }
                />{" "}
                cm
              </label>

              <p>0 - 200</p>
            </div>
            {/* <Slider
                domain={[0, 400]}
                values={[payload.bust]}
                selected={bust}
                onChangeSelected={(value) => handleChange("bust", value[0])}
              /> */}
            <div style={divCustom}>
              <label>
                Sapato:{" "}
                <InputSlide
                  value={payload.feet}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("feet", Number(target.value))
                  }
                />{" "}
                EU
              </label>

              <p>0 - 50</p>
            </div>
          </DivCentral>
        </DivCustom>

        <DivFotos>
          <FilePondUpload
            folder="artista"
            name={"Base"}
            images={images}
            setImages={(param) => {
              setImages([...images, param]);
            }}
            setUploading={(e) => setImageUploading(e)}
            remove={(imageId) => {
              if (imageId) {
                const filteredPhotosList = images.filter(
                  (item) => item !== String(imageId.serverId)
                );

                setImages(filteredPhotosList);
              }
            }}
          />
        </DivFotos>

        {loading ? (
          <div style={{ textAlign: "right" }}>
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          <ButtonSalvar disabled={imageUploading} style={{ opacity: imageUploading ? 0.6 : 1 }}>SALVAR ALTERAÇÕES</ButtonSalvar>
        )}
      </FormCustom>
    </>
  );
}

export default CadastroArtista;

const style = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #828282",
  borderRadius: "10px",
  padding: "0px 16px 0px 16px",
  height: "40px",
  marginRight: "4px",
};

const divCustom = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
};

const divColumn = {
  display: "flex",
  flexDirection: "column",
};