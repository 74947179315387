import React from "react";
import { useNavigate } from 'react-router-dom'
import { TableCustom, ButtonEdit } from './styles'

function TabelaAvaliacoes({
  mediation, handleHideMediation, path,
  loading, canEdit, count
}){
  const navigate = useNavigate()
  
  function handleNavigation(item){
    navigate(
      `/editar-avaliacao/${path}`,
      {
        state: item
      }
    );
  }

  const styles = {
    paddingLeft: 26,
  }

  const style = { 
    width: 330,
  }

  return(
    <div>
      <TableCustom large>
        <thead>
          <th data-label="Avaliador">Avaliador</th>
          <th data-label="Avaliado">Avaliado</th>
          <th data-label="Descrição">Descrição</th>
          <th data-label="Função">Total: {count}</th>
        </thead>
        <tbody>
          {mediation.map((item, index) => (
            <tr key={item.id}>
              <td data-label="Avaliador">{item.evaluator.name}</td>
              <td data-label="Avaliado">{item.rated.name}</td>
              <td data-label="Descrição">{item.description}</td>
              <td data-label="Função" >
                {canEdit ? 
                item.id === loading ? 
                <div className="text-center">
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
                :
                <>
                  <ButtonEdit azul onClick={() => handleNavigation(item)}>EDITAR AVALIAÇÃO</ButtonEdit>
                  <ButtonEdit 
                  azul={item.hide}
                  onClick={() => handleHideMediation(item)}>
                    {item.hide ? `EXIBIR` : `OCULTAR`}
                  </ButtonEdit>
                </> 
                : null }
              </td>
            </tr>
          ))}
        </tbody>
      </TableCustom>
    </div>
  )
}

export default TabelaAvaliacoes;