import styled from 'styled-components'

export const TotalChart = styled.div`
  margin-top: 1px;

  display: flex-box;
 
  
  width: 50%;
  background-color: var(--corEscura);
  border-radius: 10px;
  color: var(--corBranca);
  margin-bottom: 15px;

  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  span{
   
      font-weight: bold;
      margin: 10px 0 0 7px;
      font-size: 15px;
      height: 20px;
    }

    @media(min-width: 1400px){
      height: 23rem;
    }

    @media(max-width: 1366px){
      height: 13rem;
    }


    @media(orientation: portrait){
      width:100%;
      
      position: relative
      display: flex;
      gap: 25px;
      flex-direction:column;
     }



`

export const TotalContainer = styled.div`
  display: grid;
  
 


  @media(orientation: portrait){
    width:100%;
    
    position: relative
    display: flex;
    gap: 25px;
    flex-direction:column;
   }
` 



export const ChartContainer = styled.div`
  height: 150px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  width: 33.5vw;
  display: flex-box;
  background-color: var(--corEscura);
  margin-right: 1rem;
  margin-top: 10%;
  border-radius: 10px;


  &:nth-child(3){

    margin-right: 0;
  }

  h2, h4 {
    color: var(--corBranca);
  }

  span {
    font-size: 13.5px;
    height: 30px;
    width: 160px;
    margin: auto 0 0 7px;
    color: #828282;
  }

 @media(orientation: portrait){
  width:100%;
 }
`
 export const CharLayout = styled.div` 
    margin: -70px 15px 0 auto;
    
    
  `

export const DataBox = styled.div`
  margin: 55px 0 0 6px;
  height: 60px;

  border-radius: 5px;
  width: 25vw;
  
  color: var(--corBranca);

  h4 {
    font-weight: bold;
  }

  p{
    font-size: 12px;
  }

  img {
    margin-left:0;
    margin-bottom: 2px;
  }
  span {
    color: var(--corBranca);
    margin-left: 3px;
  }
` 