import styled from 'styled-components'

export const TotalChart = styled.div`
  margin-top: 10px;

  display: flex-box;
 
  
  width: 55%;
  height: 100%;
  background-color: var(--corEscura);
  border-radius: 10px;
  color: var(--corEscura);
  margin-bottom: 50px;

  @media(min-width: 1400px){
    height: 24vw;
  }

  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  span{
   
      font-weight: bold;
      margin: 10px 0 0 7px;
      font-size: 15px;
      height: 20px;
    }


    @media(orientation: portrait){
      width:100%;
      
      position: relative
      display: flex;
      gap: 25px;
      flex-direction:column;
     }



`

export const TotalContainer = styled.div`
  display: flex;
 


  @media(orientation: portrait){
    width:100%;
    
    position: relative
    display: flex;
    gap: 25px;
    flex-direction:column;
   }
` 



