import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

import TabelaNoticacoes from '../../components/TabelaListagemNotificacao'
import { Button, ButtonArea } from '../AvaliacoesArtistas/CriarAvaliacao/styles'
import { getNotifications, updateNotification } from '../../services/notification'
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from "../../AppContext/Context";
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
 
const ListagemNotificacoes = () => {
  const { user: { is_type } } = useAuth();
  const navigate = useNavigate ();

  const [loading, setLoading] = useState(false);
  const [itensPerPage] = useState(10);
  const [currentPage, setCurrentPage ] = useState(1);
  const [totalPages, setTotalPages ] = useState(1);

  const [notifications, setNotifications] = useState([]);
  const [currentUpdate, setCurrentUpdate] = useState(null);

  useEffect(() => {
    loadNotifications();
  }, [currentPage])

  async function loadNotifications() {
    setLoading(true);
    const response = await getNotifications(currentPage);
    setLoading(false);
    if(response) {
      const totalPages = Math.ceil(response.count / itensPerPage);
      setTotalPages(totalPages);
      setNotifications(response.results);
    }
  }
  
  function handleNavigation(event){
    event.preventDefault()
    navigate('/notificacoes/criar-notificacao')
  }

  function handlePageClick(ev){
    setCurrentPage(ev.selected + 1)
  }

  const handleChangeVisible = async (item, value, index) => {
    setCurrentUpdate(item);
    try {
      const _newList = [...notifications];
      const _indexItem = _newList.findIndex(notification => notification.id === item.id);
      
      const payload = {
        visible: value === "NÃO" ? 0 : 1 
      }
  
      const response = await updateNotification(payload, item.id);
      if(response) {
        addToast(SUCCESS, `Notificação: ${item.title} atualizada`);
        _newList[_indexItem] = {..._newList[_indexItem], visible: payload.visible};
        setNotifications(_newList);
      } else {
        addToast(ERROR, "Não foi possível atualizar notificação: "+item.title);
  
      }
  
      setCurrentUpdate(null);
    } catch (error) {
      setCurrentUpdate(null);
    }
    
  }

  return (
    <>
      <h4><b>Listagem de Notificações</b></h4>
      <ButtonArea>
        {checkPermission(is_type, MODELS.Notificacao, FUNCTIONS.Inserir) ? 
        <Button onClick={handleNavigation}>Criar notificação</Button> : null }
      </ButtonArea>
      
     { loading? <LoadingSpinner/> : <TabelaNoticacoes 
      currentUpdate={currentUpdate}
      handleChangeVisible={handleChangeVisible}
      notifications={notifications}/>}
      <ReactPaginate
      previousLabel={'Anterior'}
      nextLabel={'Próximo'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      activeLinkClassName={'active'}
      pageLinkClassName={'buttonPage page'}
      previousLinkClassName={'buttonPage page'}
      nextLinkClassName={'buttonPage page'} />
    </>
  )
}

export default ListagemNotificacoes;