import axios from "axios";

import { CLIENT_APPROVED, CLIENT_REJECT, CLIENT_SOLICITATION, GET_URL_CLIENTE, POST_URL_INVITE } from './config'

export const GetAllClient = async () => {
  try{
    const resp = await axios.get(GET_URL_CLIENTE);
    return resp.data;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetListaCliente = async(currentPage) =>{
  try{
    const resp = await axios.get(`${GET_URL_CLIENTE}`,{
      params: {
        page:currentPage,
        status: "EM ANALISE"
      }
    })
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetClienteAproved = async(currentPage) =>{
  try{
    const resp = await axios.get(`${GET_URL_CLIENTE}`,{
      params: {
        page:currentPage,
        status: "APROVADO"
      }
    })
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetClientJobs = async (userId) => {
  try {
    const resp = await axios.get(`${CLIENT_SOLICITATION}`, {
      params: {
        client: userId
      }
    });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export const CreateClient = async(data) =>{
  try {
    const resp = await axios.post(`${GET_URL_CLIENTE}`, data)
    return resp;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export const PatchClient = async(data) =>{
  try {
    const resp = await axios.patch(`${GET_URL_CLIENTE}${data.id}/`, data)
    return resp;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export const PostInviteClient = async(data) =>{
  try{
    const resp = await axios.post(`${POST_URL_INVITE}`, data)
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const ApprovedClient = async (payload) => {
  try {
    const resp = await axios.post(`${CLIENT_APPROVED}`, payload);
    return resp;
  } catch (error) {
    console.log(error);
  }
}

export const RejectClient = async (payload) => {
  try {
    const resp = await axios.post(`${CLIENT_REJECT}`, payload);
    return resp;
  } catch (error) {
    console.log(error);
  }
}

export const DeleteClient = async (clientId) => {
  try {
    const resp = await axios.delete(`${GET_URL_CLIENTE}${clientId}/`);
    return resp;
  } catch (error) {
    console.log(error);
  }
}

export const GetSearchClient = async(params) =>{
  try{
    const resp = await axios.get(`${GET_URL_CLIENTE}`,{
      params: {...params}
    })
    return resp.data;
  } catch(error){
    console.log("Erro: ", error);
  }
}