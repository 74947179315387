import React, { useState, } from 'react';
import {
  LoginStyled, Form, Input, Logo, ButtonGetIn,
  FirstColumn, FormText, LabelCheckBox,
  ForgotPasswordLink, SecondColumn, FooterArea, TotalArea, DivInput, InputOfForm, Span, I
} from './styles';
import Images from '../../assets';
import { authentication } from '../../services/auth';
import { useAuth } from '../../AppContext/Context';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import { BASE_API_URL } from '../../services/config';

const Login = () => {
  const { handleUser, load, cores } = useAuth();

  const [type, setType] = useState("password");
  const [eye, setEye] = useState("bi bi-eye-fill");

  const [loading, setLoading] = useState(false);
  const [keep, setKeep] = useState(false);
  const [payload, setPayload] = useState({
    username: '',
    password: '',
  })

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  }

  const handleLogged = () => {
    setKeep(!keep);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isLogged = await authentication(payload);
    setLoading(false);
    if (isLogged) {
      addToast(SUCCESS, 'Login Efetuado');
      handleUser(isLogged, keep);
    } else {
      addToast(ERROR, 'Email ou Senha inválidos');
    }
  }

  return (
    load ?
      <TotalArea>
        <LoginStyled>
          <FirstColumn>
            <Form>
              <Logo src={Images.logo2} alt="logo" cores={cores}>
              </Logo> <br />
              <FormText>
                Hoje vai ser  um dia Good!
              </FormText>

              <DivInput>

                <InputOfForm
                name="username"
                type="text"
                required="required"
               
                onChange={(e) => handleChange('username', e.target.value)}/>

                <Span>Email</Span>
                <I></I>
              </DivInput>
              
              <DivInput>
             
                <InputOfForm
                  name="password"
                  type={type}
                  required="required"
                  
                  onChange={(e) => handleChange('password', e.target.value)} />

                   <i className={eye} onClick={() => type === "password" ? (setType("text") , setEye("bi bi-eye-slash-fill")) : (setType("password") , setEye("bi bi-eye-fill"))}></i>

                <Span>Senha</Span>
                <I></I>
              </DivInput>
              <ForgotPasswordLink href={`${BASE_API_URL}accounts/password_reset/`} rel="noreferrer" target="_blank">
              Esqueci minha senha
              </ForgotPasswordLink>
              <div className="form-check">
                <Input
                  id="checkLogged"
                  type="checkbox"
                  className="form-check-input" onClick={handleLogged} defaultChecked={keep} />
                <LabelCheckBox className="form-check-label" For="checkLogged">Manter-se conectado</LabelCheckBox>
              </div>
              <ButtonGetIn disabled={loading} onClick={handleSubmit} type="submit">
                ENTRAR
              </ButtonGetIn>
            </Form>

          </FirstColumn>
          <SecondColumn></SecondColumn>
        </LoginStyled>

        <FooterArea>

          <footer className="text-center text-white" style={{ background: "var(--corPrincipal2)" }}>

            <div className="text-center p-3 CopyrightText" style={{ background: "var(--corEscura)", color: "var(--corBranca)" }}>
              Copyright &copy; 2023 GoodCasting - Todos os direitos reservados

            </div>

          </footer>

        </FooterArea>

      </TotalArea>
      :
      <>
      </>

  )
}

export default Login; 