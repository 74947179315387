/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { SidebarData, homeNav } from './data'
import { useNavigate } from 'react-router-dom'
import {
  MenuLateralArea,
  List1Styled,
  SidebarTotal,
  List2Styled,
  IconStyle,
  Link,
  TitleSub,
  ButtonMenu,
} from './styles'
import { useAuth } from '../../AppContext/Context'
import '../../App.css'
import Images from '../../assets/index'

function MenuSidebar() {
  const { user, ativo, setAtivo, handleLogout } = useAuth()
  const navigate = useNavigate()

  const [menu, setMenu] = useState([])

  useEffect(() => {
    const { is_type } = user

    if (is_type && !is_type.is_superuser) {
      const _arry = orderArry(is_type.list_permissions)

      const _nav = _arry.reduce((acc, item) => {
        const _hasPermission = SidebarData.find((per) => {
          return per.model === item.model
        })
        if (_hasPermission) {
          if (_hasPermission.subNav) {
            const _hasMenu = acc.findIndex((menu) => menu.model === item.model)
            if (_hasMenu > -1) {
              const subItensNav = _hasPermission.subNav.filter(
                (sub) => sub.function === item.function,
              )
              acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ...subItensNav]
            } else {
              const subItensNav = _hasPermission.subNav.filter(
                (sub) => sub.function === item.function,
              )
              acc.push({
                ..._hasPermission,
                subNav: subItensNav,
              })
            }
          } else {
            if (item.function === 'Visualizar') {
              acc.push(_hasPermission)
            }
          }
        } else {
          if (item.function === 'Visualizar') {
            if (item.model === 'Avaliação Artista') {
              const _menu = SidebarData.find((i) => i.model === 'mediacao')
              const _hasMenu = acc.findIndex(
                (menu) => menu.model === 'mediacao',
              )

              if (_hasMenu > -1) {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ..._subMenu]
              } else {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc.push({
                  ..._menu,
                  subNav: _subMenu,
                })
              }
            }

            if (item.model === 'Avaliação Cliente') {
              const _menu = SidebarData.find((i) => i.model === 'mediacao')
              const _hasMenu = acc.findIndex(
                (menu) => menu.model === 'mediacao',
              )

              if (_hasMenu > -1) {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ..._subMenu]
              } else {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc.push({
                  ..._menu,
                  subNav: _subMenu,
                })
              }
            }
            if (item.model === 'Permissão') {
              const _menu = SidebarData.find((i) => i.model === 'settings')
              const _hasMenu = acc.findIndex(
                (menu) => menu.model === 'settings',
              )

              if (_hasMenu > -1) {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ..._subMenu]
              } else {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc.push({
                  ..._menu,
                  subNav: _subMenu,
                })
              }
            }

            if (item.model === 'Pagamento Recorrente') {
              const _menu = SidebarData.find((i) => i.model === 'settings')
              const _hasMenu = acc.findIndex(
                (menu) => menu.model === 'settings',
              )

              if (_hasMenu > -1) {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ..._subMenu]
              } else {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc.push({
                  ..._menu,
                  subNav: _subMenu,
                })
              }
            }
            if (item.model === 'Profile') {
              const _menu = SidebarData.find((i) => i.model === 'settings')
              const _hasMenu = acc.findIndex(
                (menu) => menu.model === 'settings',
              )

              if (_hasMenu > -1) {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc[_hasMenu].subNav = [...acc[_hasMenu].subNav, ..._subMenu]
              } else {
                const _subMenu = _menu.subNav.filter(
                  (sub) => sub.model === item.model,
                )
                acc.push({
                  ..._menu,
                  subNav: _subMenu,
                })
              }
            }
          }
        }
        return acc
      }, [])

      _nav.unshift(homeNav)

      const data = _nav.map((val) => {
        if (val.hasOwnProperty('subNav')) {
          return { ...val, opened: false }
        }
        return val
      })
      setMenu(data)
    } else {
      setMenu(SidebarData)
    }
  }, [user])

  const orderArry = (list) => {
    const _orderPermissions = [...list]
    return _orderPermissions.sort((a, b) =>
      a.model < b.model ? -1 : a.model > b.model ? 1 : 0,
    )
  }

  const handlenavigate = (val) => {
    if (val.hasOwnProperty('subNav')) {
      const newdata = menu.map((menuoption) => {
        if (menuoption.title === val.title) {
          return { ...menuoption, opened: !menuoption.opened }
        }
        return { ...menuoption, opened: false }
      })
      return setMenu(newdata)
    }
    return navigate(val.path)
  }

  return (
    <SidebarTotal ativo={ativo}>
      <MenuLateralArea>
        {menu.map((val, key) => {
          return (
            <>
              <Link>
                <List1Styled
                  className="row"
                  key={key}
                  // eslint-disable-next-line no-sequences
                  onClick={() => (
                    handlenavigate(val),
                    val.title === 'Jobs' ||
                    val.title === 'Notificações' ||
                    val.title === 'Home'
                      ? setAtivo('')
                      : ''
                  )}
                >
                  <div>
                    <IconStyle
                      id="IconSidebar"
                      src={val.icon}
                      alt="teste"
                    ></IconStyle>
                    {val.title}
                  </div>
                </List1Styled>
              </Link>
              {val.subNav &&
                val.opened &&
                val.subNav.map((submenu, subkey) => {
                  return (
                    <List2Styled
                      className="row-2"
                      key={subkey}
                      // eslint-disable-next-line no-sequences
                      onClick={() => (handlenavigate(submenu), setAtivo(''))}
                    >
                      <TitleSub> {submenu.title} </TitleSub>
                    </List2Styled>
                  )
                })}
            </>
          )
        })}

        <Link to={'#'} onClick={handleLogout} className="logout">
          <List1Styled className="row">
            <div>
              <IconStyle
                id="IconSidebar"
                src={Images.icon_logout.default}
                alt="logout"
              ></IconStyle>
              Deslogar
            </div>
          </List1Styled>
        </Link>
      </MenuLateralArea>
    </SidebarTotal>
  )
}
export default MenuSidebar
