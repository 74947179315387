import styled from "styled-components";

export const RowInput = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 12px;
`
export const Label = styled.label`
  margin-top: ${(props) => props.noMargin ? '0' : '12px'};
`

export const InputTextClass = styled.input`
  width: ${props => props.small ? '80px' : '100%'};
  height: ${props => props.small ? '34px' : '44px'};
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
  border-radius: 8px;
`