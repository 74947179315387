import React from 'react'
import {
  Body,
  ButtonEdit,
  DivMain,
  DivTitle,
  Footer,
  ModalBackground,
  ModalContainer
} from './styleModal';

function DeleteModal({ 
  modal, setModal, handleDeleteFoto
}) {


  return (
    <DivMain>
      <ModalBackground>
        <ModalContainer>
          <DivTitle>
            <h3>Excluir Foto</h3>
          </DivTitle>
          <Body>
            <div>
              <label>Você tem certeza que quer excluir essa foto?</label><br />
              <label><b>Essa ação é irreversível!</b></label>
            </div>
          </Body>
          <Footer>
            <ButtonEdit onClick={() => setModal(false)}>CANCELAR</ButtonEdit>
            <ButtonEdit azul onClick={() => handleDeleteFoto(modal)}>EXCLUIR</ButtonEdit>
          </Footer>
        </ModalContainer>
      </ModalBackground>
    </DivMain>
  )
}

export default DeleteModal;