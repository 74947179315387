import React, { useState, useEffect } from 'react'

import { addToast, ERROR, SUCCESS } from '../../../components/Notifications'
import {
  GetPermissions,
  GetTypes,
  UpdatePermission,
} from '../../../services/permission'
import ModalType from '../Types'
import UserList from '../Users'
import { Container } from '../style'

import '../style.css'
import { checkPermission, FUNCTIONS, MODELS } from '../../../utils'
import { useAuth } from '../../../AppContext/Context'

const PermissionTable = () => {
  const {
    user: { is_type },
  } = useAuth()
  const [group, setGroup] = useState([])
  const [selected, setSelected] = useState(null)
  const [types, setTypes] = useState([])
  const [payload, setPayload] = useState({
    is_superuser: false,
    permission: [],
  })

  const [loading, setLoading] = useState(false)

  const [openTypes, setOpenTypes] = useState(false)

  useEffect(() => {
    loadTypes()
    loadPermissions()
  }, [])

  async function loadTypes() {
    const response = await GetTypes()
    if (response) {
      setTypes(response)
    }
  }

  async function loadPermissions() {
    const response = await GetPermissions()
    if (response) {
      const _group = []

      response.forEach((item, index) => {
        const _exist = _group.find(
          (group_item) => group_item.model === item.model,
        )
        if (_exist) {
          const _new_func = { id: item.id, function: item.function }
          _exist.functions.push(_new_func)
        } else {
          _group.push({
            model: item.model,
            functions: [{ id: item.id, function: item.function }],
          })
        }
      })
      setGroup(_group)
    }
  }

  function handleChangeType(value) {
    const { permission, is_superuser } = types.find((item) => item.id === value)
    setSelected(value)
    setPayload({ permission, is_superuser })
  }

  function checkBoxPermission(func) {
    return payload.permission.find((item) => item === func.id)
  }

  function handleChange(name, func) {
    if (name === 'permission') {
      const _permissions = [...payload.permission]
      const _exist = _permissions.findIndex((item) => item === func.id)
      if (_exist > -1) {
        const _new = [..._permissions]
        _new.splice(_exist, 1)
        setPayload({ ...payload, permission: _new })
      } else {
        const _new = [..._permissions]
        _new.push(func.id)
        setPayload({ ...payload, permission: _new })
      }
    } else {
      setPayload({ ...payload, [name]: func })
    }
  }

  async function handleSubmitPermission() {
    setLoading(true)
    const response = await UpdatePermission(selected, payload)
    setLoading(false)
    if (response) {
      addToast(SUCCESS, 'Permissão atualizada com sucesso')
    } else {
      addToast(ERROR, 'Não foi possível realizar essa operação')
    }
  }

  function toggleType(reload = false) {
    setOpenTypes(!openTypes)
    if (reload) {
      loadTypes()
    }
  }

  return (
    <>
      <h4>Lista de Usuários</h4>
      <Container>
        {checkPermission(is_type, MODELS.Admin, FUNCTIONS.Visualizar) ? (
          <UserList types={types} />
        ) : null}
      </Container>

      {openTypes ? <ModalType toggleModal={toggleType} /> : null}
    </>
  )
}

export default PermissionTable
