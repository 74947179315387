import styled from 'styled-components'; 

export const Link = styled.a`
  text-decoration: none;
  color: var(--corEscura);
`
export const SidebarTotal = styled.div`
  background-color: var(--corEscura);
  display: flex;
  position:relative;
  max-width: 250px;
  min-width: 250px;
  border-radius: 50px ;
  padding-top: 0px;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);



  
  @media(orientation: portrait){
    

    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    transform:translateY(-68px)   ;
    -webkit-transform:translateY(-68px)   ;
    -moz-transform:translateY(-68px)   ;
    -ms-transform:translateY(-68px)   ;
    -o-transform:translateY(-68px)   ;
    padding-top: 0px;
    background: var(--corEscura);
    border-radius: 0px 5px 5px 0px;
    height: 100%; 
    position: fixed;
    z-index: 60;
    transition: all 0.3s ease-in-out;
    left: ${props => props.ativo === "ativo" ? "0"
  : '-250px'};
    




  };
`
export const IconStyle = styled.img`
  margin-left: 15px;
  margin-right: 15px;
`

export const MenuLateralArea = styled.ul`
  max-width: 250px;
  height: 100%;
  background: var(--corEscura);
  color: var(--corEscura);
  height: auto;
  padding: 0;
  z-index: 60; 
  width: 100%;
  cursor: pointer;
  
  @media(orientation: landscape){
    position: fixed;
  }

  @media(orientation: portrait){
    
    border-radius: 5px 5px 5px 5px;
    position: fixed;
    background: var(--corEscura);
    z-index: 60;
    transition: all 0.3s ease-in-out;


  }


  `

  export const List1Styled = styled.li `
  margin: 0;
  width: 100%;
  color: var(--corBranca);
  height: 52px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  ${Link}:hover & {
    background: rgba(24, 239, 230, 0.1);
    color: var(--azulEsverdeado);
    border-left: 4px solid var(--azulEsverdeado);
  } 
`
export const List2Styled = styled.li `
  font-size: 15px !important;
  height: 30px;
  margin: 0;
  list-style-type: none;
  display: flex;
  color: var(--corBranca);
  padding-top: 5px; 

  &:hover {
    background: rgba(24, 239, 230, 0.1);
    color: var(--azulEsverdeado);
    border-left: 4px solid var(--azulEsverdeado);
  }

  
 
`
export const TitleSub = styled.div `
  margin-left: 4.5rem;
  height: 100%; 
  font-size: 12px;
`
export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: var(--corEscura);
  font-size: 30px;
  margin-top: -2px;
  position: absolute;
  display: none;
`




export const ButtonMenu = styled.button.attrs({
  className: "btn btn-secondary Menu",
  
})
`
display:none;
position: absolute;
transform: translateX(250px);
-webkit-transform: translateX(250px);
-moz-transform: translateX(250px);
-ms-transform: translateX(250px);
-o-transform: translateX(250px);




@media(orientation: portrait){

 display: block;
position: absolute;
transform: translateX(250px);
-webkit-transform: translateX(250px);
-moz-transform: translateX(250px);
-ms-transform: translateX(250px);
-o-transform: translateX(250px);
}

` 






