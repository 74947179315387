export const formatDate = (time, onlyDate) => {
  const _date = new Date(time);
  const timeOptions = {hour: '2-digit', minute:'2-digit'}
  if(onlyDate) {
    return `${_date.toLocaleDateString('pt-br', {timeZone: 'UTC'})}`;
  } else {
    return `${_date.toLocaleDateString('pt-br')} às ${_date.toLocaleTimeString('pt-br', {timeZone: 'UTC'}, timeOptions)}`;
  }
  
}

export const formatHour = (time) => {
  return time.slice(0, -3);
}

export function checkPermission(is_type, model, func) {
try {
  if(is_type.is_superuser) {
    return true;
  } else {
    return is_type.list_permissions.find(item => (item.model === model && item.function === func));
  }
} catch (error) {
  
}
}

export const MODELS = {
  Admin: "Admin",
  Artista: "Artista",
  Aula: "Aula",
  AvaliacaoArtista: "Avaliação Artista",
  AvaliacaoCliente: "Avaliação Cliente",
  Cliente: "Cliente",
  Financeiro: "Financeiro",
  Job: "Job",
  Notificacao: "Notificação",
  Permissao: "Permissão",
  Profile: "Profile",
  Pagamento: "Pagamento Recorrente"
}

export const FUNCTIONS = {
  Inserir: "Inserir",
  Visualizar: "Visualizar",
  Atualizar: "Atualizar",
  Excluir: "Excluir"
}
