import styled from 'styled-components';
import CurrencyInput from 'react-currency-input';

export const FormCustom = styled.form`
  background: #ffffff;
  box-shadow: 0px 1px 4px #15223214;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 20px;
  width: auto;
  height: 95%;

  label{
    font-size: 18px;
  }

`
export const SelectRecurrency = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const Hr = styled.hr`
  color: #828282 !important;
  height: 1px !important;
  margin-bottom: 10px;
`

export const InputName = styled.input`
  width: 280px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #828282;
  margin-right: 30px;
  padding: 7px;
`

export const InpuCustom = styled.input`
  width: 180px;
  height: 40px;
  border: 1px solid #828282;
  border-radius: 10px;
  padding: 7px; 
`

export const CustomCurrencyInput = styled(CurrencyInput)`
  width: 180px;
  height: 40px;
  border: 1px solid #828282;
  border-radius: 10px;
  padding: 7px; 
`;

export const SelectGender = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const SelectDisabled = styled.select`
  width: auto !important; 
  height: 44px;
  border: 1px solid;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const ElemtDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  margin-bottom: 6px;

  div{
    width: auto;
  }

  select{
    height: 40px;
    width: 60px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .input{
    width: 180px;
    height: 50px;
    border-radius: 10px;
    padding: 7px;
  }
`

export const DivCustom = styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const DivLeft = styled.div`
width:100%;
  display: flex;
  flex-direction: column;
`

export const DivCentral = styled.div`
width:100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const DivRight = styled.div`
width:100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

`

export const ElemtDivCabelo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width:100%;

  div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  
`

export const ButtonCabelo = styled.button`

    padding: 2px 5px;
    background: ${props => props.selected ? "linear-gradient(to right, var(--azulEsverdeado) 0%, var(--corPrincipal2) 100%)" : "transparent"};
    margin-right: 7px;
    color:  ${props => props.selected ? "var(--corEscura)" : "var(--corBranca)"};
    margin-bottom: 5px;
    box-shadow: ${props => props.selected ? "0px 0px 0.12em 0.14em rgba(255,255,255,0.75)" : ""};
    border-radius: 30em;
    font-size: 15px;
    font-family: inherit;
    border: ${props => props.selected ? "1px solid var(--corBranca)" : "1px solid var(--corPrincipal2)"};
    position: relative;
    overflow: hidden;
    z-index: 1;
    
   
   
   &::before {
    content: '';
    width: 0;
    height: 100%;
    border-radius: 30em;
    position: absolute;
    top: 0;
    left: 0;
    background-image: ${props => props.selected ? "transparent" : "linear-gradient(to right, var(--azulEsverdeado) 0%, var(--corPrincipal2) 100%)"};
    transition: .5s ease;
    display: block;
    z-index: -1;
   }
   
   &:hover::before {
    width: ${props => props.selected ? "0%" : "100%"};
   }


   &:hover{
    color: ${props => props.selected ? "var(--corEscura)" : "var(--corEscura)"};
    transition:all .5s ease-in-out;
   }

`

export const ElemtDivOlhos = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  width: 100%;
  margin-bottom: 0px;
`
export const ElemtDivIdade = styled.div`
  display: contents;
  float: right;
  width:100%;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivAltura = styled.div`
  // margin-top: 36px;
  width:100%;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivCintura = styled.div`
  margin-top: 36px;
  width:100%;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivQuadril = styled.div`
width:100%;
    margin-top: 10px;

    p{
      font-size: 12px;
      color: #828282;
      margin-bottom: 0;
    }
`

export const ElementDivBusto = styled.div`
  margin-top: 35px;
  width:100%;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const DivFotosOld = styled.div`
  display: flex;
  
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;

  div {
    display: grid;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  span {
    background-color: #e6e2e2;
    text-align: center;
    border-radius: 6px;
  }

  img{
    border-radius: 6px;
    width: 150px;
    height: 250px;
    
    object-fit: contain;
  }
`



export const DivFotos = styled.div`
  display: flex;
  width:100%;
  align-items:center;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;

`

export const ButtonAddFoto = styled.button`
  background: #cecece;
  border-radius: 6px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 250px;
  margin-right: 15px;

  img{
    width: 30px;
    height: auto;
    margin-bottom: 30px;
  }
`

export const OldButtonSalvar = styled.button`
  background: var(--corPrincipal2);
  border: none;
  border-radius: 10px;
  display: flex;
  margin-top: 15px;
  margin-left: auto;
  padding: 12px;
`




export const ButtonSalvar = styled.button`


  appearance: button;
  
  background: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: var(--corEscura);
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin-right: 20px;

  margin-bottom: 15px;
  
  margin-left: auto;
 
  outline: none;
  overflow: visible;
  padding: 13px 19px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
 

  @media(orientation: portrait){
    margin-left: 0;
    margin-top: 15px;
  }


 
 &:main, &:focus {
  user-select: auto;
 }
 
 &:hover:not(:disabled) {
  filter: brightness(1.1);
 }
 
 &:disabled {
  cursor: auto;
 }
 
 &:active:after {
  border-width: 0 0 0px;
 }

`

export const CardCustom = styled.figcaption`
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 6px;
  float: left;
  margin-right: 10px;
  width: 135px;
  margin-bottom: 10px;
  position: relative;

  button{
    border: none;
    border-radius: 20px;
    background-color: red;
    color: var(--corBranca)
    font-weight: 600;
    width: 25px;
    height: 25px;
    right: 0;
    position: absolute;
  }

  img{
    border-radius: 6px;
    width: 133px;
    height: 200px;
  }

  input{
    width: 20px;
    height: 20px;
  }

  label{
    font-size: 12px;
    float: right;
    margin-left: 5px;
  }
`