import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

import { ButtonEdit } from '../../components/Table/styles'
import { TableCustom } from './styles';
import { deleteJob, getJobs, approveJob } from '../../services/job';
import { formatDate, formatHour } from '../../utils';
import { ModalBody, ModalButton, ModalContainer } from './styles';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import LoadingSpinner from '../../components/LoadingSpinner';
import './style.css';
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from "../../AppContext/Context";

function TableJobs() {
  const { user: { is_type } } = useAuth();
  const navigate = useNavigate()
  const localUser = JSON.parse(localStorage.getItem("@good"));

  const [itensPerPage, setItensPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [modalApprove, setModalApprove] = useState(false);

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    loadJobs();
  }, [currentPage])

  async function loadJobs() {
    setLoading(true);
    const resp = await getJobs(currentPage);
    setLoading(false);
    if (resp) {
      console.log(resp);
      const totalPages = Math.ceil(resp.count / itensPerPage);
      setTotalPages(totalPages);
      setJobs(resp.results);
      setCount(resp.count)
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1)
  }

  async function handleDeleteJob() {
    setModalLoading(true);
    const response = await deleteJob(modal.id);
    setModalLoading(false);
    console.log(response);
    if(response !== undefined) {
      addToast(SUCCESS, 'Job excluído com sucesso');
      if(currentPage > 1) {
        setCurrentPage(1);
      } else {
        loadJobs();
      }
      setModal(false);
    } else {
      addToast(ERROR, 'Não foi possível excluir esse Job');
    }
  }

  async function handleApproveJob() {
    setModalLoading(true);
    const response = await approveJob(modalApprove.id);
    setModalLoading(false);
    if(response) {
      addToast(SUCCESS, 'A aprovação do seu casting está a ser processada. Por favor aguarde alguns instantes para ter o status atualizado.');
      if(currentPage > 1) {
        setCurrentPage(1);
      } else {
        loadJobs();
      }
      setModalApprove(false);
    } else {
      addToast(ERROR, 'Não foi possível aprovar esse Job');
    }
  }

  function handleEdit(job) {
    navigate(
      '/jobs/editar',
      {
        state: job
      }
    );
  }

  return (
    <>
      <div>
        <h4>Jobs Cadastrados</h4>
        <TableCustom large>
          <thead>
           
            <th data-label="Cliente">Cliente</th>
            <th data-label="Descrição&nbsp;&nbsp;">Descrição</th>
            <th data-label="Localidade">Localidade</th>
            <th data-label="Data/Horário">Data/Horário</th>
            <th data-label="Status">Status</th>
            <th data-label="&nbsp;">total: {count}</th>
          
          </thead>
          <tbody>
            {jobs.map((item) => (
              <tr>
                <td data-label="Cliente">{item.client.name}</td>
                <td data-label="Descrição&nbsp;&nbsp;">{item.description}</td>
                <td data-label="Localidade">{item.full_address}</td>
                <td data-label="Data/Horário">{`${formatDate(item.date, true)} - ${formatHour(item.time)}`}</td>
                <td data-label="&nbsp;">{item.status}</td>
                <td data-label="Status">
                  {(item.status === "PENDENTE" && checkPermission(is_type, MODELS.Job, FUNCTIONS.Atualizar)) ? 
                  <ButtonEdit azul font12 onClick={() => setModalApprove(item)}>APROVAR</ButtonEdit> : null }
                  {checkPermission(is_type, MODELS.Job, FUNCTIONS.Atualizar) ? 
                  <ButtonEdit azul font12 onClick={() => handleEdit(item)}>EDITAR</ButtonEdit> : null }
                  {checkPermission(is_type, MODELS.Job, FUNCTIONS.Excluir) ? 
                  <ButtonEdit font12 onClick={() => setModal(item)}>EXCLUIR</ButtonEdit> : null }
                </td>
              </tr>
            ))}
          </tbody>
        </TableCustom>
        {loading ? <LoadingSpinner /> : null}
        {modal ? <ModalContainer>
          <ModalBody>
            <div>
              <h4>Tem certeza que deseja excluir o job?</h4>
            </div>
            {modalLoading ? <LoadingSpinner noMargin={true} /> : <>
              <ModalButton blue onClick={handleDeleteJob}>EXCLUIR</ModalButton>
              <ModalButton onClick={() => setModal(false)}>CANCELAR</ModalButton>
            </> }
          </ModalBody>
        </ModalContainer> : null }

        {modalApprove ? <ModalContainer>
          <ModalBody>
            <div>
              <h4>Tem certeza que deseja aprovar job?</h4>
            </div>
            {modalLoading ? <LoadingSpinner noMargin={true} /> : <>
              <ModalButton blue onClick={handleApproveJob}>APROVAR</ModalButton>
              <ModalButton onClick={() => setModalApprove(false)}>CANCELAR</ModalButton>
            </> }
          </ModalBody>
        </ModalContainer> : null }

        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Próximo'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeLinkClassName={'active'}
          pageLinkClassName={'buttonPage page'}
          previousLinkClassName={'buttonPage page'}
          nextLinkClassName={'buttonPage page'} />
      </div>
    </>
  )
}

export default TableJobs;