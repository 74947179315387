import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Container,
  Content,
  Table,
  SearchContainer,
  ModalBody,
  ModalContainer,
  ModalButton,
  ButtonSearch,
  NewButton,
  DivInput,
  InputOfForm,
  Span,
  I,
  ButtonEdit,
} from "./styles";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Notification, {
  ERROR,
  SUCCESS,
} from "../../../components/Notifications";

import Button from "../../../components/Button";
import {
  DeleteClient,
  GetClienteAproved,
  GetSearchClient,
} from "../../../services/client";
import ReactPaginate from "react-paginate";
import { checkPermission, FUNCTIONS, MODELS } from "../../../utils";
import { useAuth } from "../../../AppContext/Context";

function Profiles() {
  const {
    user: { is_type },
  } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [load, setLoad] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  const [modal, setModal] = useState(null);

  const [searchList, setSearchList] = useState([]);
  const [isSearch, setIsSearch] = useState(0);
  const [searchParams, setSearchParam] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    listaCliente();
  }, [currentPage]);

  const handleGoToEdit = (id, profile) =>
    navigate(
      `/clientes/editar/${profile.id}`,
      {
        state: {
          profile: profile
        }
      }
    );

  async function listaCliente() {
    setLoad(true);
    const resp = await GetClienteAproved(currentPage);
    if (resp) {
      setData(resp.data.results);
      setLoad(false);
      console.log(resp.data.count);
      const totalPages = Math.ceil(resp.data.count / itensPerPage);
      setPages(totalPages);
    } else {
      setLoad(false);
      Notification("Nenhum dado retornado", "", "error");
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1);
  }

  async function handleDelete() {
    setLoadingModal(true);
    const resp = await DeleteClient(modal);
    setLoadingModal(false);
    if (resp) {
      listaCliente();
      setModal(null);
      Notification("Usuário apagado", "", SUCCESS);
    } else {
      Notification("Não foi possível apagar usuário", "", ERROR);
    }
  }

  async function submitSearch() {
    if (
      searchParams.name.length > 3 ||
      searchParams.email.length > 3 ||
      searchParams.phone.length > 3
    ) {
      setIsSearch(1);
      const resp = await GetSearchClient(searchParams);
      setIsSearch(2);
      if (resp) {
        setSearchList(resp);
      }
    } else {
      Notification("Informe no mínimo 4 caracteres", "", "error");
    }
  }

  function handleKey(e) {
    if (e.key === "Enter" && isSearch !== 1) {
      submitSearch();
    }
  }

  function handleChangeSearch(field, value) {
    setSearchParam({ ...searchParams, [field]: value });
  }

  function clearFilter() {
    setIsSearch(0);
    setSearchList([]);
    setSearchParam({ name: "", email: "", phone: "" });
  }

  return (
    <Container>
      <h4>Perfis de Clientes</h4>
      <Content>
        <SearchContainer>
          <DivInput>
            <InputOfForm
              type="text"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.name}
              onChange={({ target }) =>
                handleChangeSearch("name", target.value)
              }
            />

            <Span>Nome</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <InputOfForm
              type="text"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.email}
              onChange={({ target }) =>
                handleChangeSearch("email", target.value)
              }
            />

            <Span>Email</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <InputOfForm
              type="number"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.phone}
              onChange={({ target }) =>
                handleChangeSearch("phone", target.value)
              }
            />

            <Span>Telefone</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <ButtonSearch azul large onClick={() => submitSearch()}>
              Procurar
            </ButtonSearch>
            <ButtonSearch onClick={() => clearFilter()}>Limpar</ButtonSearch>
          </DivInput>
        </SearchContainer>
        <Table>
          <thead>
            <tr>
              <th data-label="Nome">Nome</th>
              <th data-label="Status">Status</th>
              <th data-label="E-mail">E-mail</th>
              <th data-label="Telefone">Telefone</th>
              <th data-label="Funções">Funções</th>
            </tr>
          </thead>
          <tbody>
            {load === true ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : isSearch != 0 ? (
              isSearch === 1 ? (
                <div className="text-center m-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                searchList.map((customer, index) => (
                  <tr key={index}>
                    <td data-label="Nome">{customer.name}</td>
                    <td data-label="Status">{customer.status}</td>
                    <td data-label="E-mail">{customer.email}</td>
                    <td data-label="Telefone">{customer.phone}</td>
                    <td data-label="Funções">
                      {checkPermission(
                        is_type,
                        MODELS.Cliente,
                        FUNCTIONS.Atualizar
                      ) ? (
                        <>
                          <ButtonEdit
                            azul
                            large
                            font12
                            onClick={() => handleGoToEdit(index, customer)}
                          >
                            Editar Perfil
                          </ButtonEdit>
                          <ButtonEdit
                            large
                            font12
                            onClick={() => setModal(customer.id)}
                          >
                            Apagar Perfil
                          </ButtonEdit>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))
              )
            ) : (
              data.map((customer, index) => (
                <tr key={index}>
                  <td data-label="Nome">{customer.name}</td>
                  <td data-label="Status">{customer.status}</td>
                  <td data-label="E-mail">{customer.email}</td>
                  <td data-label="Telefone">{customer.phone}</td>
                  <td data-label="Funções">
                    {checkPermission(
                      is_type,
                      MODELS.Cliente,
                      FUNCTIONS.Atualizar
                    ) ? (
                      <>
                        <ButtonEdit
                          azul
                          large
                          font12
                          onClick={() => handleGoToEdit(index, customer)}
                        >
                          Editar Perfil
                        </ButtonEdit>
                        <ButtonEdit
                          large
                          font12
                          onClick={() => setModal(customer.id)}
                        >
                          Apagar Perfil
                        </ButtonEdit>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Próximo"}
          breakLabel={"..."}
          breakClassName={"item break-me"}
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeLinkClassName={"active"}
          pageLinkClassName={"buttonPage page"}
          previousLinkClassName={"linkPage buttonPage page"}
          nextLinkClassName={"linkPage buttonPage page"}
        />
      </Content>
      {modal && (
        <ModalContainer>
          <ModalBody>
            <div>
              <h4>Tem certeza que deseja excluir o Usuário?</h4>
            </div>
            {loadingModal ? (
              <LoadingSpinner noMargin={true} />
            ) : (
              <>
                <ModalButton blue onClick={handleDelete}>
                  EXCLUIR
                </ModalButton>
                <ModalButton onClick={() => setModal(null)}>
                  CANCELAR
                </ModalButton>
              </>
            )}
          </ModalBody>
        </ModalContainer>
      )}
    </Container>
  );
}

export default Profiles;
