import styled from 'styled-components'; 
import CurrencyInput from 'react-currency-input';

export const FormCustom = styled.form`
  border-radius: 10px;
  color: var(--corBranca);
  background: var(--corEscura);
  box-shadow: 0px 1px 4px #15223214;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 50px;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 40px;

  padding: 0px 30px;
  width: ${props => props.large ? "100%" : "100%"};
  height: 100%;

`
export const SelectGender = styled.select`
  position: relative;
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  
  padding: 0 10px 0 10px;
`

export const SelectRecurrency = styled.select`
  width: 100% !important; 
  height: 44px;
  border-radius: 8px;
  
  padding: 0 10px 0 10px;
`;

export const FirstDiv = styled.div`
  position: relative;

  
  flex-wrap: wrap;
  
  height: 100%;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  & > div {
      width: 100%;
  }



  select{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    
  }

`

export const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  // align-items: flex-end;
  // align-content: flex-end;
  width: 30%;
  height: 100%;

  select{
    border-color: #828282;
    border-radius: 15px;
    color: #828282;
    margin-top: 20px;
    width: 250px;
    height: 45px;
    padding: 7px;
  }
`

export const DivIntern = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;

  div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }

`

export const InputSlide = styled.input`
  max-width: 55px;
  border-radius: 10px;
  color: var(--corBranca);
  border: 1px solid var(--corPrincipal2);
  padding-left: 3px;
  background: transparent;



`;

export const InputName = styled.input`
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;
  padding: 7px;
`

export const InpuCustom = styled.input`
  width: 140px;
  height: 50px;
  border-radius: 10px;
  padding: 7px;
  margin-right: 10px;
`

export const ElemtDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`
export const DivCentral = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
`


export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 100%;
  margin:30px 0px 0 0;
}

@media(orientation: portrait){
  
  width: 100%;
  margin:20px 1.5vw;


  & select{
    width:100%;
  }
}

& input,
& select {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}


& select{
  height: 100%;
  color:var(--corBranca);
}


& span {
  
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}



& input:valid ~span,
& input:focus ~span,
& select:valid ~span,
& select:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);

}


& input[type="date"]:valid ~span,
& input[type="date"]:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
}

& input[type="date"]:invalid ~span{
  color: #8f8f8f;
  transform: translateX(-10px) translateY(-34px);
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}



`



export const InputOfForm = styled.input`



`


export const CustomCurrencyInputOfForm = styled(CurrencyInput)`


`

export const TextareaOfForm = styled.textarea`
border: none;


`

export const SelectOfForm = styled.select`

& option{
  color: var(--corBranca);
}


`


export const Span = styled.span`



`




export const I = styled.i`


`