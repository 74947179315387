import TabelaAprovacaoArtista from './components/TabelaAprovacaoArtista/TabelaAprovacaoArtista'
import AprovacaoArtista from './components/AprovacaoArtista/AprovacaoArtista'
import DashBoardInicial from './pages/DashboardInicial'
import EditPerfil from './components/EditPerfil/EditPerfil'
import GestaoFinanceira from './pages/GestaoFinanceira'

import AvaliacoesArtistas from './pages/AvaliacoesArtistas'
import CriarAvaliacaoArtista from './pages/AvaliacoesArtistas/CriarAvaliacao'
import EditarAvaliacaoArtista from './pages/AvaliacoesArtistas/EditarAvaliacao'

import AvaliacoesProdutores from './pages/AvaliacoesProdutores'
import CriarAvaliacaoProdutor from './pages/AvaliacoesProdutores/CriarAvaliacao'
import EditarAvaliacaoProdutor from './pages/AvaliacoesProdutores/EditarAvaliacao'

import GestaoAulas from './pages/GestaoAulas'
import CriarAula from './pages/CriarAula'

import CadastroArtista from './pages/CadastroArtista/CadastroArtista'
import CustomerInvite from './pages/Customer/Invite'
import CustomerApproval from './pages/Customer/Approval'
import CustomerProfiles from './pages/Customer/Profiles'
import CustomerCreate from './pages/Customer/Create/CadastroCliente'
import CustomerEdit from './pages/Customer/Edit'
import CustomerProfileApproval from './pages/Customer/ProfileApproval'

import EnviarNotificacao from './pages/Notifications/EnviarNotificacoes'
import ListagemNotificacoes from './pages/Notifications'

import EditarAula from './pages/EditarAula'
import EditJob from './pages/Jobs/edit'
import Profile from './pages/Profile'
import Permission from './pages/Permission'

import Table from './components/Table/index'
import TableJobs from './pages/Jobs'
import RecurringPayment from './pages/RecurringPayment'
import PermissionTable from './pages/Permission/Table'
import Upload from './pages/Permission/Upload'

export const routes = [
  { path: '/', component: <DashBoardInicial /> },
  { path: '/home', component: <DashBoardInicial /> },

  { path: '/jobs', component: <TableJobs /> },
  { path: '/jobs/editar', component: <EditJob /> },

  { path: '/artista/profiles', component: <Table /> },
  { path: '/artista/cadastrar', component: <CadastroArtista /> },
  { path: '/artista/aprovacao', component: <TabelaAprovacaoArtista /> },
  { path: '/artista/visualizar-perfil', component: <AprovacaoArtista /> },
  { path: '/artista/editar-perfil', component: <EditPerfil /> },

  { path: '/financeiro/gestao', component: <GestaoFinanceira /> },

  { path: '/mediacao/avaliacoes/artista', component: <AvaliacoesArtistas /> },
  { path: '/criar-avaliacao/artista', component: <CriarAvaliacaoArtista /> },
  { path: '/editar-avaliacao/artista', component: <EditarAvaliacaoArtista /> },

  {
    path: '/mediacao/avaliacoes/produtores',
    component: <AvaliacoesProdutores />,
  },
  { path: '/criar-avaliacao/produtor', component: <CriarAvaliacaoProdutor /> },
  {
    path: '/editar-avaliacao/produtor',
    component: <EditarAvaliacaoProdutor />,
  },

  { path: '/aulas/gestao', component: <GestaoAulas /> },
  { path: '/aulas/criar-aula', component: <CriarAula /> },
  { path: '/aulas/editar-aula', component: <EditarAula /> },

  { path: '/clientes/invite', component: <CustomerInvite /> },
  { path: '/clientes/cadastrar', component: <CustomerCreate /> },
  { path: '/clientes/aprovacao', component: <CustomerApproval /> },
  {
    path: '/clientes/aprovacao/perfil/',
    component: <CustomerProfileApproval />,
  },
  { path: '/clientes/profiles', component: <CustomerProfiles /> },
  { path: '/clientes/editar/:id', component: <CustomerEdit /> },

  { path: '/notificacoes/criar-notificacao', component: <EnviarNotificacao /> },
  { path: '/notificacoes', component: <ListagemNotificacoes /> },

  { path: '/settings/perfis', component: <Profile /> },
  { path: '/settings/permissoes', component: <Permission /> },
  { path: '/settings/permissoes/table', component: <PermissionTable /> },
  { path: '/settings/upload', component: <Upload /> },
  { path: '/settings/pagamento-recorrente', component: <RecurringPayment /> },
]

export default routes
