import styled from "styled-components";

export const ContainerNotificacao = styled.div`
  width: 100%;

  background-color: var(--corEscura);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 50px 0 50px 0;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Form = styled.form`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Label = styled.label`
  margin-top: 8px;
`

export const Button = styled.button`
  background-color: ${(props) => props.loading ? "#707070" : "var(--corPrincipal2)"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px 0 0 auto;
`

export const ButtonSelect = styled.div`
  background-color: ${(props) => props.loading ? "#707070" : "var(--corPrincipal2)"};
  font-size: 12px;
  width: 60%;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
  background-color: ${(props) => props.loading ? "#707070" : "#5B73F4"};
  }
`

export const DescricaoAreaClass = styled.div`
  width: 100%;
  
 
`

export const InputDescricao = styled.textarea`
  margin-top: 2%;
  width: 100%;
  height: 50%;
 
`

export const FunctionNotify = styled.div`
 
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
`

export const SelectFunction = styled.select`
  
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const InputTextClass = styled.input`
  width: 350px;
  height: 44px;
 
  padding: 0 0 0 10px;
  border-radius: 8px;
`





export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 100%;
  margin:30px 0px 0 0;
}



& textarea {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 0px ;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: 2px solid var(--azulEsverdeado);
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  margin: 30px 0 30px 0;
  
}

@media(orientation: portrait){
  
  width: 100%;
  margin:20px 1.5vw;


  & select{
    width:100%;
  }
}

& input,
& select {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}


& select{
  height: 100%;
  color:var(--corBranca);
}


& span {
  
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}



& input:valid ~span,
& input:focus ~span,
& select:valid ~span,
& select:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);

}


& input[type="date"]:valid ~span,
& input[type="date"]:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
}

& input[type="date"]:invalid ~span{
  color: #8f8f8f;
  transform: translateX(-10px) translateY(-34px);
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}



`



export const InputOfForm = styled.input`



`



export const SelectOfForm = styled.select`
width:100%;



& option{
  color: var(--corBranca);
}


`


export const Span = styled.span`



`

export const TextareaOfForm = styled.textarea`



`




export const I = styled.i`


`
export const Grid = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);

`
export const SearchContainer = styled.div`
  margin-bottom: 12px;

  input {
    margin-left: 6px;
    margin-right: 6px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #828282;
    margin-right: 30px;
    padding: 7px;
  }
`
export const ButtonSearch = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.verm ? "#DC1076 0% 0% no-repeat padding-box" : "#9e9e9e 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  min-width: 120px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 2px;
  padding: 0 5px;
  margin: 6px 6px;
`;

export const TableCustom = styled.table`
 
   
height: 100%;
background: var(--corEscura);
margin: 30px 0 40px 0;
padding: 0;
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
width: 100%;
table-layout: fixed;



&:nth-child(2){
background: var(--corEscura);

width:100%;

}  


caption {
font-size: 1.5em;
margin: .5em 0 .75em;
}



th,
td {
position: relative;
padding: .625em;
width: 100%;

flex-wrap: wrap;
text-align: center;
}

th {
font-size: .85em;
color: var(--azulEsverdeado);
letter-spacing: .1em;
border-bottom: 1px solid var(--azulEsverdeado);
text-transform: uppercase;
}


td {
font-size: 100%;
color: var(--corBranca);
inline-size: 100%; 
overflow-wrap: break-word;


}

&:last-child {


}



@media screen and (max-width: 600px) {


background: transparent;
box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
border: 0;


&:nth-child(2){
  background: transparent;
  border: 0;
  width: 100%;
  
} 
  


caption {
  font-size: 1.3em;
}

thead {

  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}



  


tr {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  display: block;
  justify-content: center;
  margin-bottom: 2em;
  }

td {
  border-bottom: 1px solid var(--azulEsverdeado);
  display: block;
  font-size: .8em;
  text-align: right;
}

td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  
  */
  content: attr(data-label);
  float: left;
  color: var(--azulEsverdeado);
  font-weight: bold;
  text-transform: uppercase;
}

td:last-child {
  border-bottom: 0;
}
}
`