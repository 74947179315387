import styled from 'styled-components';

export const Container = styled.button`
  background-color: var(--corPrincipal2);
  color: var(--corEscura);
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  transition: .1s;
  padding: 0px 10px;
  font-size: 0.75em;
  font-weight: bold;
  

  &:hover{
      filter: brightness(0.95);
  }


  
`;
