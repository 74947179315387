import axios from 'axios';
import { PERMISSION_URL, TYPES_URL } from './config';

export const GetPermissions = async () => {
  try {
    const response = await axios.get(PERMISSION_URL);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const UpdatePermission = async (typeId, payload) => {

  try {
    const response = await axios.patch(`${TYPES_URL}${typeId}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const GetTypes = async () => {
  try {
    const response = await axios.get(TYPES_URL);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const CreateType = async (payload) => {
  try {
    const response = await axios.post(TYPES_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
