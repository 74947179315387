import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
 
  margin: 50px 0 50px 0;
  
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  div {
    margin-right: 3px;
  }

  @media(orientation: portrait){
    div {
      margin-right: 0px;
    }
  }
`;

export const Button = styled.button`
  background-color: ${props => props.green ? 'var(--corPrincipal2)' : '#D5D7E3'};
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 12px;
  font-size: 0.75em;
  color: var(-corEscura);
  height: 45px;
  width: 130px;
`;

export const SelectType = styled.select`
  width: 120px !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const Table = styled.table`

   
height: 100%;
background: var(--corEscura);
margin: 30px 0 40px 0;
padding: 0;
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
width: 100%;
table-layout: fixed;



&:nth-child(2){
background: var(--corEscura);

width:100%;

}  


caption {
font-size: 1.5em;
margin: .5em 0 .75em;
}



th,
td {
position: relative;
padding: .625em;
width: 100%;

flex-wrap: wrap;
text-align: center;
}

th {
font-size: .85em;
color: var(--azulEsverdeado);
letter-spacing: .1em;
border-bottom: 1px solid var(--azulEsverdeado);
text-transform: uppercase;
}


td {
font-size: 100%;
color: var(--corBranca);
inline-size: 100%; 
overflow-wrap: break-word;


}

&:last-child {


}



@media screen and (max-width: 600px) {


background: transparent;
box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
border: 0;


&:nth-child(2){
  background: transparent;
  border: 0;
  width: 100%;
  
} 
  


caption {
  font-size: 1.3em;
}

thead {

  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}



  


tr {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  display: block;
  justify-content: center;
  margin-bottom: 2em;
  }

td {
  border-bottom: 1px solid var(--azulEsverdeado);
  display: block;
  font-size: .8em;
  text-align: right;
}

td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  
  */
  content: attr(data-label);
  float: left;
  color: var(--azulEsverdeado);
  font-weight: bold;
  text-transform: uppercase;
}

td:last-child {
  border-bottom: 0;
}
}


`


export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 21%;
  margin:30px;
}

@media(orientation: portrait){
  
  width: 90%;
  margin:20px 1.5vw;


  & select{
    width:100%;
  }
}

& input,
& select {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}

&{
  display: flex;
  align-items: center;
  justify-content: center;
}


& select{
  height: 100%;
  color:var(--corBranca);
}


& span {
  
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}



& input:valid ~span,
& input:focus ~span,
& select:valid ~span,
& select:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);

}


& input[type="date"]:valid ~span,
& input[type="date"]:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
}

& input[type="date"]:invalid ~span{
  color: #8f8f8f;
  transform: translateX(-10px) translateY(-34px);
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}



`



export const InputOfForm = styled.input`



`





export const SelectOfForm = styled.select`

& option{
  color: var(--corBranca);
}


`


export const Span = styled.span`



`




export const I = styled.i`


`