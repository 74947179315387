import styled from 'styled-components'

export const ChartBar = styled.div`

`

export const AreaTotalChart = styled.div`
  background-color: var(--corEscura);
  width: 50%;
  color: var(--corBranca);
  padding: .5rem;
  display: flex;
  height: 100%;


  
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  margin: 10px 0px 50px 0px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);

  span{
    height: 25px;
   
    font-weight: bold;
  }


  @media(orientation: portrait){
    width:100%;
    margin: 0px 0 0 0px;
 
    position: relative
    display: flex;
    gap: 25px;
    flex-direction:column;
   }
  `

  export const AreaInformacao = styled.div`
    margin: 0;
    color: var(--corBranca);
    position: relative;
  

    
    @media(orientation: portrait){
      width:100%;
      margin: 0px 0 0 0px;
   
      position: relative
      display: flex;
      gap: 25px;
      flex-direction:column;
     }

    span {
      
      font-weight: bold;
      font-size: 25px;
    }
    p {
        font-weight: bold;
        margin-left: 3px;
    }
    img {
      margin-bottom: 1px;
    }
  `