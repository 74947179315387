import axios from 'axios'

import { ADMIN_URL } from './config'

export const GetAdmins = async () => {
  try {
    const response = await axios.get(ADMIN_URL)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const CreateAdmin = async (payload) => {
  try {
    const response = await axios.post(`${ADMIN_URL}`, payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const UpdateAdmin = async (adminId, payload) => {
  try {
    const response = await axios.patch(`${ADMIN_URL}${adminId}/`, payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
