import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import LoadingSpinner from "../LoadingSpinner/index.js";
import {
  TableCustom,
  ButtonEdit,
  ModalContainer,
  ModalButton,
  ModalBody,
} from "./styles.js";
import { GetListaArtista, RejectArtist } from "../../services/artist";
import { getDate } from "../../Helpers/index";
import { addToast, SUCCESS, ERROR } from "../Notifications";

function TabelaAprovacaoArtista() {
  let navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [dataArtist, setDataArtist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: "",
    description: "",
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    listaArtista();
    console.log(dataArtist);
  }, []);

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: "", description: "" });
  }

  function handleClick(profile) {
    navigate("/artista/visualizar-perfil", {
      state: {
        profile: profile,
      },
    });
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function listaArtista() {
    setLoad(true);
    const resp = await GetListaArtista();
    if (resp) {
      setDataArtist(resp.data);
      setLoad(false);
    } else {
      setLoad(false);
      addToast(ERROR, "Nenhum dado retornado");
    }
  }

  async function rejectModal() {
    setLoading(true);
    const resp = await RejectArtist({ ...payload, artist: modal.id });
    setLoading(false);
    if (resp && resp.status === 200) {
      addToast(SUCCESS, "Artista cancelado com sucesso!");
      handleTriggerModal(false);
      listaArtista();
    } else {
      addToast(ERROR, "Não foi possível realizar essa operação");
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, "Informe o Motivo da rejeição");
      return false;
    }
    return true;
  }

  return (
    <div>
      <h4>Aprovação de Talentos</h4>

      {modal && (
        <ModalContainer>
          <ModalBody>
            <div>
              <h4>Tem certeza que deseja rejeitar este Artista?</h4>
            </div>
            {loading ? (
              <LoadingSpinner noMargin={true} />
            ) : (
              <>
                <ModalButton blue onClick={rejectModal}>
                  EXCLUIR
                </ModalButton>
                <ModalButton onClick={() => handleTriggerModal(false)}>
                  CANCELAR
                </ModalButton>
              </>
            )}
          </ModalBody>
        </ModalContainer>
      )}

      <TableCustom>
        <thead>
          <tr>
            <th data-label="Nome">Nome</th>
            <th data-label="Início">Início</th>
            <th data-label="Status">Status</th>
            <th data-label="Email">Email</th>
            <th data-label="Telefone">Telefone</th>
            <th data-label="Funções"> Funções</th>
          </tr>
        </thead>

        <tbody>
          {load === true ? (
            <div className="text-center m-5">
              <div className="spinner-border text-info" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            dataArtist.map((profile, index) => (
              <tr key={index}>
                <td data-label="Nome">{profile.name}</td>
                <td data-label="Início">{getDate(profile.created_at)}</td>
                <td data-label="Status">{profile.status}</td>
                <td data-label="Email">{profile.email}</td>
                <td data-label="Telefone">{profile.phone_prefix} {profile.phone.replace('(','').replace(')','').replace('-','').replace(' ','')}</td>
                <td data-label="Funções">
                  <ButtonEdit
                    azul
                    large
                    font12
                    onClick={() => handleClick(profile)}
                  >
                    VISUALIZAR PERFIL
                  </ButtonEdit>
                  <ButtonEdit large font12 onClick={() => setModal(profile)}>
                    REJEITAR PERFIL
                  </ButtonEdit>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </TableCustom>
    </div>
  );
}

export default TabelaAprovacaoArtista;
{
  /* <Modal 
loading={loading} handleChangeReject={handleChangeReject}
closeModal={handleTriggerModal} rejectModal={rejectModal}/> */
}
