import React from "react";
import { DashboardTotal, ButtonMonth, CardsUp, CardsDown } from "./styles";
import LineChart from "../../components/ChartsMenores";
import ChartMensalidade from "../../components/ChartMensalidade";
import Chart1 from "../../components/Chart1";
import Chart2 from "../../components/Chart2";
import Chart3 from "../../components/Chart3";
import Chart4 from "../../components/Chart4";

const DashBoardInicial = () => {
  return (
    <>
      <h4>
        <b>Dashboard</b>
      </h4>
      <DashboardTotal>
        <CardsUp>
          <Chart1 />
          <Chart2 />
        </CardsUp>
        <CardsDown>
          {/* <ButtonMonth>Últimos 12 meses</ButtonMonth> */}
          <Chart3 />
          <Chart4 />
        </CardsDown>
      </DashboardTotal>
    </>
  );
};

export default DashBoardInicial;
