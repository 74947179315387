import Images from '../../assets/index'

export const homeNav = {
  title: 'Home',
  icon: Images.icon_home.default,
  path: '/home',
}

export const SidebarData = [
  {
    title: 'Talentos',
    model: 'Artista',
    icon: Images.icon_users.default,
    path: '/artistas',
    subNav: [
      {
        function: 'Inserir',
        title: 'Cadastrar Talentos',
        path: '/artista/cadastrar',
      },
      {
        function: 'Atualizar',
        title: 'Aprovação de Cadastros',
        path: '/artista/aprovacao',
      },
      {
        function: 'Visualizar',
        title: 'Ver Perfis',
        path: '/artista/profiles',
      },
    ],
  },

  {
    title: 'Clientes',
    icon: Images.icon_inbox.default,
    path: '/clientes',
    model: 'Cliente',
    subNav: [
      {
        function: 'Inserir',
        title: 'Cadastrar Clientes',
        path: '/clientes/cadastrar',
      },
      {
        function: 'Inserir',
        title: 'Convidar Clientes',
        path: '/clientes/invite',
      },
      {
        function: 'Atualizar',
        title: 'Aprovação de Cadastros',
        path: '/clientes/aprovacao',
      },
      {
        function: 'Visualizar',
        title: 'Ver Perfis',
        path: '/clientes/profiles',
      },
    ],
  },

  {
    title: 'Financeiro',
    icon: Images.icon_dashboard.default,
    path: '/financeiro',
    model: 'Financeiro',
    subNav: [
      {
        function: 'Visualizar',
        title: 'Gestão Financeira',
        path: '/financeiro/gestao',
      },
    ],
  },

  {
    title: 'Mediação',
    icon: Images.icon_message.default,
    path: '/mediacao',
    model: 'mediacao',
    subNav: [
      {
        function: 'Visualizar',
        model: 'Avaliação Artista',
        title: 'Avaliações Artistas',
        path: '/mediacao/avaliacoes/artista',
      },
      {
        function: 'Visualizar',
        model: 'Avaliação Cliente',
        title: 'Avaliações Produtores',
        path: '/mediacao/avaliacoes/produtores',
      },
    ],
  },

  {
    title: 'Aulas',
    icon: Images.ic_class,
    path: '/aulas',
    model: 'Aula',
    subNav: [
      {
        model: 'Aula',
        function: 'Visualizar',
        title: 'Gestão de aulas',
        path: '/aulas/gestao',
      },
    ],
  },

  {
    model: 'Job',
    title: 'Jobs',
    icon: Images.icon_calendar.default,
    path: '/jobs',
  },

  {
    model: 'Notificação',
    title: 'Notificações',
    icon: Images.icon_bell.default,
    path: '/notificacoes',
  },

  {
    title: 'Settings',
    model: 'settings',
    icon: Images.icon_setting.default,
    path: '/settings',
    subNav: [
      {
        model: 'Permissão',
        title: 'Permissões',
        path: '/settings/permissoes',
      },
      {
        model: 'Permissão',
        title: 'Lista de Usuários',
        path: '/settings/permissoes/table',
      },
      {
        model: 'Permissão',
        title: 'Upload de Fotos',
        path: '/settings/upload',
      },
      {
        model: 'Pagamento Recorrente',
        function: 'Visualizar',
        title: 'Pagamento Recorrente',
        path: '/settings/pagamento-recorrente',
      },

      // {
      //   model: "Profile",
      //   title: "Perfis",
      //   path: '/settings/perfis',
      // }
    ],
  },
]
