import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ChartBar, AreaTotalChart, AreaInformacao } from "./styles";
import Images from "../../assets/index";
import Chart from "chart.js/auto";
import { CHART_URL } from "../../services/config";
import axios from "axios";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

const ChartCrescimentoMensalidadeMaior = () => {
  const [dataChart, setDataChart] = useState([]);
  const [data, setData] = useState({});
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await axios.get(CHART_URL + "job/").then((response) => {
      setData(response.data);
      setDataChart(response.data.data_chart);
      console.log("Data => ", response.data);
    });
  };
  return (
    <>
      <AreaTotalChart>
        <span>Casting lançados</span>
        <AreaInformacao>
          <span>{data.total_jobs_amount}</span>

          {data.reduction ? (
            <p
              style={{
                color: "#df025c",
              }}
            >
              {data.percentage?.toString().substr(0, 5)}% <FaArrowAltCircleDown />{" "}
            </p>
          ) : (
            <p
              style={{
                color: "#24C855",
              }}
            >
              {data.percentage?.toString().substr(0, 5)}% <FaArrowAltCircleUp />{" "}
            </p>
          )}
        </AreaInformacao>
        <ChartBar style={{ height: "100%", width: "100%" }}>
          <Bar
            data={{
              labels: dataChart.map((data) => data.date),
              datasets: [
                {
                  label: "",
                  data: dataChart.map((data) => data.amount),
                  fill: false,
                  backgroundColor: "rgba(16, 220, 212, 0.8)",
                  borderColor: "rgba(16, 220, 212, 1)",
                  borderWidth: 1.5,
                  responsive: true,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
            }}
          />
        </ChartBar>
      </AreaTotalChart>
    </>
  );
};

export default ChartCrescimentoMensalidadeMaior;
