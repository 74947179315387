import React, { useState } from 'react';

import { CreateAdmin, UpdateAdmin } from '../../../services/admin';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, ERROR, SUCCESS } from '../../../components/Notifications';
import {
  ModalBody, ModalButton, ModalContainer, DivInput, SelectOfForm, InputOfForm, I, Span,
} from './styles';

const ModalUserAdmin = ({ selected, types, toggleModal }) => {

  const [payload, setPayload] = useState(selected.id ?
    {
      id: selected.id,
      name: selected.name,
      email: selected.email,
      is_type: selected.is_type.id
    }
    : {});
  const [loading, setLoading] = useState(false);

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit() {
    if (payload.name && payload.email && payload.is_type) {
      if (payload.id) {
        setLoading(true);
        const response = await UpdateAdmin(payload.id, payload);
        setLoading(false);
        if (response) {
          addToast(SUCCESS, 'Usuário editado com sucesso');
          toggleModal(false);
        }
      } else {
        if (payload.password) {
          setLoading(true);
          const response = await CreateAdmin(payload);
          setLoading(false);
          if (response) {
            addToast(SUCCESS, 'Usuário criado com sucesso');
            toggleModal(false);
          }
        } else {
          addToast(ERROR, 'Informe todos os campos');
        }
      }
    } else {
      addToast(ERROR, 'Informe todos os campos');
    }
  }

  return (
    <ModalContainer>
      <ModalBody>
        <div className='text-center'>
          <h4>{payload.id ? 'Editar Usuário' : 'Novo Usuário Admin'} </h4>
        </div>

        <DivInput>

          <InputOfForm
            type="text"
            value={payload.name}
            required
            onChange={({ target }) => handleChange('name', target.value)} />

          <Span>Nome</Span>
          <I></I>
        </DivInput>



        <DivInput>

          <InputOfForm
            type="text"
            value={payload.email}
            required
            onChange={({ target }) => handleChange('email', target.value)} />

          <Span>Email</Span>
          <I></I>
        </DivInput>
        {payload.id ? null : <DivInput>

          <InputOfForm
            type="password"
            required
            onChange={({ target }) => handleChange('password', target.value)} />

          <Span>Senha</Span>
          <I></I>
        </DivInput>

        }



        <DivInput>

          <SelectOfForm
            required
            value={payload.is_type ? payload.is_type : ''}
            onChange={({ target }) => handleChange('is_type', Number(target.value))} >
            <option hidden>Escolha um Tipo</option>
            {types.map((type) => (
              <option value={type.id} key={type.id}>
                {type.name}
              </option>
            ))}


          </SelectOfForm>

          <Span>Tipo de usuário:</Span>
          <I></I>
        </DivInput>
        {loading ? <LoadingSpinner noMargin={true} /> : <div className="d-flex justify-content-end">
          <ModalButton blue onClick={handleSubmit}>{payload.id ? 'EDITAR' : 'CRIAR'}</ModalButton>
          <ModalButton onClick={() => toggleModal(false)}>CANCELAR</ModalButton>
        </div>}
      </ModalBody>
    </ModalContainer>
  );
}

export default ModalUserAdmin;