import styled from 'styled-components';

export const Container = styled.div`
 
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;

  & > h2 {
    margin-bottom: 56px;
  }
`;

export const Content = styled.div`
  background: var(--corEscura);
  color: var(--corBranca);
  width:100%;
  box-shadow: 0px 1px 4px var(--corEscura);
  border-radius: 10px;
  padding: 1em 2em;
  
`;

export const Form = styled.form`

  & > textarea {
    
    margin-bottom: 76px;
  }


  
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;

  & > div {
      width: 100%;
  }


  &  input{
    width: 100%;
  }
`

export const AlignEnd = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  
  & > button {
    height: 45px;
  }


 
`



export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 90%;
  margin-top:20px
}

@media(orientation: portrait){
  
  width: 100%;
  
}

& input {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}



& textarea {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: 2px solid var(--azulEsverdeado);
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  margin: 30px 0 30px 0;
  
}





& span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

& input:valid ~span,
& input:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
  font-size: 0,75em;
}




& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}



`



export const InputOfForm = styled.input`



`


export const TextareaOfForm = styled.textarea`



`


export const Span = styled.span`



`




export const I = styled.i`


`;