import React from "react";

import "./style.css";
import { ButtonEdit } from "../TabelaAprovacaoArtista/styles";
import {
  DivInput,
  Span,
  I,
  InputOfForm,
  TextareaOfForm,
  SelectOfForm,
} from "../../pages/AvaliacoesProdutores/CriarAvaliacao/styles";

function Modal({ closeModal, rejectModal, loading, handleChangeReject }) {
  const style = {
    border: "1px solid #828282",
    borderRadius: "6px",
    width: "99%",
    height: "9%",
    maxHeight: "10%",
    marginTop: "10px",
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="title">
            <h3>Rejeitar Cadastro</h3>
          </div>
          <div className="body">
            <DivInput>
              <InputOfForm
                required
                id="input"
                type="text"
                onChange={({ target }) =>
                  handleChangeReject("reason", target.value)
                }
              />
              <Span>Motivo</Span>
              <I></I>
            </DivInput>
            <DivInput style={{ marginTop: 30 }}>
              <TextareaOfForm
                required
                cols="50"
                onChange={({ target }) =>
                  handleChangeReject("description", target.value)
                }
              />

              <Span className="spanTextarea">Observação</Span>
            </DivInput>
          </div>
          <div className="footer">
            {loading ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : (
              <>
                <ButtonEdit font12 onClick={() => closeModal(false)}>
                  CANCELAR
                </ButtonEdit>
                <ButtonEdit azul font12 onClick={() => rejectModal()}>
                  CONFIRMAR
                </ButtonEdit>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;


const style = {
  color: "black"
};