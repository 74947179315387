import styled from 'styled-components';

export const Container = styled.div`
  padding: 1em 3em 3em 0px;

  & > h2 {
    margin-bottom: 56px;
  }
`;

export const Content = styled.div`
  background-color: var(--corEscura);
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em 2em;
`;

export const Header = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;

  & > p {
    padding: 0px;
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
  }

  & > button {
    height: 40px;
    font-size: 14px;
  }
`;

export const Photo = styled.img`
  width: 20%;
  height: 20%;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  margin-top: 16px;

  & > textarea {
    margin-bottom: 76px;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 1rem;
  & > div {
      width: 100%;
  }
`;

export const FormHalf = styled.div`
  width: 100%;
  ${props => props.row ? `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  `: ''}


  @media(orientation: portrait){
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;


    & div{
      width: 100%;
    }
  }

  select{
    height: 100%;
    border-radius: 10px;
    
  }



`