import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import ReactPaginate from 'react-paginate'

import {
  DeleteArtist,
  GetPaginatedArtists,
  GetSearchArtists,
} from '../../services/artist'
import {
  Content,
  TableCustom,
  ButtonEdit,
  ButtonSearch,
  ModalButton,
  ModalContainer,
  ModalBody,
  SearchContainer,
  Row,
  DivInput,
  InputOfForm,
  Span,
  I,
} from './styles'
import LoadingSpinner from '../LoadingSpinner'
import Notification, { ERROR, SUCCESS } from '../Notifications'
import { checkPermission, FUNCTIONS, MODELS } from '../../utils'
import { useAuth } from '../../AppContext/Context'

function Table() {
  const {
    user: { is_type },
  } = useAuth()
  const [itensPerPage, setItensPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [load, setLoad] = useState(false)

  const [modal, setModal] = useState(null)
  const [loadingModal, setLoadingModal] = useState(false)

  const [searchList, setSearchList] = useState([])
  const [isSearch, setIsSearch] = useState(0)
  const [searchParams, setSearchParam] = useState({
    name: '',
    email: '',
    phone: '',
    nick_name: '',
  })

  const navigate = useNavigate()

  const [artists, setArtists] = useState([])

  useEffect(() => {
    listaArtista()
  }, [currentPage])

  function handleClick(profile) {
    console.log(profile);
    navigate('/artista/editar-perfil', {
      state: {
        profile,
      },
    })
  }

  async function listaArtista() {
    setLoad(true)
    const resp = await GetPaginatedArtists(currentPage)
    if (resp) {
      setArtists(resp.data.results)
      setLoad(false)
      const totalPages = Math.ceil(resp.data.count / itensPerPage)
      setPages(totalPages)
    } else {
      setLoad(false)
      Notification('Nenhum dado retornado', '', 'error')
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1)
  }

  async function handleDelete() {
    setLoadingModal(true)
    const resp = await DeleteArtist(modal)
    setLoadingModal(false)
    if (resp) {
      listaArtista()
      Notification('Usuário apagado', '', SUCCESS)
      setModal(null)
    } else {
      Notification('Não foi possível apagar usuário', '', ERROR)
    }
  }

  async function submitSearch() {
    if (
      searchParams.name.length > 3 ||
      searchParams.email.length > 3 ||
      searchParams.phone.length > 3 ||
      searchParams.nick_name.length > 1
    ) {
      setIsSearch(1)
      const resp = await GetSearchArtists(searchParams)
      setIsSearch(2)
      if (resp) {
        setSearchList(resp)
      }
    } else {
      Notification('Informe no mínimo 4 caracteres', '', 'error')
    }
  }

  function handleKey(e) {
    if (e.key === 'Enter' && isSearch !== 1) {
      submitSearch()
    }
  }

  function handleChangeSearch(field, value) {
    setSearchParam({ ...searchParams, [field]: value })
  }

  function clearFilter() {
    setIsSearch(0)
    setSearchList([])
    setSearchParam({ name: '', email: '', phone: '', nick_name: '' })
  }

  return (
    <div>
      <h4>Visualização de Perfil</h4>

      <Content>
        <Row>
          <DivInput>
            <InputOfForm
              type="text"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.nick_name}
              onChange={({ target }) =>
                handleChangeSearch('nick_name', target.value)
              }
            />

            <Span>Código / Apelido</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <InputOfForm
              type="text"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.name}
              onChange={({ target }) =>
                handleChangeSearch('name', target.value)
              }
            />

            <Span>Nome</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <InputOfForm
              type="text"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.email}
              onChange={({ target }) =>
                handleChangeSearch('email', target.value)
              }
            />

            <Span>Email</Span>
            <I></I>
          </DivInput>

          <DivInput>
            <InputOfForm
              type="number"
              required="required"
              onKeyDown={handleKey}
              value={searchParams.phone}
              onChange={({ target }) =>
                handleChangeSearch('phone', target.value)
              }
            />

            <Span>Telefone</Span>
            <I></I>
          </DivInput>

          <SearchContainer>
            <ButtonSearch azul large onClick={() => submitSearch()}>
              Procurar
            </ButtonSearch>
            <ButtonSearch onClick={() => clearFilter()}>Limpar</ButtonSearch>
          </SearchContainer>
        </Row>

        <TableCustom large>
          <thead>
            <th data-label="Nome">Código</th>
            <th data-label="Nome">Nome</th>
            <th data-label="Email">Email</th>
            <th data-label="Telefone">Telefone</th>
            <th data-label="Status">Status</th>
            <th data-label="Funções">Funções</th>
          </thead>
          <tbody>
            {load === true ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : isSearch != 0 ? (
              isSearch === 1 ? (
                <div className="text-center m-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                searchList.map((profile, index) => (
                  <tr style={line} key={index}>
                    <td data-label="Nome">{profile.nick_name}</td>
                    <td data-label="Nome">{profile.name}</td>
                    <td data-label="Email">{profile.email}</td>
                    <td data-label="Telefone">{profile.phone_prefix+' '+profile.phone}</td>
                    <td data-label="Status">{profile.status}</td>
                    <td data-label="Funções">
                      {checkPermission(
                        is_type,
                        MODELS.Artista,
                        FUNCTIONS.Atualizar,
                      ) ? (
                        <>
                          <ButtonEdit
                            azul
                            large
                            font12
                            onClick={() => handleClick(profile)}
                          >
                            EDITAR PERFIL
                          </ButtonEdit>
                          <ButtonEdit
                            verm
                            large
                            font12
                            onClick={() => setModal(profile.id)}
                          >
                            APAGAR PERFIL
                          </ButtonEdit>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))
              )
            ) : (
              artists.map((profile, index) => (
                <tr style={line} key={index}>
                  <td data-label="Nome">{profile.nick_name}</td>
                  <td data-label="Nome">{profile.name}</td>
                  <td data-label="Email">{profile.email}</td>
                  <td data-label="Telefone">{profile.phone_prefix+' '+profile.phone}</td>
                  <td data-label="Status">{profile.status}</td>
                  <td data-label="Funções">
                    {checkPermission(
                      is_type,
                      MODELS.Artista,
                      FUNCTIONS.Atualizar,
                    ) ? (
                      <>
                        <ButtonEdit
                          azul
                          large
                          font12
                          onClick={() => handleClick(profile)}
                        >
                          EDITAR PERFIL
                        </ButtonEdit>
                        <ButtonEdit
                          verm
                          large
                          font12
                          onClick={() => setModal(profile.id)}
                        >
                          APAGAR PERFIL
                        </ButtonEdit>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </TableCustom>
        {isSearch !== 0 ? null : (
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            nextLinkClassName="linkPage buttonPage page"
            previousLinkClassName="linkPage buttonPage page"
            breakClassName={'item break-me'}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeLinkClassName={'active'}
            pageLinkClassName={'buttonPage page'}
          />
        )}
      </Content>
      {modal && (
        <ModalContainer>
          <ModalBody>
            <div>
              <h4>Tem certeza que deseja excluir o Artista?</h4>
            </div>
            {loadingModal ? (
              <LoadingSpinner noMargin={true} />
            ) : (
              <>
                <ModalButton blue onClick={handleDelete}>
                  EXCLUIR
                </ModalButton>
                <ModalButton onClick={() => setModal(null)}>
                  CANCELAR
                </ModalButton>
              </>
            )}
          </ModalBody>
        </ModalContainer>
      )}
    </div>
  )
}
const line = {
  border: '1px solid #cccccc99'
}
export default Table
