import styled from "styled-components";

export const DashboardTotal = styled.div`
  display: flex-box;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ButtonMonth = styled.button`
  background-color: var(--azulEsverdeado);
  font-size: 12px;
  height: 20px;
  width: 120px;
  border-radius: 8px;
  border: none;
  margin-top: 35px;
`;

export const CardsUp = styled.div`
display: flex;
position: relative;
justify-content: space-between;
column-gap: 1rem;
width:100%;
  height: 100%;
@media(orientation: portrait){
  width:100%;
  height: 100%;
  row-gap: 1rem;
  
  position: relative
  display: flex;
  
  flex-direction:column;
  margin-bottom: 1rem;
 }
`;

export const CardsDown = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  column-gap: 1rem;
  width:100%;
  height: 100%;
  @media(orientation: portrait){
    width:100%;
  row-gap: 1rem;
  height: 100%;
    
    position: relative
    display: flex;
    
    flex-direction:column;
   }

`;
