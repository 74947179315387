import styled from 'styled-components';

export const Container = styled.div`
      
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
    & > h2 {
    margin-bottom: 56px;
    }
`;

export const Content = styled.div`

color: var(--corBranca);
width: 100%;
  
  border-radius: 6px;
 
`;


export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--azulEsverdeado) 0% 0% no-repeat padding-box" : "darkorange 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 35px;
  font-weight: 550;
  font-size: ${props => props.font12 ? "0.75em" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 5px;
  padding: ${props => props.azul ? "0 7px" : "0 13px"};
`

export const Table = styled.table.attrs({
  
})`
 
   
height: 100%;
background: var(--corEscura);
margin: 30px 0 40px 0;
padding: 0;
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
width: 100%;
table-layout: fixed;



&:nth-child(2){
background: var(--corEscura);

width:100%;

}  


caption {
font-size: 1.5em;
margin: .5em 0 .75em;
}



th,
td {
position: relative;
padding: .625em;
width: 100%;

flex-wrap: wrap;
text-align: center;
}

th {
font-size: .85em;
color: var(--azulEsverdeado);
letter-spacing: .1em;
border-bottom: 1px solid var(--azulEsverdeado);
text-transform: uppercase;
}


td {
font-size: 100%;
color: var(--corBranca);
inline-size: 100%; 
overflow-wrap: break-word;


}

&:last-child {


}



@media screen and (max-width: 600px) {


background: transparent;
box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
border: 0;


&:nth-child(2){
  background: transparent;
  border: 0;
  width: 100%;
  
} 
  


caption {
  font-size: 1.3em;
}

thead {

  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}



  


tr {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  display: block;
  justify-content: center;
  margin-bottom: 2em;
  }

td {
  border-bottom: 1px solid var(--azulEsverdeado);
  display: block;
  font-size: .8em;
  text-align: right;
}

td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  
  */
  content: attr(data-label);
  float: left;
  color: var(--azulEsverdeado);
  font-weight: bold;
  text-transform: uppercase;
}

td:last-child {
  border-bottom: 0;
}
}
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index:4;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: var(--corEscura);
  color: var(--corBranca);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  text-align: center;
  z-index:4;

  div {
    margin-bottom: 20px
  }

  div h2{
    font-size: 1.5em;
  }

  div h4{
    font-size: 1.5em;
    color: var(--corBranca);
  }


  @media(orientation: portrait){
    div h2{
      font-size: 1.1em;
    }
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  min-width: 90px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "0.75em" : "0.75em"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`

export const SearchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
  background: var(--corEscura);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
 


`

export const ButtonSearch = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.verm ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 2px;
  padding: 0 5px;
  margin: 6px 6px;
`




export const NewButton = styled.button`


  appearance: button;
  
  background: ${props => props.azul ? "var(--corPrincipal2) " : props.verm ? "#DC1076 " : "#707070 "};
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: var(--corBranca);
  cursor: pointer;
  display: inline-block;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin-right: 20px;

  margin-top: 20px;
  outline: none;
  overflow: visible;
  padding: 13px 19px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
 
 

 
 &:main, &:focus {
  user-select: auto;
 }
 
 &:hover:not(:disabled) {
  filter: brightness(1.1);
 }
 
 &:disabled {
  cursor: auto;
 }
 
 &:active:after {
  border-width: 0 0 0px;
 }

`





export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 40%;
  margin:30px 15px;
}

@media(orientation: portrait){
  
  width: 90%;
  margin:20px 15px;
}

& input {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 2;
  
}



& span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

& input:valid ~span,
& input:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
  font-size: 0,75em;
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 1;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
}



`



export const InputOfForm = styled.input`



`


export const Span = styled.span`



`




export const I = styled.i`


`