import styled from 'styled-components';

export const Container = styled.div`
    padding: 1em 3em 3em 0px;

    & > h2 {
    margin-bottom: 56px;
    }
`;

export const Content = styled.div`
  background-color: var(--corBranca);
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em;
`;

export const Table = styled.table`
 

   
height: 100%;
background: var(--corEscura);
margin: 30px 0 40px 0;
padding: 0;
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
width: 100%;
table-layout: fixed;



&:nth-child(2){
background: var(--corEscura);

width:100%;

}  


caption {
font-size: 1.5em;
margin: .5em 0 .75em;
}



th,
td {
position: relative;
padding: .625em;
width: 100%;

flex-wrap: wrap;
text-align: center;
}

th {
font-size: .85em;
color: var(--azulEsverdeado);
letter-spacing: .1em;
border-bottom: 1px solid var(--azulEsverdeado);
text-transform: uppercase;
}


td {
font-size: 100%;
color: var(--corBranca);
inline-size: 100%; 
overflow-wrap: break-word;


}





@media screen and (max-width: 600px) {


background: transparent;
box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
border: 0;


&:nth-child(2){
  background: transparent;
  border: 0;
  width: 100%;
  
} 
  


caption {
  font-size: 1.3em;
}

thead {

  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}



  


tr {
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
-ms-border-radius: 10px;
-o-border-radius: 10px;
background: var(--corEscura);
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
display: block;
justify-content: center;
margin-bottom: 2em;
}

td {
  border-bottom: 1px solid var(--azulEsverdeado);
  display: block;
  font-size: .8em;
  min-height: 35px;
  text-align: right;
}

td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  
  */
  content: attr(data-label);
  float: left;
  color: var(--azulEsverdeado);
  font-weight: bold;
  text-transform: uppercase;
}

td:last-child {
  border-bottom: 0;
}
}
`;