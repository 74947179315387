const runtimeEnv = window.ENV ?? {};
export const BASE_API_URL = runtimeEnv['REACT_APP_SERVER_API'] ?? process.env.REACT_APP_SERVER_API ?? '';
export const REACT_APP_IMAGEUPLOADER_API = runtimeEnv['REACT_APP_IMAGEUPLOADER_API'] ?? process.env.REACT_APP_IMAGEUPLOADER_API ?? '';
export const REACT_APP_STORAGE_BASE_URL = runtimeEnv['REACT_APP_STORAGE_BASE_URL'] ?? process.env.REACT_APP_STORAGE_BASE_URL ?? '';
export const REACT_APP_WORKER_API = runtimeEnv['REACT_APP_WORKER_API'] ?? process.env.REACT_APP_WORKER_API ?? '';

export const SESSION_URL = `${BASE_API_URL}session/logged/`;
export const CHART_URL = `${BASE_API_URL}chart/`;

export const AUTH_URL = `${BASE_API_URL}admin/authentication/`; 
export const ADMIN_URL = `${BASE_API_URL}user/admin/`;

export const CLIENT_SOLICITATION = `${BASE_API_URL}job/solicitation/`;

export const GET_URL_ARTISTA = `${BASE_API_URL}user/artist/`;
export const APPROVED_ARTIST_URL = `${BASE_API_URL}user/artist/approved/`;
export const ARTIST_REJECT = `${BASE_API_URL}user/artist/rejected/`;
export const ARTIST_PHOTO = `${BASE_API_URL}image/photo/`;

export const PROFILE_URL = `${BASE_API_URL}job/profile/`;

export const GET_URL_CLIENTE = `${BASE_API_URL}user/client/`;
export const POST_URL_INVITE = `${BASE_API_URL}user/client/invite/`;
export const CLIENT_REJECT = `${BASE_API_URL}user/client/rejected/`;
export const CLIENT_APPROVED = `${BASE_API_URL}user/client/approved/`;

export const MEDIATION_ARTIST_URL = `${BASE_API_URL}user/evaluation/artist/`;
export const MEDIATION_PRODUCER_URL = `${BASE_API_URL}user/evaluation/client/`;

export const JOB_SOLICITATION_URL = `${BASE_API_URL}job/solicitation/`;
export const JOB_APROVE_URL = `${REACT_APP_WORKER_API}/NotificationPy`;

export const CLASS_URL = `${BASE_API_URL}video/class/`;

export const NOTIFICATION_URL = `${BASE_API_URL}support/notification/`;

export const PERMISSION_URL = `${BASE_API_URL}permission/list/`;

export const TYPES_URL = `${BASE_API_URL}permission/type/`;

export const RECURRING_PAYMENT_TYPE = `${BASE_API_URL}recurring-payment-type/`;
