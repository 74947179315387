import React, { useState } from 'react';
import { Container, Content, Form, FormRow, AlignEnd, DivInput, InputOfForm,  TextareaOfForm,Span, I } from './styles';
import { useNavigate } from 'react-router';
import Notification from '../../../components/Notifications';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import { PostInviteClient } from '../../../services/client';

function Invite() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();
    }

    async function handleInvite() {
        const data = {
            name: name,
            email: email,
            description: description,
        }

        const resp = await PostInviteClient(data);
        Notification("Convite enviado com sucesso!", "", "success")
        navigate("/clientes/profiles")
    }

    return (
        <Container>
            <h4>Convidar Clientes</h4>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <FormRow>


                        <DivInput>

                            <InputOfForm
                                type="text"
                                required="required"

                                onChange={(ev) => setName(ev.target.value)} />

                            <Span>Nome completo</Span>
                            <I></I>
                        </DivInput>



                        <DivInput>

                            <InputOfForm

                                type="text"
                                required="required"

                                onChange={(ev) => setEmail(ev.target.value)} />

                            <Span>Email</Span>
                            <I></I>
                        </DivInput>

                    </FormRow>
                   

<FormRow>
                    <DivInput>

                        <TextareaOfForm

                            type="text"
                            required="required"

                            onChange={(ev) => setDescription(ev.target.value)} />

                        <Span className='spanTextarea'>Mensagem</Span>
                  
                    </DivInput>
                    </FormRow>

                    <AlignEnd>
                        <Button onClick={handleInvite}>Enviar Convite</Button>
                    </AlignEnd>
                </Form>
            </Content>
        </Container>
    );
}

export default Invite;