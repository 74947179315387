import styled from 'styled-components'; 

export const HeaderArea = styled.nav`
  height: 68px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgba(0,0,0,0.05);
  background: var(--corEscura);


  
  
  img{
    filter: ${props => props.cores === "Escuro" ? 'brightness(100%)' : 'brightness(20%)'};
    margin: 0 0 0 15px;
  }


`
export const Img = styled.img`
  margin: 0.8% 0 0 1.5%;

`




export const ButtonMenu = styled.button.attrs({
  className: "btn btn-secondary Menu",
  
})
`





@media(orientation: portrait){

 display: block;
position: absolute;
transform: translateX(250px);
-webkit-transform: translateX(250px);
-moz-transform: translateX(250px);
-ms-transform: translateX(250px);
-o-transform: translateX(250px);
}

` 