import styled from "styled-components";

export const FormCustom = styled.form`
  display: flex;
  gap: 2.5rem;
  background: #ffffff;
  box-shadow: 0px 1px 4px #15223214;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 20px;
  width: 100%;
  height: auto;

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  h6 {
    color: var(--unnamed-color-131523);
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
  }
  span {
    color: var(--unnamed-color-828282);
    font-size: 14px;
    margin: 0 7px 0 3px;
  }
`;

export const SectionCustom = styled.section`
  display: flex;
  width: 62.5%;
  padding: 1rem;
  //background-color: #000;
  flex-direction: column;
  .session {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const DivButtons = styled.div`
  display: flex;
  width: 15%;
  flex-direction: column;
  position: absolute;
  right: 2.5%;
`;
export const CardCustom = styled.figcaption`
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 6px;
  float: left;
  margin-right: 10px;
  width: 155px;
  margin-bottom: 10px;

  img {
    border-radius: 6px;
    width: 155px;
    height: 200px;
  }

  input {
    width: 20px;
    height: 20px;
  }

  label {
    font-size: 12px;
    float: right;
    margin-left: 5px;
  }
`;
