import styled from 'styled-components';


export const Container = styled.div`
width: 100%;

display: flex;
align-items: center;
flex-direction: column;
border-radius: 10px;
`

export const DivInput = styled.div`
  display: flex;
 
  justify-content: center;
  align-items: center;
  flex-direction: row-column;
  width: 100%;
`
export const ValorMensalidade = styled.div`
  width: 200px;
  
  @media(orientation: portrait){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
`
export const DataInicio = styled.div`
  width: 200px;
 
  @media(orientation: portrait){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const DataTermino = styled.div`
  width: 200px;

  @media(orientation: portrait){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--corEscura);
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;

  @media(orientation: portrait){
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
`

export const Label = styled.label`
font-size: 0.9em;
`

export const InputText = styled.input`
  width: 200px;
  position: relative;
  height: 46px;

  @media(orientation: portrait){
    width: 100%;
  }

`

export const Button = styled.button`
 background-color: var(--corPrincipal2);
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 66px;
  margin-right: 20px;
`

export const ButtonMonth = styled.button`
  background-color: var(--corPrincipal2);
  font-size: 12px;
  height: 20px;
  width: 120px;
  border-radius: 8px;
  border: none;
  margin-top: 35px;
`
export const ChartArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 40px 0;
  height: 300px;



  @media(orientation: portrait){
    
    flex-direction: column;
    height: 100%;
  }
  
`








export const DInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

@media(orientation: portrait){
  display: flex;
  align-items: center;
  justify-content: center;
  width:80%;
  
}

& input {
  font-weight: 700;
  position: relative;
  width: 100%;
  align-self: center;
  
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}



& span {
  
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 0.75em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

& input ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
  font-size: 0,75em;
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}



`



export const InputOfForm = styled.input`



`


export const Span = styled.span`



`




export const I = styled.i`


`