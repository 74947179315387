import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 50px 0 50px 0;

`;

export const Table = styled.table`
  background: var(--corEscura);
  color:var(--corBranca);
  width: 100%;
  border-radius: 10px;

  td, th {
    
    width: 200px;
    height: 47px;
    white-space: nowrap;
    padding-left: 12px;
    text-align: center;
  }



  @media (orientation: portrait) {
    height: 100%;
    background: var(--corEscura);
    margin: 30px 0 40px 0;
    padding: 0;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 100%;
    table-layout: fixed;
    
    
    
    &:nth-child(2){
    background: var(--corEscura);
    
    width:100%;
    
    }  
    
    
    caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
    }
    
    
    
    th,
    td {
    position: relative;
    padding:  0.625em ;
    width: 100%;
    
    flex-wrap: wrap;
    text-align: center;
    }
    

    td:first-child{
      padding: 0.625em;
    }
    
    th {
    font-size: .85em;
    color: var(--azulEsverdeado);
    letter-spacing: .1em;
    border-bottom: 1px solid var(--azulEsverdeado);
    text-transform: uppercase;
    }
    
    
    td {
    font-size: 100%;
    color: var(--corBranca);
    inline-size: 100%; 
    overflow-wrap: break-word;
    
    
    }
    
    &:last-child {
    
    
    }

    background: transparent;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
    border: 0;
    
    
    &:nth-child(2){
      background: transparent;
      border: 0;
      width: 100%;
      
    } 
      
    
    
    caption {
      font-size: 1.3em;
    }
    
    thead {
    
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    
    
      
    
    
    tr {
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      background: var(--corEscura);
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
      display: block;
      justify-content: center;
      margin-bottom: 2em;
      }
    
    td {
      border-bottom: 1px solid var(--azulEsverdeado);
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    td:nth-child(2)::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: "Atualizar";
      
      */
      content: "Atualizar";
      float: left;
      color: var(--azulEsverdeado);
      font-weight: bold;
      text-transform: uppercase;
    }


    td:nth-child(3)::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: "Excluir";
      
      */
      content: "Excluir";
      float: left;
      color: var(--azulEsverdeado);
      font-weight: bold;
      text-transform: uppercase;
    }


    td:nth-child(4)::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: "Inserir";
      
      */
      content: "Inserir";
      float: left;
      color: var(--azulEsverdeado);
      font-weight: bold;
      text-transform: uppercase;
    }


    td:nth-child(5)::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: "Visualizar";
      
      */
      content: "Visualizar";
      float: left;
      color: var(--azulEsverdeado);
      font-weight: bold;
      text-transform: uppercase;
    }
    
    td:last-child {
      border-bottom: 0;
    }
    }
`;

export const Select = styled.select`
  height: 44px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
`

export const Button = styled.button`
  background-color: var(--corPrincipal2);
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  transition: .1s;
  padding: 12px;
  font-size: 0.75em;
  font-weight: bold;
`;

export const ButtonPlus = styled.button`
  color: green;
  margin-right: 15px;
  border: none;
  border-radius: 4px;
  width: 40px;
  height: 30px;
  font-weight: bold;
`;

export const CheckBox = styled.div`



  
  input {
    margin-right: 10px;
  
    
  }

  label {
    font-weight: 600;
    margin-right: 5px;
  }
`;




export const DivInput = styled.div.attrs({
  className: "inputbox",
  
})
`
& {
  position: relative;
  width: 100%;
  margin:30px 0px 0 0;
}



& textarea {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 0px ;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: 2px solid var(--azulEsverdeado);
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 2;
  margin: 30px 0 30px 0;
  
}

@media(orientation: portrait){
  
  width: 100%;
  margin:20px 1.5vw;


  & select{
    width:100%;
  }
}

& input,
& select {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 2;
  
}


& select{
  height: 100%;
  color:var(--corBranca);
}


& span {
  
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}



& input:valid ~span,
& input:focus ~span,
& select:valid ~span,
& select:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
  font-size: 0.75em;
}


& input[type="date"]:valid ~span,
& input[type="date"]:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
}

& input[type="date"]:invalid ~span{
  color: #8f8f8f;
  transform: translateX(-10px) translateY(-34px);
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 1;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
}



`



export const InputOfForm = styled.input`



`



export const SelectOfForm = styled.select`
width:100%;



& option{
  color: var(--corBranca);
}


`


export const Span = styled.span`



`

export const TextareaOfForm = styled.textarea`



`




export const I = styled.i`


`