import React, { useState } from "react";
import {
  ContainerAvaliacao,
  Form,
  Label,
  Button,
  AvaliacaoEditSpace,
  FunctionEdit,
  InputStar,
  LabelStar,
  DescricaoArea,
  UpDivsAvaliacao,
  InputDescricao,
  LabelAvaliacao,
  SelectFunction,
} from "../CriarAvaliacao/styles";
import { FaStar } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { updateArtisMediation } from "../../../services/mediation";

const EditarAvaliacao = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(null);
  const [payload, setPayload] = useState({
    grade: Number(location.state.grade),
    description: location.state.description,
  });

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const response = await updateArtisMediation(location.state.id, payload);
    setLoading(false);
    if (response) {
      navigate("/mediacao/avaliacoes/artista");
    }
  }

  return (
    <>
      <h4>
        <b>Editar Avaliação</b>
      </h4>
      <ContainerAvaliacao>
        <Form onSubmit={handleSubmit}>
          <UpDivsAvaliacao>
            <AvaliacaoEditSpace>
              <Label>Avaliador</Label>
              <LabelAvaliacao>
                {" "}
                {location.state.evaluator.name}
              </LabelAvaliacao>{" "}
              <Label>Avaliado</Label>
              <LabelAvaliacao> {location.state.rated.name}</LabelAvaliacao>{" "}
            </AvaliacaoEditSpace>
              <FunctionEdit id="alvo">
                {/* <Label>Função</Label>  */}
                {/* <SelectFunction name="Artistas" id="select" disabled="true">
                <option value="Artista" selected>Artista</option>
                <option value="Cliente">Cliente</option>
                    </SelectFunction> */}
                <Label> Avaliação </Label>
                {[...Array(5)].map((star, i) => {
                  const ratingValue = i + 1;
                  return (
                    <LabelStar>
                      <InputStar
                        type="radio"
                        name="rating"
                        onClick={() => handleChange("grade", ratingValue)}
                      />
                      <FaStar
                        className="star"
                        color={
                          ratingValue <= (hover || payload.grade)
                            ? "var(--corPrincipal2)"
                            : "#e4e5e9"
                        }
                        size={26}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)}
                      />
                    </LabelStar>
                  );
                })}
              </FunctionEdit>
          <Button disabled={loading} loading={loading} type="submit">
            Salvar alteração
          </Button>
          </UpDivsAvaliacao>
          <DescricaoArea>
            <Label>Descrição</Label>
            <InputDescricao
              onChange={({ target }) =>
                handleChange("description", target.value)
              }
              value={payload.description}
            ></InputDescricao>
          </DescricaoArea>
        </Form>
      </ContainerAvaliacao>
    </>
  );
};

export default EditarAvaliacao;
