import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  background-color: var(--corEscura);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 50px 0 50px 0;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerClass = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #fff;
  margin: 50px 0 50px 0;
`;
export const ContainerNotificacao = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #fff;
  margin: 50px 0 50px 0;
`;
export const ContainerAvaliacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0 2rem 0;
  width: 96%;
  background-color: #fff;
  margin: 50px 0 50px 0;
`;

export const Form = styled.form`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AvaliacaoSpace = styled.div`
  margin: 0 0px 0 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: collumn;
  display: flex;
  gap: 1rem;
`;
export const AvaliacaoEditSpace = styled.div`
  margin: 0 0px 0 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

export const InputsArea = styled.div`
  margin: 0 20px 0 20px;
  width: 100%;
`;

export const Function = styled.div`
  width: 100%;
`;
export const FunctionEdit = styled.div``;

export const SelectFunction = styled.select`
  // width: 180px;
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;
export const Label = styled.label`
  color: var(--corBranca);
  margin-right: 10px;
`;

export const LabelAvaliacao = styled.label`
  border: 1px solid grey;
  width: 320px;
  height: 44px;
  margin: 2px 0 0 2px;
  padding: 2px 0 0 10px;
`;

export const LabelStar = styled.label`
  .star {
    cursor: pointer;
    transition: color 200ms;
  }
`;
export const InputStar = styled.input`
  display: none;
`;

export const InputText = styled.input`
  width: 320px;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
`;

export const InputTextClass = styled.input`
  width: 100%;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
  border-radius: 8px;
`;

export const Button = styled.button`
  background-color: ${(props) =>
    props.loading ? "#707070" : "var(--corPrincipal2)"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0px 20px 0px;
`;

export const ButtonArea = styled.div`
  display: flex;
  margin-left: auto;
`;

export const DescricaoArea = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin: 0 0px 0 0px;
`;
export const DescricaoAreaClass = styled.div`
  width: 100%;

  margin: 0;
`;

export const UpDivs = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const UpDivsClass = styled.div`
  display: flex;
  height: 30vh;
`;
export const UpDivsAvaliacao = styled.div`
  display: flex;
  width: 96%;
  justify-content: space-between;
`;

export const InputDescricao = styled.textarea`
  margin-top: 2%;
  width: 96%;
  height: 50%;
  padding: 4px 0 0 6px;
`;

export const FunctionNotify = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DivInput = styled.div.attrs({
  className: "inputbox",
})`
  & {
    position: relative;
    width: 100%;
    margin: 30px 0px 0 0;
  }

  & textarea {
    font-weight: 700;
    position: relative;
    width: 100%;
    padding: 20px 0px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 2px solid var(--azulEsverdeado);
    color: #000;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
    margin: 30px 0 30px 0;
  }

  @media (orientation: portrait) {
    width: 100%;
    margin: 20px 1.5vw;

    & select {
      width: 100%;
    }
  }

  & input,
  & select {
    font-weight: 700;
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: #fff;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
  }

  & select {
    height: 100%;
    color: var(--corBranca);
  }

  & span {
    position: absolute;
    left: 0;
    padding: 20px 10px 10px;
    font-size: 1em;
    color: #8f8f8f;
    letter-spacing: 00.05em;
    transition: 0.5s;
    pointer-events: none;
  }

  & input:valid ~ span,
  & input:focus ~ span,
  & select:valid ~ span,
  & select:focus ~ span {
    color: var(--azulEsverdeado);
    transform: translateX(-10px) translateY(-34px);
  }

  & input[type="date"]:valid ~ span,
  & input[type="date"]:focus ~ span {
    color: var(--azulEsverdeado);
    transform: translateX(-10px) translateY(-34px);
  }

  & input[type="date"]:invalid ~ span {
    color: #8f8f8f;
    transform: translateX(-10px) translateY(-34px);
  }

  & i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--azulEsverdeado);
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 9;
  }

  & i:nth-child(2) {
    position: absolute;
    pointer-events: all;
    margin-left: 95%;
    bottom: 30px;
    width: 100%;

    background: transparent;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    z-index: 10;
  }
`;

export const InputOfForm = styled.input`
  color: #000 !important;
`;

export const SelectOfForm = styled.select`
  width: 100%;

  & option {
    color: var(--corBranca);
  }
`;

export const Span = styled.span``;

export const TextareaOfForm = styled.textarea``;

export const I = styled.i``;
