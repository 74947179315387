import React, { useState } from 'react'
import FilePondUpload from '../../../components/FilePondUpload'
import { Container } from '../style'

const Upload = () => {
  const [images, setImages] = useState([])
  return (
    <>
      <h4>Upload de fotos</h4>
      <Container>
        <FilePondUpload
          folder="telainicial"
          images={images}
          setImages={(param) => {
            setImages([...images, param])
          }}
        />
      </Container>
    </>
  )
}

export default Upload
