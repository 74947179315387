import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`

export const Button = styled.button`
  background-color: var(--corPrincipal2);
  font-size: 12px;
  width: 180px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 30px 0% 0 auto;
`

export const ButtonArea = styled.div`
  display: flex;
`