import React, { useEffect, useState } from "react";

import {
  Container,
  Form,
  Input,
  InputTextarea,
  RowInputs,
  Select,
  Button,
} from "./style";
import { GetProfiles } from "../../../services/profile";
import { updateJob } from "../../../services/job";
import { useNavigate, useLocation } from "react-router-dom";
import { addToast, ERROR, SUCCESS } from "../../../components/Notifications";
import LoadingSpinner from "../../../components/LoadingSpinner";

const EditJob = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [profiles, setProfiles] = useState([]);
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(location.state);
    if (location.state) {
      const job = location.state;
      setPayload({
        id: job.id,
        category: job.category,
        title: job.title,
        description: job.description,
        time: job.time,
        date: job.date,
        value: job.value,
        image_right_time: job.image_right_time,
        feeding: job.feeding,
        transport: job.transport,
        profile: job.profile.id,
        full_address: job.full_address,
      });
    }
    loadProfiles();
  }, []);

  async function loadProfiles() {
    const response = await GetProfiles();
    if (response) {
      setProfiles(response);
    }
  }

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const response = await updateJob(payload);
    setLoading(false);
    if (response) {
      addToast(SUCCESS, "Job atualizado com sucesso");
      navigate("/jobs");
    } else {
      addToast(
        ERROR,
        "Não foi possível atualizar o Job",
        "Verifique os dados informados"
      );
    }
  }

  return (
    <>
      <h4>Job Cadastrado</h4>
      <Container>
        <Form onSubmit={handleSubmit}>
          <label>Status: {location.state.status}</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "20%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
              }}
            >
              <label>Titulo</label>
              <InputTextarea
                cols="50"
                value={payload.title}
                onChange={({ target }) => handleChange("title", target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
              }}
            >
              <label>Categoria</label>
              <Select
                style={{
                  width: "60%",
                }}
                value={payload.category}
                onChange={({ target }) =>
                  handleChange("category", target.value)
                }
              >
                {payload.category !== "Campanha Publicitária / Ficção" &&
                  payload.category !== "Catálogo" &&
                  payload.category !== "Desfile" &&
                  payload.category !== "Modelo de Prova / Eventos" && (
                    <option value={payload.category}>{payload.category}</option>
                  )}
                <option value="Campanha Publicitária / Ficção">
                  Campanha Publicitária / Ficção
                </option>
                <option value="Catálogo">Catálogo</option>
                <option value="Desfile">Desfile</option>
                <option value="Modelo de Prova / Eventos">
                  Modelo de Prova / Eventos
                </option>
              </Select>
            </div>
          </div>
          <label>Descrição</label>
          <InputTextarea
            cols="50"
            value={payload.description}
            onChange={({ target }) => handleChange("description", target.value)}
          />
          <RowInputs>
            <div>
              <label>Horário</label> <br />
              <Input
                type="time"
                value={payload.time}
                onChange={({ target }) => handleChange("time", target.value)}
              />
            </div>
            <div>
              <label>Data</label> <br />
              <Input
                type="date"
                value={payload.date}
                onChange={({ target }) => handleChange("date", target.value)}
              />
            </div>
            <div>
              <label>Cache</label> <br />
              <Input
                type="number"
                value={payload.value}
                onChange={({ target }) => handleChange("value", target.value)}
              />
            </div>
          </RowInputs>
          <RowInputs>
            <div>
              <label>Transporte</label> <br />
              <Select
                value={payload.transport}
                onChange={({ target }) =>
                  handleChange("transport", target.value)
                }
              >
                <option value="Transporte por conta do contratante">
                  Transporte por conta do contratante
                </option>
                <option value="Transporte por conta do contratado">
                  Transporte por conta do contratado
                </option>
              </Select>
            </div>
            <div>
              <label>Alimentação</label> <br />
              <Select
                value={payload.feeding}
                onChange={({ target }) => handleChange("feeding", target.value)}
              >
                <option value="Alimentação por conta do contratante">
                  Alimentação por conta do contratante
                </option>
                <option value="Alimentação por conta do contratado">
                  Alimentação por conta do contratado
                </option>
              </Select>
            </div>
          </RowInputs>
          <RowInputs>
            <div>
              <label>Perfil</label> <br />
              <Select
                value={payload.profile}
                onChange={({ target }) =>
                  handleChange("profile", Number(target.value))
                }
              >
                {profiles.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Select>
            </div>
            <div>
              <label>Tempo de Direito de Imagem</label> <br />
              <Select
                value={payload.image_right_time}
                onChange={({ target }) =>
                  handleChange("image_right_time", target.value)
                }
              >
                <option value="Não Aplicável">Não Aplicável</option>
                <option value="6 Meses">6 Meses</option>
                <option value="1 Ano">1 Ano</option>
                <option value="2 Anos">2 Anos</option>
              </Select>
            </div>
            <div>
              <label>Tempo de Vinculação a Campanha</label> <br />
              <Select
                value={payload.campaign_broadcast}
                onChange={({ target }) =>
                  handleChange("campaign_broadcast", target.value)
                }
              >
                <option value="Não Aplicável">Não Aplicável</option>
                <option value="6 Meses">6 Meses</option>
                <option value="1 Ano">1 Ano</option>
                <option value="2 Anos">2 Anos</option>
              </Select>
            </div>
          </RowInputs>
          <label>Morada</label>
          <Input
            value={payload.full_address}
            onChange={({ target }) =>
              handleChange("full_address", target.value)
            }
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Salvar Job</Button>
          )}
        </Form>
      </Container>
    </>
  );
};

export default EditJob;
