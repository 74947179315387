import React from "react";
import { formatDate } from '../../utils';
import LoadingSpinner from "../LoadingSpinner";
import { TableCustom  } from './styles'

function TabelaNoticacoes({
  currentUpdate,
  notifications,
  handleChangeVisible
}){
  const style = {
    fontSize: 20,
  }

  const checkVisible = (_item) => {
    if(_item.visible) {
      if(_item.visible < 1) {
        return "NÃO"
      } else {
        return "SIM"
      }
    }
    return "NÃO"
  }

  return(
    <div>
      <TableCustom >
        <thead>
          <th data-label="Titulo">Título</th>
          <th data-label="Mensagem&nbsp;&nbsp;">Mensagem  </th>
          <th data-label="Email">Email</th>
          <th data-label="Função">Função</th>
          <th data-label="Visível">Visível</th>
          <th data-label="Data de envio">Data de envio</th>
        </thead>
        <tbody>
          {notifications.map((item, index) => (
            <><tr key={item.id}>
              <td data-label="Titulo">{item.title}</td>
              <td data-label="Mensagem&nbsp;&nbsp;">{item.message}</td>
            
                {item.function === "Produtor" ? <td data-label="Email">
                  {typeof (item.client) === "object" ? '' : item.client.email}
                </td> : <td data-label="Email">
                  {typeof (item.artist) === "object" ? '' : item.artist.email}
                </td>}
                <td data-label="Função">{item.function}</td>
                <td data-label="Visível">
                  {currentUpdate && currentUpdate.id === item.id ? <LoadingSpinner noMargin />
                    : <select
                      value={checkVisible(item)}
                      onChange={({ target }) => handleChangeVisible(item, target.value, index)}
                    >
                      <option>NÃO</option>
                      <option>SIM</option>
                    </select>}
                </td>
                <td data-label="Data de envio">{formatDate(item.created_at)}</td>
              </tr></>
          ))}
        </tbody>
        
      </TableCustom>
    </div>
  )
}

export default TabelaNoticacoes;