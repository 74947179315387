import styled from 'styled-components';





export const TableCustom = styled.table`


   
  height: 100%;
  background: var(--corEscura);
  margin: 30px 0 40px 0;
  padding: 0;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  table-layout: fixed;



&:nth-child(2){
  background: var(--corEscura);
  
  width:100%;
  
}  


caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

 

 th,
 td {
  position: relative;
  padding: .625em;
  width: 100%;
  
  flex-wrap: wrap;
  text-align: center;
}

 th {
  font-size: .85em;
  color: var(--azulEsverdeado);
  letter-spacing: .1em;
  border-bottom: 1px solid var(--azulEsverdeado);
  text-transform: uppercase;
}


td {
  font-size: 100%;
  color: var(--corBranca);
  inline-size: 100%; 
  overflow-wrap: break-word;

  
}

&:last-child {
  

}



@media screen and (max-width: 600px) {


  background: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
  border: 0;


  &:nth-child(2){
    background: transparent;
    border: 0;
    width: 100%;
    
  } 
    


 caption {
    font-size: 1.3em;
  }
  
 thead {
  
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  
    
  
  
  tr {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background: var(--corEscura);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
    display: block;
    justify-content: center;
    margin-bottom: 2em;
    }
  
 td {
    border-bottom: 1px solid var(--azulEsverdeado);
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
 td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    
    */
    content: attr(data-label);
    float: left;
    color: var(--azulEsverdeado);
    font-weight: bold;
    text-transform: uppercase;
  }
  
 td:last-child {
    border-bottom: 0;
  }
}
`




export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--azulEsverdeado) 0% 0% no-repeat padding-box" : "darkorange 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 35px;
  font-weight: 550;
  font-size: ${props => props.font12 ? "0.75em" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 5px;
  padding: ${props => props.azul ? "0 7px" : "0 13px"};
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 30;
  left: 0;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: var(--corEscura);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  text-align: center;

  div {
    margin-bottom: 20px
  }


  div h2{
    font-size: 1.5em;
  }

  div h4{
    font-size: 1.5em;
    color: var(--corBranca)
  }

  @media(orientation: portrait){
    div h2{
      font-size: 1.1em;
    }
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`