import React, { useState, useEffect, useRef } from "react";
import InputMask from "react-input-mask";
import FilePondUpload from "../../components/FilePondUpload.js";
import {
  FormCustom,
  InputName,
  InpuCustom,
  Hr,
  ElemtDiv,
  DivCustom,
  DivLeft,
  DivCentral,
  ElemtDivCabelo,
  ButtonCabelo,
  ElemtDivOlhos,
  ElementDivAltura,
  ElementDivCintura,
  DivRight,
  ElementDivQuadril,
  ElementDivBusto,
  DivFotos,
  ButtonAddFoto,
  ButtonSalvar,
  CardCustom,
  SelectGender,
  CustomCurrencyInput,
  SelectRecurrency,
  SelectDisabled,
} from "./style";
import Slider from "../Slider";
import { useNavigate, useLocation } from "react-router";
import {
  DeleteArtistPhoto,
  PatchArtista,
  SelectArtistPhoto,
  SendArtistPhoto,
} from "../../services/artist";
import { uploadArtistPhoto } from "../../services/upload";
import Notification, { addToast, SUCCESS, ERROR } from "../Notifications";
import LoadingSpinner from "../LoadingSpinner";
import { codigoPaises } from "../../Helpers";
import DeleteModal from "./DeleteModal";

import Adicao from "../../assets/adicao.png";
import { InputSlide } from "../../pages/CadastroArtista/style";
import { GetRecurring } from "../../services/recurringType";
import { createNotification } from "../../services/notification.js";

function PhoneInput(props) {
  return (
    <InputMask
      mask={props.mask}
      type={props.type}
      placeholder={props.mask}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
    ></InputMask>
  );
}

function EditPerfil() {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef();
  const day = [1 - 31];
  const month = [1 - 12];
  const year = [1960 - 2040];
  const maskCustomer = [day / month / year];

  const [profileArtist, setProfileArtist] = useState("");
  const [baseArtist, setBaseArtist] = useState("");
  const [approvedAt, setApprovedAt] = useState("");
  const [fotos, setFotos] = useState([...location.state.profile.photos]);
  const [imageUploading, setImageUploading] = useState(false);


  const [selectLoad, setSelectLoad] = useState(false);
  const [phoneMask, setPhoneMask] = useState("999999999");
  const [modal, setModal] = useState(false);
  const [recurringTypeList, setRecurringTypeList] = useState([]);
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);
  const [payload, setPayload] = useState({
    title: "",
    function: "Modelo",
    message: "",
    artist: 0,
  });
  // location.state.profile.photos

  const { profile } = location.state;
  useEffect(() => {
    if (!location.state) {
      return navigate("/artista/profiles");
    }
    if (profile.phone_prefix === "+1") {
      setPhoneMask("999999999");
    } else if (profile.phone_prefix === "+55") {
      setPhoneMask("(99) 99999-9999");
    } else if (profile.phone_prefix === "+351") {
      setPhoneMask("999999999");
    } else {
      setPhoneMask("");
    }
    setPayload({
      ...payload,
      title: "Perfil editado!",
      message:
        "Seu perfil foi alterado pelo suporte, entre em contato para mais detalhes!",
      artist: profile.id,
    });
    setProfileArtist({
      id: profile.id,
      name: profile.name,
      email: profile.email,
      instagram: profile.instagram,
      phone_prefix: profile.phone_prefix,
      phone: profile.phone,
      gender: profile.gender,
      nick_name: profile.nick_name,
      birth_date: formatDate(profile.birth_date),
      address_city: profile.address_city,
      hair: profile.hair,
      eye: profile.eye,
      skin: profile.skin,
      stature: profile.stature,
      waist: profile.waist,
      bust: profile.bust,
      hip: profile.hip,
      status: profile.status,
      monthly_fee: profile.monthly_fee,
      expiration_date: formatDate(profile.expiration_date),
      recurring_payment_type: profile.recurring_payment_type
        ? profile.recurring_payment_type.id
        : null,
    });
    setBaseArtist(false);
    loadRecurringType();
    setApprovedAt(formatDate(profile.approved_at));
  }, []);

  async function loadRecurringType() {
    try {
      const response = await GetRecurring();
      setRecurringTypeList(response);
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(name, value) {
    if (name === "phone_prefix") {
      if (value === "+1") {
        setPhoneMask("999999999");
      } else if (value === "+55") {
        setPhoneMask("(99) 99999-9999");
      } else if (value === "+351") {
        setPhoneMask("999999999");
      } else {
        setPhoneMask("");
      }
    }
    setBaseArtist(true);
    setProfileArtist({
      ...profileArtist,
      [name]: value,
    });
  }

  async function handleEditArtista(e) {
    e.preventDefault();
    const numberPhotos =
      newPhotos.length + (fotos.length - currentPhotos.length);
    const checkNumber = /\d+/g;

    if (profileArtist.name.match(checkNumber) > 0) {
      Notification("Ops!", "Não é permitido números no campo nome.", "error");
    } else if (numberPhotos > 10) {
      console.log(numberPhotos);
      Notification(
        "Ops!",
        "Não é permitido mais que 10 fotos por usuário",
        "error"
      );
    } else {
      const resp = await PatchArtista({
        ...profileArtist,
        birth_date: profileArtist.birth_date + "T00:00:00",
        expiration_date: profileArtist.expiration_date + "T00:00:00",
        images: newPhotos,
        remove_images: currentPhotos,
      });
      console.log(currentPhotos);
      if (resp && resp.status === 200) {
        if (baseArtist) {
          createNotification(payload);
        }
        Notification("Dados alterados com sucesso!", "", "success");
        navigate("/artista/profiles");
      }
    }
  }

  function checkPhotosCount() {
    const sum = currentPhotos.reduce((count, item) => {
      if (item.is_active) {
        return count + 1;
      }
      return count;
    }, 0);
    return sum;
  }

  const formatDate = (time, onlyDate) => {
    if (time) {
      const _date = new Date(time);
      if (onlyDate) {
        return `${_date.toLocaleDateString("pt-pt", { timeZone: "UTC" })}`;
      } else {
        return `${_date
          .toLocaleDateString("pt-pt", { timeZone: "UTC" })
          .split("/")
          .reverse()
          .join("-")}`;
      }
    }
  };

  async function handleDeleteFoto(photo) {
    const response = await DeleteArtistPhoto(photo.id);
    if (response) {
      const _newCurrentPhotos = currentPhotos.filter(
        (item) => item.id !== photo.id
      );
      setCurrentPhotos(_newCurrentPhotos);
      setModal(false);
    } else {
      addToast(ERROR, "Não foi possível deletar a foto");
    }
  }

  return (
    <>
      <h4>Edição de Perfil</h4>
      <FormCustom onSubmit={handleEditArtista}>
        <div>
          <h4 style={{ color: "#212529" }}>
            Talento - {profileArtist.nick_name}
          </h4>
        </div>

        <ElemtDiv>
          <div>
            <label>Nome Completo</label>
            <br />
            <InputName
              type="text"
              value={profileArtist.name}
              onChange={(ev) => handleChange("name", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div>
            <label>Email</label>
            <br />
            <InputName
              type="email"
              value={profileArtist.email}
              onChange={(ev) => handleChange("email", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div>
            <label>Data Nascimento</label>
            <br />
            <InpuCustom
              type="date"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              title="Somente número nesse campo"
              value={profileArtist.birth_date}
              onChange={({ target }) =>
                handleChange("birth_date", target.value)
              }
              required
            ></InpuCustom>
          </div>
          <div>
            <label>Cidade de Atuação</label>
            <br />
            <InpuCustom
              type="text"
              value={profileArtist.address_city}
              placeholder="Ex. Belo Horizonte"
              onChange={(ev) => handleChange("address_city", ev.target.value)}
              required
            ></InpuCustom>
          </div>

          <div>
            <label>Instagram</label>
            <br />
            <InputName
              type="text"
              value={profileArtist.instagram}
              onChange={(ev) => handleChange("instagram", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div style={{ marginTop: 25 }}>
            Gênero <br />
            <SelectGender
              value={profileArtist.gender}
              required
              onChange={({ target }) => handleChange("gender", target.value)}
            >
              <option hidden>Escolha um Gênero</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
              <option value="Trans Masculino">Trans Masculino</option>
              <option value="Trans Feminino">Trans Feminino</option>
              <option value="Outros">Outros</option>
              <option value="Todos">Todos</option>
            </SelectGender>
          </div>

          <div style={divColumn}>
            <label>Telefone</label>
            <div style={divCustom}>
              <select
                value={profileArtist.phone_prefix}
                onChange={({ target }) =>
                  handleChange("phone_prefix", target.value)
                }
              >
                {codigoPaises.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
              {/* <MaskInput
                required
                mask={phoneMask}
                value={profileArtist.phone}
                placeholder={phoneMask}
                onChange={({ target }) => handleChange("phone", target.value)}
                style={style}
              /> */}
              <PhoneInput
                value={profileArtist.phone}
                onChange={({ target }) => handleChange("phone", target.value)}
                style={style}
              ></PhoneInput>
            </div>
          </div>

          <div style={{ marginTop: 29 }}>
            Data Aprovação
            <br />
            {approvedAt ? (
              <InpuCustom
                type="date"
                disabled
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                title="Somente número nesse campo"
                value={approvedAt}
              ></InpuCustom>
            ) : (
              "-"
            )}
          </div>

          <div style={{ marginTop: 25 }}>
            Valor Pagamento <br />
            <CustomCurrencyInput
              prefix="€ "
              value={profileArtist.monthly_fee}
              onChange={(masked, raw) => {
                handleChange("monthly_fee", raw);
              }}
            />
          </div>

          <div style={{ marginTop: 25 }}>
            Status <br />
            {profileArtist.status === "PAUSADO" ||
              profileArtist.status === "APROVADO" ? (
              <SelectGender
                value={profileArtist.status}
                required
                onChange={({ target }) => handleChange("status", target.value)}
              >
                <option hidden>Escolha um Status</option>
                <option value="APROVADO">APROVADO</option>
                <option value="PAUSADO">PAUSADO</option>
              </SelectGender>
            ) : (
              <SelectDisabled disabled>
                <option>{profileArtist.status}</option>
              </SelectDisabled>
            )}
          </div>

          <div style={{ marginTop: 25 }}>
            Tipo Assinatura <br />
            <SelectRecurrency
              required
              value={profileArtist.recurring_payment_type}
              onChange={({ target }) =>
                handleChange("recurring_payment_type", target.value)
              }
            >
              <option hidden>Seleciona</option>
              {recurringTypeList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectRecurrency>
          </div>

          <div style={{ marginTop: 29 }}>
            Data Expiração
            <br />
            <InpuCustom
              type="date"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              title="Somente número nesse campo"
              value={profileArtist.expiration_date}
              onChange={({ target }) =>
                handleChange("expiration_date", target.value)
              }
              required
            ></InpuCustom>
          </div>
        </ElemtDiv>

        <DivCustom>
          <DivLeft>
            <ElemtDivCabelo>
              <label>Cabelo</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Castanho Escuro"}
                  onClick={() => handleChange("hair", "Castanho Escuro")}
                >
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Loiro"}
                  onClick={() => handleChange("hair", "Loiro")}
                >
                  Loiro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Ruivo"}
                  onClick={() => handleChange("hair", "Ruivo")}
                >
                  Ruivo
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Grisalho"}
                  onClick={() => handleChange("hair", "Grisalho")}
                >
                  Grisalho
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Castanho Claro"}
                  onClick={() => handleChange("hair", "Castanho Claro")}
                >
                  Castanho Claro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Colorido"}
                  onClick={() => handleChange("hair", "Colorido")}
                >
                  Colorido
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Preto"}
                  onClick={() => handleChange("hair", "Preto")}
                >
                  Preto
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Careca"}
                  onClick={() => handleChange("hair", "Careca")}
                >
                  Careca
                </ButtonCabelo>
              </div>
            </ElemtDivCabelo>

            <ElemtDivOlhos>
              <label>Olhos</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Castanho Escuro"}
                  onClick={() => handleChange("eye", "Castanho Escuro")}
                >
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Verde"}
                  onClick={() => handleChange("eye", "Verde")}
                >
                  Verde
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Azul"}
                  onClick={() => handleChange("eye", "Azul")}
                >
                  Azul
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Castanho Claro"}
                  onClick={() => handleChange("eye", "Castanho Claro")}
                >
                  Castanho Claro
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
            <ElemtDivOlhos>
              <label>Pele</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Branca"}
                  onClick={() => handleChange("skin", "Branca")}
                >
                  Branca
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Negra"}
                  onClick={() => handleChange("skin", "Negra")}
                >
                  Negra
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Morena"}
                  onClick={() => handleChange("skin", "Morena")}
                >
                  Morena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Indígena"}
                  onClick={() => handleChange("skin", "Indígena")}
                >
                  Indígena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Asiática"}
                  onClick={() => handleChange("skin", "Asiática")}
                >
                  Asiática
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.skin ===
                    "Sul Asiática - Índia, Paquistão, etc."
                  }
                  onClick={() =>
                    handleChange(
                      "skin",
                      "Sul Asiática - Índia, Paquistão, etc."
                    )
                  }
                >
                  Sul Asiática - Índia, Paquistão
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
          </DivLeft>

          <DivCentral>
            <ElementDivAltura>
              <label>
                Altura:{" "}
                <InputSlide
                  value={profileArtist.stature}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("stature", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 250</p>
              <Slider
                domain={[0, 250]}
                values={[profileArtist.stature ? profileArtist.stature : 0]}
                selected={profileArtist.stature}
                onChangeSelected={(value) => handleChange("stature", value[0])}
              />
            </ElementDivAltura>
            <ElementDivCintura>
              <label>
                Cintura:{" "}
                <InputSlide
                  value={profileArtist.waist}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("waist", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 200</p>
              <Slider
                domain={[0, 200]}
                values={[profileArtist.waist ? profileArtist.waist : 0]}
                selected={profileArtist.Awaist}
                onChangeSelected={(value) => handleChange("waist", value[0])}
              />
            </ElementDivCintura>
          </DivCentral>

          <DivRight>
            <ElementDivQuadril>
              <label>
                Anca:{" "}
                <InputSlide
                  value={profileArtist.hip}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("hip", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 200</p>
              <Slider
                domain={[0, 200]}
                values={[profileArtist.hip ? profileArtist.hip : 0]}
                selected={profileArtist.hip}
                onChangeSelected={(value) => handleChange("hip", value[0])}
              />
            </ElementDivQuadril>
            <ElementDivBusto>
              <label>
                Peito:{" "}
                <InputSlide
                  value={profileArtist.bust}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("bust", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 200</p>
              <Slider
                domain={[0, 200]}
                values={[profileArtist.bust ? profileArtist.bust : 0]}
                selected={profileArtist.bust}
                onChangeSelected={(value) => handleChange("bust", value[0])}
              />
            </ElementDivBusto>
          </DivRight>
        </DivCustom>
        <FilePondUpload
          setUploading={(e) => setImageUploading(e)}
          folder="artista"
          name={profileArtist.name}
          photos={location.state.profile.photos}
          images={newPhotos}
          setImages={(param) => {
            setNewPhotos([...newPhotos, param]);
          }}
          remove={(imageId) => {
            if (typeof imageId === "number") {
              const filter = location.state.profile.photos.find(
                (photos) => photos.id === imageId
              );
              setCurrentPhotos([...currentPhotos, filter]);
            }
          }}
        />

        <ButtonSalvar disabled={imageUploading} style={{ opacity: imageUploading ? 0.6 : 1 }}>SALVAR ALTERAÇÕES</ButtonSalvar>
      </FormCustom>
      {modal ? (
        <DeleteModal
          handleDeleteFoto={handleDeleteFoto}
          modal={modal}
          setModal={setModal}
        />
      ) : null}
    </>
  );
}

export default EditPerfil;

const Label = {
  marginTop: "12px",
};

const divCustom2style = {
  display: "flex",
  alignItems: "flex-end",
  marginLeft: "10px",
  marginBottom: "5px",
};

const style = {
  border: "1px solid #828282",
  borderRadius: "10px",
  padding: "0px 16px 0px 16px",
  height: "40px",
};

const divCustom = {
  display: "flex",
};

const divColumn = {
  display: "flex",
  flexDirection: "column",
};