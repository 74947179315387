import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputContainer = styled.input`   
    width: 100%;
    border: 1px solid #828282;
    border-radius: 10px;
    padding: 0px 16px 0px 16px;
    height: 40px;
`;
