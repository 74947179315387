import React from 'react'
import {
  Container, InputText, Form, Label, Button, ValorMensalidade,
  DataInicio, DataTermino, ButtonMonth, DivInput, ChartArea, DInput, InputOfForm, Span, I
} from './styles'
import ChartMensalidadeMaior from '../../components/ChartMensalidadeMaior'
import Chart1 from '../../components/Chart1'
import { useNavigate } from 'react-router'
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from '../../AppContext/Context';


const GestaoFinanceira = () => {
  const { user: { is_type } } = useAuth();
  const navigate = useNavigate()

  function handleNavigation(event) {
    event.preventDefault()
    navigate("/financeiro/gestao")
  }
  return (
    <>
      <h3><b>Gestão Financeira</b></h3>
      <Container>
        <DivInput>
          <Form>
            <ValorMensalidade>

              <DInput>

                <InputOfForm
                  type="number"
                  required="required"
                  placeholder='EUR 250,00'
                />

                <Span>Valor da mensalidade</Span>
                <I></I>
              </DInput>

            </ValorMensalidade>
            <DataInicio>



              <DInput>

                <InputOfForm
                  type="date"
                  required="required"
                />

                <Span>Data de início</Span>
                <I></I>
              </DInput>
            </DataInicio>
            <DataTermino>
 

              <DInput>

                <InputOfForm

                  type="date"
                  required="required"
                />

                <Span>Data de término</Span>
                <I></I>
              </DInput>


            </DataTermino>
            {checkPermission(is_type, MODELS.Financeiro, FUNCTIONS.Inserir) ?
              <Button onClick={handleNavigation}>Salvar Valor</Button> : null}
          </Form>
        </DivInput>
        <ButtonMonth>
          Últimos 12 meses
        </ButtonMonth>
        <ChartArea>
          <ChartMensalidadeMaior />
          <Chart1 />
        </ChartArea>


      </Container>
    </>
  )
}

export default GestaoFinanceira;