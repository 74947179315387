import React, { useEffect, useState } from 'react'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { addToast, ERROR, SUCCESS } from '../../../components/Notifications'
import { GetAdmins, UpdateAdmin } from '../../../services/admin'
import ModalUserAdmin from './modalUserAdmin'
import { Table, Button, Input } from './styles'
import { checkPermission, FUNCTIONS, MODELS } from '../../../utils'
import { useAuth } from '../../../AppContext/Context'

const Users = ({ types }) => {
  const {
    user: { is_type },
  } = useAuth()
  const [userList, setUserList] = useState([])
  const [filterList, setFilterList] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [userLoading, setUserLoading] = useState(false)

  const [modal, setModal] = useState(false)

  useEffect(() => {
    loadUsers()
  }, [])
  useEffect(() => {
    const seacth = async () => {
      setFilterList(
        userList.filter((user) => {
          return user.name.toLowerCase().includes(filter)
        }),
      )
    }
    seacth()
  }, [userList, filter])

  async function loadUsers() {
    setLoading(true)
    const response = await GetAdmins()
    setLoading(false)
    if (response) {
      setUserList(response)
    }
  }

  async function handleDisable(user) {
    setUserLoading(user.id)
    const response = await UpdateAdmin(user.id, { is_active: !user.is_active })
    setUserLoading(false)
    if (response) {
      addToast(SUCCESS, 'Usuário atualizado com sucesso')
      loadUsers()
    } else {
      addToast(ERROR, 'Não foi possível desabilitar esse usuário')
    }
  }

  function toggleModal(item) {
    setModal(item)
    if (!item) {
      loadUsers()
    }
  }

  return (
    <>
      <div
        className="d-flex justify-content-end mt-3"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        {checkPermission(is_type, MODELS.Admin, FUNCTIONS.Inserir) ? (
          <>
            <Input
              placeholder="Pesquisa"
              onChange={(e) => {
                setFilter(e.target.value)
                console.log(filter)
              }}
            />
            <Button blue onClick={() => toggleModal(true)}>
              Criar Novo
            </Button>
          </>
        ) : null}
      </div>
      <Table large>
        <thead>
          <th data-label="Nome">Nome</th>
          <th data-label="Email">Email</th>
          <th data-label="Type">Type</th>
          <th data-label=""></th>
        </thead>
        <tbody>
          {loading === true ? (
            <div className="text-center m-5">
              <div className="spinner-border text-info" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : filterList.length > 0 ? (
            filterList.map((item, index) => (
              <tr key={item.id}>
                <td data-label="Nome">{item.name}</td>
                <td data-label="Email">{item.email}</td>
                <td data-label="Type">{item.is_type.name}</td>
                <td data-label="">
                  {checkPermission(
                    is_type,
                    MODELS.Admin,
                    FUNCTIONS.Atualizar,
                  ) ? (
                    userLoading === item.id ? (
                      <LoadingSpinner noMargin />
                    ) : (
                      <div>
                        <Button blue onClick={() => toggleModal(item)}>
                          Editar
                        </Button>
                        <Button
                          green={!item.is_active}
                          onClick={() => handleDisable(item)}
                        >
                          {item.is_active ? `Desativar` : `Ativar`}
                        </Button>
                      </div>
                    )
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            userList.map((item, index) => (
              <tr key={item.id}>
                <td data-label="Nome">{item.name}</td>
                <td data-label="Email">{item.email}</td>
                <td data-label="Type">{item.is_type.name}</td>
                <td data-label="">
                  {checkPermission(
                    is_type,
                    MODELS.Admin,
                    FUNCTIONS.Atualizar,
                  ) ? (
                    userLoading === item.id ? (
                      <LoadingSpinner noMargin />
                    ) : (
                      <div>
                        <Button blue onClick={() => toggleModal(item)}>
                          Editar
                        </Button>
                        <Button
                          green={!item.is_active}
                          onClick={() => handleDisable(item)}
                        >
                          {item.is_active ? `Desativar` : `Ativar`}
                        </Button>
                      </div>
                    )
                  ) : null}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {modal ? (
        <ModalUserAdmin
          selected={modal}
          toggleModal={toggleModal}
          types={types}
        />
      ) : null}
    </>
  )
}

export default Users
