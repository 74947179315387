import React from 'react';
import { Container, InputContainer } from './styles';

function Input({label: inputLabel, ...props}) {
  return (
    <Container>
      {inputLabel && (<label>{inputLabel}</label>)}
      <InputContainer {...props} />
    </Container>
  );
}

export default Input;