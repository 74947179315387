import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  ContainerNotificacao,
  Form,
  Button,
  FunctionNotify,
  DivInput,
  InputOfForm,
  SelectOfForm,
  Span,
  I,
  TextareaOfForm,
  Grid,
  ButtonSelect,
  Row,
  SearchContainer,
  ButtonSearch,
} from "./styles";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { createNotification } from "../../../services/notification";
import {
  GetPaginatedArtists,
  GetSearchArtists,
} from "../../../services/artist";
import {
  GetClienteAproved,
  GetSearchClient,
} from "../../../services/client";
import { Dialog } from "primereact/dialog";
import ReactPaginate from "react-paginate";
import Notification from "../../../components/Notifications";

const EnviarNotificacao = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [rowClick, setRowClick] = useState(true);

  const [searchList, setSearchList] = useState([]);
  const [isSearch, setIsSearch] = useState(0);
  const [searchParams, setSearchParam] = useState({
    name: "",
    email: "",
    phone: "",
    nick_name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [pagesClient, setPagesClient] = useState(1);
  const [load, setLoad] = useState(false);

  const [clientList, setClientList] = useState([]);
  const [payload, setPayload] = useState({
    title: "",
    function: "Produtor",
    message: "",
  });

  useEffect(() => {
    if (payload.function === "Modelo") {
      console.log('art')
      loadArtists(currentPage);
    } else {
      console.log('cli')
      loadClients(currentPage);
    }
  }, [currentPage, payload.function]);

  async function loadArtists(page = 1) {
    setLoad(true);
    const resp = await GetPaginatedArtists(page);
    if (resp) {
      setArtistList(resp.data.results);
      setLoad(false);
      const totalPages = Math.ceil(resp.data.count / 10);
      console.log(totalPages)
      setPages(totalPages);
    } else {
      setLoad(false);
      Notification("Nenhum dado retornado", "", "error");
    }
  }

  async function loadClients(page = 1) {
    setLoad(true);
    const resp = await GetClienteAproved(page);
    if (resp) {
      setClientList(resp.data.results);
      setLoad(false);
      const totalPages = Math.ceil(resp.data.count / 10);
      console.log(totalPages)
      setPagesClient(totalPages);
    } else {
      setLoad(false);
      Notification("Nenhum dado retornado", "", "error");
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1);
  }

  function handleChange(name, value) {
    if (name === "function") {
      const _newPayload = { ...payload };
      delete _newPayload.artist;
      delete _newPayload.client;
      setPayload({ ..._newPayload, [name]: value });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    console.log(payload);
    const response =  await createNotification(payload);
    setLoading(false);
    if (response) {
      navigate("/notificacoes");
    }
  }
  async function submitSearch() {
    if (
      searchParams.name.length > 3 ||
      searchParams.email.length > 3 ||
      searchParams.phone.length > 3 ||
      searchParams.nick_name.length > 1
    ) {
      setIsSearch(1);
      if (payload.function === "Modelo") {
        const resp = await GetSearchArtists(searchParams);
        console.log(resp);
        setIsSearch(2);
        if (resp) {
          setArtistList(resp);
        }
      } else {
        const resp = await GetSearchClient(searchParams);
        console.log(resp);
        setIsSearch(2);
        if (resp) {
          setClientList(resp);
        }
      }
    } else {
      Notification("Informe no mínimo 4 caracteres", "", "error");
    }
  }

  function handleKey(e) {
    if (e.key === "Enter" && isSearch !== 1) {
      submitSearch();
    }
  }

  function handleChangeSearch(field, value) {
    setSearchParam({ ...searchParams, [field]: value });
  }

  function clearFilter() {
    setIsSearch(0);
    setSearchList([]);
    setSearchParam({ name: "", email: "", phone: "", nick_name: "" });
    if (payload.function === "Modelo") {
      loadArtists();
    } else {
      loadClients();
    }
  }

  return (
    <>
      <h4>
        <b>Cadastro de Notificação</b>
      </h4>
      <ContainerNotificacao>
        <Form onSubmit={handleSubmit}>
          <Button type="submit" disabled={loading} loading={loading}>
            Salvar Notificação
          </Button>
          <FunctionNotify id="alvo">
            <DivInput>
              <SelectOfForm
                required="required"
                name="function"
                id="function"
                onChange={({ target }) =>{
                  handleChange("function", target.value)
                  clearFilter()
                }
                }
                value={payload.function}
              >
                <option hidden>Escolha uma opção</option>
                <option value="Modelo">Modelo</option>
                <option value="Produtor">Produtores</option>
              </SelectOfForm>

              <Span>Função</Span>
              <I></I>
            </DivInput>
          </FunctionNotify>
          <Grid>
            <DivInput>
              <InputOfForm
                type="text"
                required="required"
                onChange={({ target }) => handleChange("title", target.value)}
              />

              <Span>Título</Span>
              <I></I>
            </DivInput>
            {payload.function === "Modelo" ? (
              <FunctionNotify>
                <ButtonSelect onClick={() => setVisible(true)}>
                  <Span>{payload.artist && payload.function === 'Modelo' ?  `Talento: ${name}` : 'Selecionar os Talentos'}</Span>
                </ButtonSelect>
              </FunctionNotify>
            ) : (
              <FunctionNotify>
                <ButtonSelect onClick={() => setVisible(true)}>
                  <Span>{payload.client && payload.function === 'Produtor' ?  `Produtor: ${name}` : 'Selecionar os Produtores'}</Span>
                </ButtonSelect>
              </FunctionNotify>
            )}
          </Grid>

          <DivInput style={{ marginTop: 50 }}>
            <TextareaOfForm
              type="text"
              required="required"
              onChange={({ target }) => handleChange("message", target.value)}
            />

            <Span className="spanTextarea">Mensagem da notificação</Span>
          </DivInput>
        </Form>

        <Dialog
          header={payload.function === "Modelo" ? 'Seleção de Talentos' : 'Seleção de Produtores'}
          visible={visible}
          style={{ width: "65vw" }}
          onHide={() => setVisible(false)}
        >
          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "4rem",
                padding: "0 2rem 1rem 2rem",
              }}
            >
              {payload.function === "Modelo" ? (
                <DivInput>
                  <InputOfForm
                    type="text"
                    required="required"
                    onKeyDown={handleKey}
                    value={searchParams.nick_name}
                    onChange={({ target }) =>
                      handleChangeSearch("nick_name", target.value)
                    }
                  />

                  <Span>Código / Apelido</Span>
                  <I></I>
                </DivInput>
              ) : (
                <DivInput>
                  <InputOfForm
                    type="text"
                    required="required"
                    onKeyDown={handleKey}
                    value={searchParams.email}
                    onChange={({ target }) =>
                      handleChangeSearch("email", target.value)
                    }
                  />

                  <Span>Email</Span>
                  <I></I>
                </DivInput>
              )}

              <DivInput>
                <InputOfForm
                  type="text"
                  required="required"
                  onKeyDown={handleKey}
                  value={searchParams.name}
                  onChange={({ target }) =>
                    handleChangeSearch("name", target.value)
                  }
                />

                <Span>Nome</Span>
                <I></I>
              </DivInput>
            </div>
            <SearchContainer>
              <ButtonSearch azul large onClick={() => submitSearch()}>
                Procurar
              </ButtonSearch>
              <ButtonSearch onClick={() => clearFilter()}>Limpar</ButtonSearch>
            </SearchContainer>
          </Row>
          <DataTable
            value={payload.function === "Modelo" ? artistList : clientList}
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            selectionMode={rowClick ? null : "checkbox"}
            onSelectionChange={(e) => {
              console.log(e);
              if (e.type === "all") {
                setVisible(false);
                return setPayload({
                  title: payload.title,
                  message: payload.message,
                  function: payload.function,
                });
              }
              handleChange(payload.function === "Modelo" ? "artist" : "client", Number(e.value[0].id));
              setName(e.value[0].name)
              setVisible(false);
            }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            {payload.function === "Modelo" && (
              <Column field="nick_name" header="Codigo"></Column>
            )}
            <Column field="name" header="Nome"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="phone" header="Telefone"></Column>
          </DataTable>
          {isSearch !== 0 ? null : (
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              nextLinkClassName="linkPage buttonPage page"
              previousLinkClassName="linkPage buttonPage page"
              breakClassName={"item break-me"}
              pageCount={payload.function === 'Modelo' ? pages : pagesClient}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeLinkClassName={"active"}
              pageLinkClassName={"buttonPage page"}
            />
          )}
        </Dialog>
      </ContainerNotificacao>
    </>
  );
};
const line = {
  border: "1px solid #cccccc99",
  padding: 0,
};
export default EnviarNotificacao;
