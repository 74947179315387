import React from 'react'
import { Line } from 'react-chartjs-2'
import { TotalContainer, TotalChart } from './styles';
import Chart from 'chart.js/auto';

const ChartMensalidadeMaior = () => {
  return (
    <>
      <TotalChart>
        <span>Mensalidades</span>
      <TotalContainer>
      <div style={{height: '100%', width: '100%'}}>
      <Line 
        data={{
          labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai','Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          datasets:[
            {
              label: 'Ativos',
              data: [4,9,3, 5, 2, 2, 5, 7, 3, 5, 4, 6, 2],
              fill: false,
              backgroundColor: 'rgb(30, 94, 255, 0.8)',
              borderColor: 'rgba(30, 94, 255, 1)',
              borderWidth: 1.5,
              pointRadius:0,
            },
            {
              label: 'Pausados',
              data: [3, 5, 2, 4, 7, 2, 1, 4, 2, 3, 7, 3],
              fill: false,
              backgroundColor: 'rgba(16, 220, 212, 0.8)',
              borderColor: 'rgba(16, 220, 212, 1)',
              borderWidth: 1.5,
              pointRadius:0,
            },
          ],
        }}
        options={{

        }}
      />
      </div>
      
    </TotalContainer>
     </TotalChart>
    
    </>
  )
}

export default ChartMensalidadeMaior;