import axios from 'axios';
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { retrive } from '../services/auth';
export const AuthContext = createContext({});

const AuthProvider = (props) => {
  //mudar o state da string aq 
  const [user, setUser] = useState('');
  const [load, setLoad] = useState(false);
  const [ativo, setAtivo] = useState("");
  const [cores, setCores] = useState("Claro")


  //function to handle context states
  const handleLogged = useCallback(async (localUser) => {
    const keepLogged = await retrive(localUser);
    if (keepLogged) {
      handleUser(keepLogged, true);
      setLoad(true);
    } else {
      setLoad(true);
    }
  }, [])

  useEffect(() => {
    const localUser = localStorage.getItem("token");
    if (localUser) {
      handleLogged(localUser);
    } else {
      setLoad(true);
    }
  }, [handleLogged])



  function handleUser(isLogged, keep) {
    setUser(isLogged.data)
    axios.defaults.headers.common['Authorization'] = `Token ${isLogged.data.token}`;
    if (keep) {
      localStorage.setItem("@good", JSON.stringify({ token: isLogged.data.token, id: isLogged.data.id }));
    }
  }

  function handleLogout() {
    setUser('');
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("@good")
  }

  return (
    <AuthContext.Provider value={{
      user: user,
      handleUser: handleUser,
      handleLogout: handleLogout,
      load: load,
      ativo: ativo,
      setAtivo: setAtivo,
      setCores: setCores,
      cores: cores,

    }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
}



