import axios from 'axios';
import { PROFILE_URL } from './config';

export const GetProfiles = async () => {
  try {
    const response = await axios.get(PROFILE_URL);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const CreateProfile = async (payload) => {
  try {
    const response = await axios.post(PROFILE_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const EditProfile = async (payload) => {
  try {
    const response = await axios.patch(`${PROFILE_URL}${payload.id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const DeleteProfile = async (profileId) => {
  try {
    const response = await axios.delete(`${PROFILE_URL}${profileId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}