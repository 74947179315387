import axios from "axios";
import { MEDIATION_ARTIST_URL, MEDIATION_PRODUCER_URL, GET_URL_CLIENTE } from "./config";

/// Artistas ///
export const getArtistMediation = async (page) => {
  const params = { page };
  try {
    const response = await axios.get(MEDIATION_ARTIST_URL, { params });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const createArtistMediation = async(payload) => {
  try {
    const response = await axios.post(MEDIATION_ARTIST_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const updateArtisMediation = async(id, payload) => {
  try {
    const response = await axios.patch(`${MEDIATION_ARTIST_URL}${id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

/// Produtores ///
export const getProducersMediation = async (page) => {
  const params = { page };
  try {
    const response = await axios.get(MEDIATION_PRODUCER_URL, { params });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const createProducerMediation = async(payload) => {
  try {
    const response = await axios.post(MEDIATION_PRODUCER_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const updateProducerMediation = async(id, payload) => {
  try {
    const response = await axios.patch(`${MEDIATION_PRODUCER_URL}${id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const GetProducers = async() =>{
  try{
    const resp = await axios.get(`${GET_URL_CLIENTE}`)
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}