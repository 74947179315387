import React, { useState, useEffect } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { addToast, ERROR } from '../components/Notifications'
import { v4 as uuidv4 } from 'uuid';
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { REACT_APP_IMAGEUPLOADER_API, REACT_APP_STORAGE_BASE_URL } from "../services/config"

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


function FilePondUpload(props) {
  const [files, setFiles] = useState([]);
  const [filesMod, setFilesMod] = useState([]);
  const [imageListUploading, setImageListUploading] = useState([]);

  const [qfiles, setQFiles] = useState(0);

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!firstRender) {
      if (files.length > qfiles) {
        setQFiles(files.length)
        if (props.setUploading) {
          props.setUploading(true);
        }
      } else {
        if (files.length < qfiles) {
          setQFiles(qfiles - 1)
        }
      }
    } else {
      console.log("primeiro acesso")
    }

  }, [files]);



  useEffect(() => {
    if (props.setUploading) {
      props.setUploading(!areAllStatusesFalse(imageListUploading));
    }
  }, [imageListUploading]);

  function areAllStatusesFalse(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status !== false) {
        return false; // Se encontrar qualquer item com status true, retorna false
      }
    }
    return true; // Se não encontrar nenhum item com status true, retorna true
  }

  useEffect(() => {
    if (props.photos) {
      setFiles(
        props.photos.map((photo) => ({
          source: photo.image,
          options: {
            type: "local",
          },
        }))
      );
      setFilesMod(
        props.photos.map((photo) => ({
          source: photo.image,
          id: photo.id,
          options: {
            type: "local",
          },
        }))
      );

      if (firstRender) {
        setFirstRender(false);
        setQFiles(props.photos.length)
      }
    }

    if (firstRender) {
      setFirstRender(false);
    }
  }, []);

  function onRemove(fieldName) {
    console.log("removido")
    const fileFiltered = filesMod.find((file) => file.source === fieldName);

    if (!fileFiltered) return null;

    const filteredPhotosList = filesMod.filter(
      (item) => item.id !== fileFiltered.id
    );
    console.log(fileFiltered);

    setFiles(filteredPhotosList);
    setFilesMod(filteredPhotosList);

    if (fileFiltered) {
      return props.remove(fileFiltered.id);
    }
  }

  function updateStatusToFalse(idToUpdate) {
    return imageListUploading.map(item => item.id === idToUpdate ? { ...item, status: false } : item);
  }

  return (
    <div className="container mt-4">
      <FilePond
        accept="image/*"
        allowMultiple={true}
        stylePanelLayout="compact"
        files={files}
        maxParallelUploads={1}
        onupdatefiles={setFiles}
        onremovefile={(e, a) => props.remove(a)}
        remove
        fileSizeBase={1500}
        server={{
          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source);
            fetch(myRequest).then(function (response) {
              response.blob().then(function (myBlob) {
                load(myBlob);
              });
            });
          },
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            if (file.size < 4500000) {
              const formData = new FormData();
              const fileName = uuidv4();
              console.log(`Uploading file ${fileName} (size: ${file.size}) to ${props.folder}...`);

              let listTempUploading = imageListUploading;
              listTempUploading.push({
                id: fileName,
                status: true
              })
              setImageListUploading(listTempUploading)

              if (props.setUploading) {
                props.setUploading(!areAllStatusesFalse(listTempUploading));
              }

              formData.append('File', file);
              formData.append('FileFolder', props.folder);
              formData.append('FileName', fileName);
              const request = new XMLHttpRequest();
              request.open(
                "POST",
                `${REACT_APP_IMAGEUPLOADER_API}/upload`,
                true
              );
              // Deve chamar o método progress para atualizar o progresso para 100% antes de chamar load
              // Definir computable como false alterna o indicador de carregamento para o modo infinito
              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              };
              request.timeout = 60000
              // Deve chamar o método load quando terminar e passar o id do arquivo do servidor retornado
              // este id do arquivo do servidor é então usado mais tarde ao reverter ou restaurar um arquivo
              // para que seu servidor saiba qual arquivo retornar sem expor essa informação ao cliente
              request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                  // the load method accepts either a string (id) or an object
                  const resp = request.responseText;
                  console.log('Uploadresponse:', resp);
                  let url = `${JSON.parse(resp).data.uri}`;
                  if (url?.startsWith('https')) {
                    // url = url.replace(REACT_APP_STORAGE_BASE_URL, '');
                    props.setImages(url);


                    let tempFilesMod = filesMod;

                    tempFilesMod.push({
                      source: url,
                      id: fileName,
                      options: {
                        type: "local",
                      },
                    })

                    setFilesMod(tempFilesMod);

                  }
                  load(url);

                  setImageListUploading(updateStatusToFalse(fileName))

                } else {
                  // Can call the error method if something is wrong, should exit after
                  error("ocorreu algum erro");
                  setImageListUploading(updateStatusToFalse(fileName))
                }
              };
              request.ontimeout = () => {
                error("ocorreu algum erro");
                setImageListUploading(updateStatusToFalse(fileName))
              }
              request.send(formData);
              // Should expose an abort method so the request can be cancelled
              return {
                abort: () => {
                  // This function is entered if the user has tapped the cancel button
                  request.abort();
                  // Let FilePond know the request has been cancelled
                  abort();
                  setImageListUploading(updateStatusToFalse(fileName))
                },
              };
            } else {
              if (props.setUploading) {
                props.setUploading(!areAllStatusesFalse(imageListUploading));
              }
              addToast(ERROR, "A foto deve ter no máximo 1.5mb")
              return abort();
            }
          },
          remove: onRemove,
        }}
        instantUpload={true}
        labelFileProcessingError="Erro durante o upload"
        maxFiles={props.maxFiles ? props.maxFiles : 10}
        allowReorder={true}
        labelIdle={props.label ? props.label : "Selecione ou arraste suas imagens !"}
      />
    </div>
  );
}
export default FilePondUpload;