import React, { useState, useEffect } from 'react';

import LoadingSpinner from '../../components/LoadingSpinner';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import { GetPermissions, GetTypes, UpdatePermission } from '../../services/permission';
import ModalType from './Types';
import {
  Container, Table, Button, ButtonPlus, CheckBox, DivInput, SelectOfForm
} from './style';

import './style.css';
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from "../../AppContext/Context";


const Permission = () => {
  const { user: { is_type } } = useAuth();
  const [group, setGroup] = useState([]);
  const [selected, setSelected] = useState(null);
  const [types, setTypes] = useState([]);
  const [payload, setPayload] = useState({
    is_superuser: false,
    permission: []
  });

  const [loading, setLoading] = useState(false);

  const [openTypes, setOpenTypes] = useState(false);

  useEffect(() => {
    loadTypes();
    loadPermissions();
  }, [])

  async function loadTypes() {
    const response = await GetTypes();
    if (response) {
      setTypes(response);
    }
  }

  async function loadPermissions() {
    const response = await GetPermissions();
    if (response) {
      let _group = [];

      response.forEach((item, index) => {
        const _exist = _group.find(group_item => group_item.model === item.model);
        if (_exist) {
          const _new_func = { id: item.id, function: item.function };
          _exist.functions.push(_new_func);
        } else {
          _group.push({
            model: item.model,
            functions: [{ id: item.id, function: item.function }]
          })
        }
      });
      setGroup(_group);
    }
  }

  function handleChangeType(value) {
    const { permission, is_superuser } = types.find(item => item.id === value);
    setSelected(value);
    setPayload({ permission, is_superuser });
  }

  function checkBoxPermission(func) {
    return payload.permission.find(item => item === func.id);
  }

  function handleChange(name, func) {

    if (name === "permission") {
      const _permissions = [...payload.permission];
      const _exist = _permissions.findIndex(item => item === func.id);
      if (_exist > -1) {
        const _new = [..._permissions];
        _new.splice(_exist, 1);
        setPayload({ ...payload, permission: _new });
      } else {
        let _new = [..._permissions];
        _new.push(func.id);
        setPayload({ ...payload, permission: _new });
      }
    } else {
      setPayload({ ...payload, [name]: func })
    }
  }

  async function handleSubmitPermission() {
    setLoading(true);
    const response = await UpdatePermission(selected, payload);
    setLoading(false);
    if (response) {
      addToast(SUCCESS, 'Permissão atualizada com sucesso');
    } else {
      addToast(ERROR, 'Não foi possível realizar essa operação');
    }
  }

  function toggleType(reload = false) {
    setOpenTypes(!openTypes);
    if (reload) {
      loadTypes();
    }
  }

  return (
    <>
      <h4>Permissões</h4>


      <Container>

        <div className='selectArea'>

          <DivInput className='selectAreaDiv'>

            <SelectOfForm
              required
              onChange={({ target }) => handleChangeType(Number(target.value))}
              style={{ border: "2px solid var(--azulEsverdeado)", borderRadius: "10px", width: "90%" }} >
              <option hidden>Escolha um Tipo</option>
              {types.map((type) => (
                <option value={type.id} key={type.id}>
                  {type.name}
                </option>
              ))}




            </SelectOfForm>

          </DivInput>

          {checkPermission(is_type, MODELS.Permissao, FUNCTIONS.Inserir) ? <ButtonPlus onClick={toggleType}> + </ButtonPlus> : null}

        </div>
        {selected ? <CheckBox>






          <label >
            <input checked={payload.is_superuser} type="checkbox" id="checke" hidden
              onClick={() => handleChange("is_superuser", !payload.is_superuser)} />
            <label for="checke" className="checkmarker"></label>
          </label>


          <label htmlFor="checke">Status de Super Usuário</label>
        </CheckBox> : null}
        <Table>
          <thead>
            <tr>
              <th className="permissionHead"></th>
              <th className="permissionHead" data-label="Atualizar">Atualizar</th>
              <th className="permissionHead" data-label="Excluir">Excluir</th>
              <th className="permissionHead" data-label="Inserir">Inserir</th>
              <th className="permissionHead" data-label="Visualizar">Visualizar</th>
            </tr>
          </thead>
          <tbody>
            {group.map((item, index) => (
              <tr key={index}>
                <td className='texto' >{item.model}</td>
                {item.functions.map((func, i) => {
                  if (item.model !== 'Job' && func.function === "Excluir") {
                    return (<td></td>);
                  } else {
                    return (
                      <td key={`${i}${func.id}`} data-label="Atualizar" >


                        <label className="containerCheckbox">
                          <input type="checkbox" checked={checkBoxPermission(func)} onClick={() => handleChange("permission", func)} />
                          <div className="checkmark"></div>
                        </label>


                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>


        </Table>


        <div className='Legenda'>
          <label className="containerCheckbox">
            <input type="checkbox" checked="checked" id='legenda' />
            <div className="checkmark" style={{ cursor: "default" }}></div>
            <span>Habilitado</span>
          </label>


          <label className="containerCheckbox">
            <input type="checkbox" checked="" id='legenda' />
            <div className="checkmark" style={{ cursor: "default" }}></div>
            <span>Não Habilitado</span>
          </label>
        </div>



        {(checkPermission(is_type, MODELS.Permissao, FUNCTIONS.Inserir) || checkPermission(is_type, MODELS.Permissao, FUNCTIONS.Atualizar)) ?
          <div className="d-flex justify-content-end mt-4">
            {loading ? <LoadingSpinner /> :
              selected ?
                <Button onClick={() => handleSubmitPermission()}>Salvar</Button> : null
            }
          </div> : null}



      </Container>


      {openTypes ? <ModalType toggleModal={toggleType} /> : null}
    </>
  );
}

export default Permission;