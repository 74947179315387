import styled, { keyframes } from "styled-components";
import Images from '../../assets';

const slideBackgroundImage = keyframes`
  0% {background-image: url(${Images.imgLogin1});}
  9% {background-image: url(${Images.imgLogin2});}
  18% {background-image: url(${Images.imgLogin3});}
  27% {background-image: url(${Images.imgLogin4});}
  36% {background-image: url(${Images.imgLogin5});}
  45% {background-image: url(${Images.imgLogin6});}
  54% {background-image: url(${Images.imgLogin7});}
  63% {background-image: url(${Images.imgLogin8});}
  72% {background-image: url(${Images.imgLogin9});}
  81% {background-image: url(${Images.imgLogin10});}
  90% {background-image: url(${Images.imgLogin11});}
  100% {background-image: url(${Images.imgLogin12});}
`

export const FooterArea = styled.section`
 @media(orientation: portrait){
 background: var(--corEscura);
 height:10vh;
 }
 `
export const TotalArea = styled.section``

export const LoginStyled = styled.div`
    background: var(--corEscura);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow:hidden;

    @media(orientation: portrait){
      height: 90vh;
      display: flex;
    }
  `
export const FirstColumn = styled.div.attrs({
  className: "backgroundLogin",
})`
    width: 100%;
    
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    
    color: var(--corBranca);
    background: var(--corEscura);
    min-height: 100%;

    @media(orientation: portrait){
      width: 100%;
     
    }
    
    
  `

export const Form = styled.form`
  position: relative;
  margin-top: 15vh;
  
  text-align: justify;
  width: 60%;

  min-width: 420px;
  height:100%;
 
   
   padding-left: 3rem;


   @media(orientation: portrait){
    width:70%;
    margin-top: 20vh;
    min-width: 50%;
    position:relative;
    padding-left: 0rem;
  }



`
export const Logo = styled.img`
    max-width: 50%;
    margin: 0 0 8% 3%;
    filter: ${props => props.cores === "Escuro" ? 'brightness(100%)' : 'brightness(20%)'};
    @media(orientation: portrait){
     
    }
  `

export const FormText = styled.p`
    font-size: 18px;
    text-transform: uppercase;


    @media(orientation: portrait){
    text-align: justify;
    font-size: 0.8em
    }
  `

export const InputText = styled.input`
  height: 50px;
  width: 90%;
  
  border-radius:10px;
  @media(orientation: portrait){
    height: 40px;
    width: 100%;
    border-radius:8px;
  }
`

export const Input = styled.input`
  background-color: var(--corPrincipal2);
  &:checked {
  background-color: var(--corPrincipal2);  
  border: solid var(--corPrincipal2) ;
  border-width: 0 1px 1px 0;
  }


    
    &:hover{
      cursor:pointer
    
  }
`

export const Label = styled.label`
  margin-top: 3%;
  
`
export const ForgotPasswordLink = styled.a`
  text-decoration: none !important;
  color: #828282;
  font-size: 12px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 2% 0 3% 0;
  cursor: pointer;
  &:hover {
    color: #828282;
  }

`

export const LabelCheckBox = styled.label`
  color: #828282;
  font-size: 12px;
  margin-top: 0.7%;
  


`
export const ButtonGetIn = styled.button`
  background-color: ${props => props.disabled ? "#828282" : "var(--azulEsverdeado)"};
  padding: 2% 40%;
  font-weight: 900;
  margin: 8.7% -2% 15% -2%;
  border-radius: 30px;
  text-align: center;
  border: none;
  
  color: #212731;


  @media(orientation: portrait){
    width:100%;
    margin: 8.7% 0 15% 0;
  }
`

export const Footer = styled.footer`
  color: #828282;
  font-size: 12px;
  display: flex;
  background: var(--corEscura);
  justify-content: center;
  margin-top: 4%;
  @media(orientation: portrait){
   position:absolute;
   display:flex;
   align-items:center;
   align-self:center;
   justify-content: center;
   text-align:center;
   width:100%;
   margin-top: 30px;
  }
`

export const SecondColumn = styled.div`
      width: 60%;
      min-height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(${Images.imgLoginBackground});
      @media(orientation: portrait){
        display:none;
      }
`
// animation: ${slideBackgroundImage} 60s linear infinite;

export const FooterForgotPassword = styled.footer`
  color: #828282;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 14%;
  @media(orientation: portrait){

  }
`
export const FormTextForgotPassWord = styled.div`
  font-size: 16px;
  margin: 5% 12% 6% 0;
`

export const TextInformationPassWord = styled.p`
  margin-top: 5%;
  text-align: justify;
`

export const DivInput = styled.div.attrs({
  className: "inputbox",

})
  `
& {
  position: relative;
  width: 90%;
  margin-top:20px
}

@media(orientation: portrait){
  
  width: 100%;
  
}

& input {
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--corBranca);
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 8;
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  color: var(--corEscura);
}



& span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

& input:valid ~span,
& input:focus ~span {
  color: var(--azulEsverdeado);
  transform: translateX(-10px) translateY(-34px);
  font-size: 0,75em;
}

& i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--azulEsverdeado);
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

& i:nth-child(2) {
  position: absolute;
  pointer-events:all ;
  margin-left: 95%;
  bottom: 30px;
  width: 100%;
 
  background: transparent;
  border-radius: 4px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}
`

export const InputOfForm = styled.input``

export const Span = styled.span``

export const I = styled.i``
