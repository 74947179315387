import React, {Fragment} from 'react';
import { Container } from './styles';

function Textarea({label: inputLabel, ...props}) {
  return (
    <Fragment>
      {inputLabel && (
        <Fragment>
          <label>{inputLabel}</label>
          <br />
        </Fragment>
      )}
      <Container {...props} />
    </Fragment>
  )
}

export default Textarea;