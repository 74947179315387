import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputsArea,
  DescricaoAreaClass,
  UpDivs,
  InputDescricao,
  DivInput,
  Span,
  I,
  InputOfForm,
  TextareaOfForm,
} from "../AvaliacoesArtistas/CriarAvaliacao/styles";
import { Label, RowInput, InputTextClass } from "./styles";
import { useNavigate } from "react-router-dom";
import { createClassVideo } from "../../services/class";

const CriarAula = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    title: "",
    subtitle: "",
    link: "",
    description: "",
    order: 0,
  });

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const url = new URL(payload.link);
    const link = new URLSearchParams(url.search).get("v");

    const _payload = { ...payload, link: link };

    setLoading(true);
    const response = await createClassVideo(_payload);
    setLoading(false);
    if (response) {
      navigate("/aulas/gestao");
    }
  }

  return (
    <>
      <h4>
        <b>Nova Aula</b>
      </h4>
      <Container>
        <Form onSubmit={handleSubmit}>
          <UpDivs>
            <InputsArea>
              <DivInput>
                <InputOfForm
                  required="required"
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                />

                <Span>Título da Aula</Span>
                <I></I>
              </DivInput>

              <DivInput>
                <InputOfForm
                  required="required"
                  onChange={(event) =>
                    handleChange("subtitle", event.target.value)
                  }
                />

                <Span>Subtítulo da Aula</Span>
                <I></I>
              </DivInput>

              <DivInput>
                <InputOfForm
                  required="required"
                  onChange={(event) => handleChange("link", event.target.value)}
                />

                <Span>Link da Aula</Span>
                <I></I>
              </DivInput>
              <RowInput>
                <DivInput>
                  <InputOfForm
                    type="number"
                    value={payload.order}
                    required="required"
                    onChange={(event) =>
                      handleChange("order", event.target.value)
                    }
                  />

                  <Span>Posição</Span>
                  <I></I>
                </DivInput>
              </RowInput>
            </InputsArea>
            {loading ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : (
              <Button type="submit">Salvar Aula</Button>
            )}
          </UpDivs>
          <DescricaoAreaClass>
            <DivInput>
              <TextareaOfForm
                required="required"
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
              />

              <Span className="spanTextarea">Descrição</Span>
            </DivInput>
          </DescricaoAreaClass>
        </Form>
      </Container>
    </>
  );
};

export default CriarAula;
