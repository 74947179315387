import React, { useEffect, useState } from "react";
import { ButtonEdit } from "../TabelaAprovacaoArtista/styles";
import { CardCustom, FormCustom, SectionCustom, DivButtons } from "./style";
import { useLocation, useNavigate } from "react-router";
import {
  ApprovedArtista,
  GetArtistPhotos,
  PatchArtista,
  RejectArtist,
  SelectArtistPhoto,
} from "../../services/artist";
import Modal from "../Modal/Modal";
import { addToast, SUCCESS, ERROR } from "../Notifications";
import LoadingSpinner from "../LoadingSpinner";

function AprovacaoArtista() {
  const navigate = useNavigate();
  const location = useLocation();

  const [profileArtist, setProfileArtist] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [loadPhoto, setLoadPhoto] = useState(false);
  const [selectLoad, setSelectLoad] = useState(false);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: "",
    description: "",
  });

  useEffect(() => {
    if (!location.state) {
      return navigate("/artista/aprovacao");
    }
    console.log(location.state.profile);
    setProfileArtist(location.state.profile);
    loadPhotos(location.state.profile.id);
  }, []);

  async function loadPhotos(artistId) {
    setLoadPhoto(true);
    const resp = await GetArtistPhotos(artistId);
    setLoadPhoto(false);
    if (resp) {
      setPhotos(resp);
    }
  }

  async function submitApproved() {
    if (checkPhotosCount() > 3) {
      const photo_profile = photos.find((img) => img.is_active);
      try {
        setLoading(true);
        const resp = await ApprovedArtista(profileArtist.id);
        console.log(profileArtist);
        console.log(photo_profile.image);
        // await PatchArtista({
        //   ...profileArtist,
        //   image: photo_profile.image
        // });

        setLoading(false);
        if (resp && resp.status === 200) {
          addToast(SUCCESS, "Artista aprovado com sucesso!");
          navigate(-1);
        }
      } catch (error) {
        setLoading(false);
        const { response } = error;
        if (response) {
          if (response.data.error === "error-send-email") {
            addToast(
              ERROR,
              "Houve um problema ao aprovar o artista",
              "O Email do artista está errado ou não existe"
            );
            return;
          }
          if (response.data.error === "error-required-fields") {
            const { message } = response.data.response;
            addToast(ERROR, message);
            return;
          }
        } else {
          addToast(ERROR, "Houve um problema ao aprovar o artista");
          return;
        }
      }
    } else {
      addToast(ERROR, "Selecione ao menos 4 Fotos");
    }
  }

  function checkPhotosCount() {
    const sum = photos.reduce((count, item) => {
      if (item.is_active) {
        return count + 1;
      }
      return count;
    }, 0);
    return sum;
  }

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: "", description: "" });
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function rejectModal() {
    if (validForm()) {
      setLoading(true);
      const resp = await RejectArtist({ ...payload, artist: modal.id });
      setLoading(false);
      if (resp && resp.status === 200) {
        addToast(SUCCESS, "Artista cancelado com sucesso!");
        handleTriggerModal(false);
        navigate.push("/artista/aprovacao");
      } else {
        addToast(ERROR, "Não foi possível realizar essa operação");
      }
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, "Informe o Motivo da rejeição");
      return false;
    }
    return true;
  }

  async function handleSelectPhoto(photo) {
    if (!selectLoad) {
      if (checkPhotosCount() < 4 || photo.is_active) {
        setSelectLoad(photo.id);
        const resp = await SelectArtistPhoto(photo);
        setSelectLoad(false);
        if (resp) {
          const _new = [...photos].map((item) => {
            if (item.id === photo.id) {
              item.is_active = !item.is_active;
            }
            return item;
          });
          setPhotos(_new);
          addToast(SUCCESS, "Foto selecionada");
        } else {
          addToast(ERROR, "Não foi possível realizar essa operação");
        }
      } else {
        addToast(ERROR, "Selecione apenas 4 fotos");
      }
    }
  }

  const formatDate = (time, onlyDate) => {
    const _date = new Date(time);
    if (onlyDate) {
      return `${_date.toLocaleDateString("pt-pt", { timeZone: "UTC" })}`;
    } else {
      return `${_date
        .toLocaleDateString("pt-pt")
        .split("/")
        .reverse()
        .join("/")}`;
    }
  };

  return (
    <>
      <h4>Aprovação de Perfil</h4>
      <FormCustom large>
        {profileArtist && (
          <div style={divCustom}>
            <h4 style={h4}>{profileArtist.name}</h4>
            <h4 style={h4}>Artista - {profileArtist.nick_name}</h4>
            <h6
              style={{
                marginTop: 12,
              }}
            >
              Data Nascimento:{" "}
              <span>{formatDate(profileArtist.birth_date)}</span>
            </h6>
            <h6>
              Gênero: <span>{profileArtist.gender}</span>
            </h6>
            <h6>
              Distrito: <span>{profileArtist.address_city}</span>
            </h6>
            <h6>
              Altura: <span>{profileArtist.stature} cm</span>
            </h6>
            <h6>
              Cintura: <span>{profileArtist.waist} cm</span>
            </h6>
            <h6>
              Anca: <span>{profileArtist.hip} cm</span>
            </h6>
            <h6>
              Peito: <span>{profileArtist.bust} cm</span>
            </h6>
            <h6>
              Olhos: <span>{profileArtist.eye}</span>
            </h6>
            <h6>
              Cabelo: <span>{profileArtist.hair}</span>
            </h6>
            <h6>
              Cor de Pele/Etnia: <span>{profileArtist.skin}</span>
            </h6>
            <h6>
              Email: <span>{profileArtist.email}</span>
            </h6>
            <h6>
              Prefixo: <span>{profileArtist.phone_prefix}</span>
            </h6>
            <h6>
              Telefone: <span>{profileArtist.phone}</span>
            </h6>
            <h6>
              Instagram: <span>{profileArtist.instagram}</span>
            </h6>
          </div>
        )}
        <SectionCustom>
          <h5 style={divH6}>Selecione as fotos adequadas para o perfil:</h5>
          <div className="session">
            {photos.map((photo) => (
              <CardCustom key={photo.id}>
                <img src={photo.image} alt="Modelo" />
                <div style={divCustom2}>
                  {selectLoad === photo.id ? (
                    <LoadingSpinner noMargin={true} center={true} />
                  ) : (
                    <div style={divCustom3}>
                      <input
                        type="checkbox"
                        id={`select${photo.id}`}
                        value="Selecionar"
                        checked={photo.is_active}
                        onClick={() => handleSelectPhoto(photo)}
                      />
                      <p style={Label}>
                        Selecionar
                      </p>
                    </div>
                  )}
                </div>
              </CardCustom>
            ))}
          </div>
        </SectionCustom>
        <div className="justify-content-between">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DivButtons>
              <ButtonEdit type="button" azul font12 onClick={submitApproved}>
                APROVAR CADASTRO
              </ButtonEdit>
              <ButtonEdit
                type="button"
                font12
                onClick={() => setModal(profileArtist)}
              >
                REJEITAR CADASTRO
              </ButtonEdit>
            </DivButtons>
          )}
        </div>
      </FormCustom>
      {modal && (
        <Modal
          handleChangeReject={handleChangeReject}
          closeModal={handleTriggerModal}
          rejectModal={rejectModal}
        />
      )}
    </>
  );
}

export default AprovacaoArtista;

const divCustom = {
  width: "25%",
  alignItems: "baseline",
};

const divCustom2 = {
  display: "flex",
  alignItems: "flex-end",
  marginLeft: "10px",
  marginBottom: "5px",
};

const divCustom3 = {
  display: "flex",
  width: "100%",
  fexDirection: "row",
};

const Label = {
  marginTop: "12px",
};

const divH6 = {
  fontSize: "18px",
};
const h4 = {
  fontSize: "16px",
  color: "black",
  marginBottom: ".5rem",
};
