import axios from 'axios';
import { RECURRING_PAYMENT_TYPE } from './config';

export const GetRecurring = async () => {
    try {
        const response = await axios.get(RECURRING_PAYMENT_TYPE);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const CreateRecurring = async (payload) => {
    try {
        const response = await axios.post(RECURRING_PAYMENT_TYPE, payload);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const UpdateRecurring = async (payload, id) => {
    try {
        const response = await axios.patch(`${RECURRING_PAYMENT_TYPE}${id}/`, payload);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}