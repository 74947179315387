import styled from 'styled-components'

export const Table = styled.table`
  height: 100%;
  background: var(--corEscura);
  margin: 30px 0 40px 0;
  padding: 0;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  table-layout: fixed;

  &:nth-child(2) {
    background: var(--corEscura);

    width: 100%;
  }

  caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  th,
  td {
    position: relative;
    padding: 0.625em;
    width: 100%;

    flex-wrap: wrap;
    text-align: center;
  }

  th {
    font-size: 0.85em;
    color: var(--azulEsverdeado);
    letter-spacing: 0.1em;
    border-bottom: 1px solid var(--azulEsverdeado);
    text-transform: uppercase;
  }

  td {
    font-size: 100%;
    color: var(--corBranca);
    inline-size: 100%;
    overflow-wrap: break-word;
  }

  &:last-child {
  }

  @media screen and (max-width: 600px) {
    background: transparent;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
    border: 0;

    &:nth-child(2) {
      background: transparent;
      border: 0;
      width: 100%;
    }

    caption {
      font-size: 1.3em;
    }

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      background: var(--corEscura);
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
      display: block;
      justify-content: center;
      margin-bottom: 2em;
    }

    td {
      border-bottom: 1px solid var(--azulEsverdeado);
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    td::before {
      /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  
  */
      content: attr(data-label);
      float: left;
      color: var(--azulEsverdeado);
      font-weight: bold;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
`

export const Button = styled.button`
  background: ${(props) =>
    props.blue
      ? 'var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box'
      : (props) =>
          props.green
            ? '#1EA081 0% 0% no-repeat padding-box'
            : '#DC1076 0% 0% no-repeat padding-box'};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  width: 128px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 18px;
  margin-right: 0;
  padding: 0 7px;
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 4;
`
export const Input = styled.input`
  border: none;
  border-radius: 2rem;
  width: 20vw;
  margin-right: 5vw;
  padding: 0.5rem;
  padding-left: 1rem;
`
export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: var(--corEscura);
  color: var(--corBranca);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  z-index: 4;

  div {
    margin-bottom: 20px;
  }

  div h2 {
    font-size: 1.5em;
    color: var(--corBranca);
  }

  @media (orientation: portrait) {
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div h2 {
      font-size: 1.1em;
    }
  }
`

export const ModalButton = styled.button`
  background: ${(props) =>
    props.blue
      ? 'var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box'
      : props.red
      ? '#DC1076 0% 0% no-repeat padding-box'
      : '#707070 0% 0% no-repeat padding-box'};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${(props) => (props.font12 ? '0.75em' : '0.75em')};
  text-align: center;
  color: var(--corEscura);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;

  @media (orientation: portrait) {
    width: 100%;
  }
`

export const DivInput = styled.div.attrs({
  className: 'inputbox',
})`
  & {
    position: relative;
    width: 100%;
    margin: 30px 0px 0 0;
  }

  @media (orientation: portrait) {
    width: 100%;
    margin: 20px 1.5vw;

    & select {
      width: 100%;
    }
  }

  & input,
  & select {
    font-weight: 700;
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: var(--corBranca);
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 2;
  }

  & select {
    height: 100%;
    color: var(--corBranca);
  }

  & span {
    position: absolute;
    left: 0;
    padding: 20px 10px 10px;
    font-size: 1em;
    color: #8f8f8f;
    letter-spacing: 00.05em;
    transition: 0.5s;
    pointer-events: none;
  }

  & input:valid ~ span,
  & input:focus ~ span,
  & select:valid ~ span,
  & select:focus ~ span {
    color: var(--azulEsverdeado);
    transform: translateX(-10px) translateY(-34px);
  }

  & input[type='date']:valid ~ span,
  & input[type='date']:focus ~ span {
    color: var(--azulEsverdeado);
    transform: translateX(-10px) translateY(-34px);
  }

  & input[type='date']:invalid ~ span {
    color: #8f8f8f;
    transform: translateX(-10px) translateY(-34px);
  }

  & i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--azulEsverdeado);
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 1;
  }

  & i:nth-child(2) {
    position: absolute;
    pointer-events: all;
    margin-left: 95%;
    bottom: 30px;
    width: 100%;

    background: transparent;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    z-index: 2;
  }
`

export const InputOfForm = styled.input``

export const SelectOfForm = styled.select`
  & option {
    color: var(--corBranca);
  }
`

export const Span = styled.span``

export const I = styled.i``
